<template>
  <div>
    <div
      v-if="s.popup == 'contactedit'"
      id="popnew"
      class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
    >
      <ContactEdit @close="closeContact" />
    </div>
    <div
      v-if="s.popup"
      @click="close"
      id="overlay"
      class="fixed bg-cyan-900 z-10 w-screen h-screen top-0 left-0 opacity-80"
    ></div>

    <template v-if="lead.id > 0">
      <div class="flex max-xl:flex-col">
        <div class="w-[400px] my-4 max-xl:mx-auto max-xl:max-w-full">
          <div class="bg-white rounded-[4px]">
            <div class="p-4 v-bbgray">
              <div class="flex items-center w-full mb-4 relative">
                <h1 class="font-latobold text-xl text-black">ЛИД</h1>
              </div>
              <div class="flex items-center relative">
                <div class="w-16 h-16 relative">
                  <span
                    class="avatar w-full h-full flex items-center justify-center"
                    :class="'bg-status-' + lead.status"
                  >
                    <span
                      class="w-8 h-8 flex items-center justify-center first:!fill-white"
                    >
                      <IcoStatusLead :status="parseInt(lead.status)" />
                    </span>
                  </span>
                </div>
                <div class="flex flex-col ml-4">
                  <span class="text-gray-400 text-[10px] uppercase">{{
                    st[lead.status]
                  }}</span>
                  <span class="font-latobold text-xl text-black"
                    >№{{ lead.id }}</span
                  >
                </div>

                <span
                  title="Обработка лида просрочена"
                  v-if="lead.violate == 2"
                >
                  <svg class="w-5 h-5 ml-2 mt-1 fill-status-11">
                    <use xlink:href="@/assets/i.svg#status-11"></use>
                  </svg>
                </span>

                <div
                  v-if="lead.order_id"
                  class="absolute top-1 -right-4 h-14 overflow-hidden max-w-[40%] pl-6 pr-4 flex items-center"
                  @click="editOrderId(lead.order_id)"
                >
                  <svg class="w-7 h-7 fill-white z-2 mr-2">
                    <use xlink:href="@/assets/i.svg#ico-rating"></use>
                  </svg>
                  <span class="text-white z-20 relative text-2xl font-latobold"
                    >ЗАКАЗ</span
                  >
                  <svg
                    class="absolute top-0 -left-8 h-full"
                    :class="'!fill-status-6'"
                  >
                    <use xlink:href="@/assets/bg.svg#bg-timer"></use>
                  </svg>
                </div>

                <div
                  v-if="timer?.time"
                  class="absolute top-1 -right-4 h-14 overflow-hidden max-w-[50%] pl-6 pr-4 flex items-center"
                >
                  <span
                    class="text-white z-20 relative text-2xl font-latobold"
                    >{{ timer.time }}</span
                  >
                  <svg
                    class="absolute top-0 -left-8 h-full"
                    :class="'!fill-status-' + timer?.color"
                  >
                    <use xlink:href="@/assets/bg.svg#bg-timer"></use>
                  </svg>
                </div>
              </div>
            </div>

            <!-- <div class="flex p-5 v-bbgray">
                            <div class="flex items-center">
                                <svg class="min-w-5 w-5 h-5 fill-gray-400 rotate-45 mr-2">
                                    <use xlink:href="@/assets/i.svg#ico-no"></use>
                                </svg>
                                <div class="flex flex-col">
                                    <span class="text-gray-400 text-[10px] uppercase">СОЗДАН</span>
                                    <span class="font-latobold text-base text-gray-700">{{
                                        moment(lead.create_at).format("DD.MM.YYYY в HH:mm")
                                    }}</span>
                                </div>
                            </div>
                        </div> -->

            <div class="flex v-bbgray">
              <div class="flex ml-4 py-5 pl-5 pr-4 items-center">
                <svg class="min-w-5 w-5 h-5 fill-gray-400 rotate-45 mr-2">
                  <use xlink:href="@/assets/i.svg#ico-no"></use>
                </svg>
                <div class="flex flex-col">
                  <span class="text-gray-400 text-[10px] uppercase"
                    >СОЗДАН</span
                  >
                  <span class="font-latobold text-base text-gray-700">{{
                    moment(lead.create_at).format("DD.MM.YYYY в HH:mm")
                  }}</span>
                </div>
              </div>
            </div>
            <div v-if="lead.start_at" class="flex v-bbgray">
              <div class="flex ml-4 py-3 pl-5 pr-4 items-center">
                <div class="flex flex-col">
                  <span class="text-gray-400 text-[10px] uppercase"
                    >Начато</span
                  >
                  <span class="font-latobold text-base text-gray-700">{{
                    moment(lead.start_at).format("DD.MM.YYYY в HH:mm")
                  }}</span>
                </div>
              </div>
            </div>
            <div v-if="lead.end_at" class="flex v-bbgray">
              <div class="flex ml-4 py-3 pl-5 pr-4 items-center">
                <div class="flex flex-col">
                  <span class="text-gray-400 text-[10px] uppercase"
                    >Закончено</span
                  >
                  <span class="font-latobold text-base text-gray-700">{{
                    moment(lead.end_at).format("DD.MM.YYYY в HH:mm")
                  }}</span>
                </div>
              </div>
            </div>

            <div v-if="lead.status != 1" class="flex flex-wrap p-5 v-bbgray">
              <div class="w-1/3 flex flex-col px-4 my-2 max-m:w-full">
                <input
                  class="mychbox"
                  type="checkbox"
                  id="taho"
                  v-model="lead.taho"
                  @change="kindOfLead({ name: 'taho', val: lead.taho })"
                />
                <label for="taho">Тахограф</label>
              </div>
              <div class="w-1/3 flex flex-col px-4 my-2 max-m:w-full">
                <input
                  class="mychbox"
                  type="checkbox"
                  id="monitor"
                  v-model="lead.monitor"
                  @change="kindOfLead({ name: 'monitor', val: lead.monitor })"
                />
                <label for="monitor">Мониторинг</label>
              </div>
              <div class="w-1/3 flex flex-col px-4 my-2 max-m:w-full">
                <input
                  class="mychbox"
                  type="checkbox"
                  id="card"
                  v-model="lead.card"
                  @change="kindOfLead({ name: 'card', val: lead.card })"
                />
                <label for="card">Карты</label>
              </div>
              <div class="w-1/3 flex flex-col px-4 my-2 max-m:w-full">
                <input
                  class="mychbox"
                  type="checkbox"
                  id="propusk"
                  v-model="lead.propusk"
                  @change="kindOfLead({ name: 'propusk', val: lead.propusk })"
                />
                <label for="propusk">Пропуска</label>
              </div>
              <div class="w-1/3 flex flex-col px-4 my-2 max-m:w-full">
                <input
                  class="mychbox"
                  type="checkbox"
                  id="teach"
                  v-model="lead.teach"
                  @change="kindOfLead({ name: 'teach', val: lead.teach })"
                />
                <label for="teach">Обучение</label>
              </div>
              <div class="w-1/3 flex flex-col px-4 my-2 max-m:w-full">
                <input
                  class="mychbox"
                  type="checkbox"
                  id="driver"
                  v-model="lead.driver"
                  @change="kindOfLead({ name: 'driver', val: lead.driver })"
                />
                <label for="driver">Водители</label>
              </div>
            </div>
          </div>

          <div class="bg-white order-conn mt-4 relative rounded-[4px]">
            <div class="flex p-5 v-bbgray">
              <div class="flex flex-col justify-center overflow-hidden pr-8">
                <span
                  class="text-gray-400 leading-none w-full max-w-[300px] mr-6 mb-1"
                  >БИЗНЕС ПРОЦЕСС</span
                >
                <p
                  v-if="lead.business_proc"
                  class="font-latobold text-base text-gray-700"
                >
                  <span v-if="lead.business_proc == 1">
                    Пролонгация тахографы (СКЗИ)
                  </span>
                  <span v-if="lead.business_proc == 2">
                    Пролонгация настройки ЕСТР
                  </span>
                  <span v-if="lead.business_proc == 3">
                    Пролонгация пропуска
                  </span>
                  <span v-if="lead.business_proc == 4">
                    Пролонгация карты
                  </span>
                  <span v-if="lead.business_proc == 5">
                    Контроль качества
                  </span>
                  <span v-if="lead.business_proc == 6">
                    Реанимация неактивных клиентов
                  </span>
                  <span v-if="lead.business_proc == 7">
                    Реанимация клиентов без заказов
                  </span>
                  <span v-if="lead.business_proc == 8"> Рекламации </span>
                  <span v-if="lead.business_proc == 9"> Как дела </span>
                  <span v-if="lead.business_proc == 10"> Общий </span>
                  <span v-if="lead.business_proc == 11"> АБ Мониторинг </span>
                  <span v-if="lead.business_proc == 12"> Агент </span>
                  <span v-if="lead.business_proc == 13"> Рекомендация </span>
                  <span v-if="lead.business_proc == 14"> Интернет </span>
                  <span v-if="lead.business_proc == 15"> Новая база </span>
                </p>
                <p v-else class="font-latobold text-base text-red-400">
                  Бизнес процесс не выбран
                </p>
                <!--                                 
                                <select v-model="lead.business_proc" class="select-bp v-bgray rounded-sm text-sm text-teal-700 w-full mt-2" name="" id="">
                                    <option value="0" disabled selected>Выберите опцию</option>
                                    <option value="1">Пролонгация тахографы</option>
                                    <option value="2">Пролонгация настройки ЕСТР</option>
                                    <option value="3">Пролонгация пропуска </option>
                                    <option value="4">Контроль качества</option>
                                    <option value="5">Реанимация неактивных клиентов</option>
                                    <option value="6">Реанимация клиентов без заказов</option>
                                    <option value="7">Рекламации</option>
                                    <option value="8">Агент</option>
                                </select> -->

                <!-- <span class="font-latobold text-base text-gray-700">Старт касания</span> -->
              </div>
            </div>
          </div>

          <div class="bg-white order-conn mt-4 relative rounded-[4px]">
            <div class="flex p-5 v-bbgray">
              <div
                v-if="lead.type"
                class="flex items-center group relative w-1/2"
              >
                <div
                  class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4"
                >
                  <span class="w-6 h-6 flex-cc"
                    ><IcoPic :pic="null" :name="String(lead.type)" />
                  </span>
                </div>
                <div class="flex flex-col justify-center overflow-hidden pr-8">
                  <span
                    class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                    >ВИД</span
                  >
                  <span class="font-latobold">{{ type[lead.type] }}</span>
                </div>

                <!-- <a
                  class="group cursor-pointer opacity-0 group-hover:opacity-100"
                  @click="trySetLeadType(0)"
                >
                  <svg
                    class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                  >
                    <use xlink:href="@/assets/i.svg#ico-no"></use>
                  </svg>
                </a> -->
              </div>
              <div
                v-if="lead.direction"
                class="flex items-center group relative w-1/2"
              >
                <div
                  class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4"
                >
                  <span
                    class="text-white text-xl w-11 h-11 leading-none rounded-full flex items-center justify-center"
                  >
                    <svg
                      class="w-5 h-5 fill-white"
                      :class="{ 'rotate-180': lead.direction == 2 }"
                    >
                      <use xlink:href="@/assets/i.svg#ico-next-one"></use>
                    </svg>
                  </span>
                </div>
                <div class="flex flex-col justify-center overflow-hidden pr-8">
                  <span
                    class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                    >НАПРАВЛЕНИЕ</span
                  >
                  <span class="font-latobold">{{
                    direction[lead.direction]
                  }}</span>
                </div>
                <!-- 
                <a
                  class="group cursor-pointer opacity-0 group-hover:opacity-100"
                  @click="trySetLeadDirection(0)"
                >
                  <svg
                    class="fill-gray-400 w-4 h-4 mr-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                  >
                    <use xlink:href="@/assets/i.svg#ico-no"></use>
                  </svg>
                </a> -->
              </div>
            </div>
            <div v-if="!lead.type" class="p-5 v-bbgray">
              <div class="flex items-center w-full">
                <p class="text-gray-400 leading-none w-full max-w-[100px] mr-6">
                  ВИД
                </p>
                <select
                  class="h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                  v-model="lead.type"
                  @change="trySetLeadType(lead.type)"
                >
                  <!--<option value="0">Выбрать</option>-->
                  <option :value="key" v-for="(val, key) in type" :key="val">
                    {{ val }}
                  </option>
                </select>
              </div>
            </div>
            <div v-if="!lead.direction && lead.type" class="p-5 v-bbgray">
              <div class="flex items-center w-full">
                <p class="text-gray-400 leading-none w-full max-w-[100px] mr-6">
                  НАПРАВЛЕНИЕ
                </p>
                <select
                  class="h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                  v-model="lead.direction"
                  @change="trySetLeadDirection(lead.direction)"
                >
                  <!--<option value="0">Выбрать</option>-->
                  <option
                    v-for="(val, key) in direction"
                    :key="val"
                    :value="key"
                  >
                    {{ val }}
                  </option>
                </select>
              </div>
            </div>
            <div v-if="canShowChannel" class="p-5 v-bbgray group relative">
              <div v-if="lead.channel" class="flex items-center">
                <div class="flex flex-col">
                  <p class="text-gray-400 text-[10px] uppercase">
                    КАНАЛ ПРИВЛЕЧЕНИЯ
                  </p>
                  <span class="font-latobold text-base text-gray-700">{{
                    channel.find((item) => item.valueCh === lead.channel).name
                  }}</span>
                </div>

                <div
                  class="absolute top-1/2 -translate-y-1/2 right-8 opacity-0 group-hover:opacity-100 transition-all duration-150"
                >
                  <!-- <a class="group cursor-pointer" @click="trySetLeadChannel(0)">
                    <svg
                      class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a> -->
                </div>
              </div>
              <div v-else class="flex items-center w-full">
                <p class="text-gray-400 leading-none w-full max-w-[100px] mr-6">
                  КАНАЛ ПРИВЛЕЧЕНИЯ
                </p>
                <select
                  class="h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                  v-model="lead.channel"
                  @change="trySetLeadChannel(lead.channel)"
                >
                  <!--<option value="0">Выбрать</option>-->
                  <option v-for="ch in availableChannels" :value="ch" :key="ch">
                    {{ channel[ch] }}
                  </option>
                </select>
              </div>
            </div>
            <!-- <div
              class="p-5"
              style="display: flex; align-items: center"
              v-if="canShowChannelDetailType"
            >
              <p class="text-gray-400 leading-none w-full max-w-[200px] mr-6">
                ДЕТАЛИ КАНАЛА
              </p>
              <select
                v-model="lead.channel_detail_type"
                class="h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                @change="trySetLeadChannelDetailType(lead.channel_detail_type)"
              >
                <option
                  v-for="ch in availableChannelDetails"
                  :value="ch"
                  :key="ch"
                >
                  {{ channelDetailType[ch] }}
                </option>
              </select>
            </div>
            <div style="position: relative" v-if="canShowChannelDetailValue">
              <p
                class="text-gray-400 leading-none w-full p-5 mr-6"
                style="font-weight: 500; text-transform: uppercase"
              >
                Дополнительные данные:
              </p>
              <input
                type="text"
                v-model="lead.channel_detail_value"
                placeholder="Введите дополнительные сведения"
                style="
                  border: 1px solid #7b7b7b;
                  padding: 24px 18px;
                  padding-right: 50px;
                "
                class="h-9 px-3 py-2 mt-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                @change="setTouchChannelDetailValue(lead.channel_detail_value)"
              />
              <svg
                style="position: absolute; right: 15px; bottom: 11%"
                fill="#cccccc"
                height="20px"
                width="20px"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                enable-background="new 0 0 512 512"
              >
                <g>
                  <g>
                    <path
                      d="m455.1,137.9l-32.4,32.4-81-81.1 32.4-32.4c6.6-6.6 18.1-6.6 24.7,0l56.3,56.4c6.8,6.8 6.8,17.9 0,24.7zm-270.7,271l-81-81.1 209.4-209.7 81,81.1-209.4,209.7zm-99.7-42l60.6,60.7-84.4,23.8 23.8-84.5zm399.3-282.6l-56.3-56.4c-11-11-50.7-31.8-82.4,0l-285.3,285.5c-2.5,2.5-4.3,5.5-5.2,8.9l-43,153.1c-2,7.1 0.1,14.7 5.2,20 5.2,5.3 15.6,6.2 20,5.2l153-43.1c3.4-0.9 6.4-2.7 8.9-5.2l285.1-285.5c22.7-22.7 22.7-59.7 0-82.5z"
                    />
                  </g>
                </g>
              </svg>
            </div> -->

            <div class="p-5" v-if="canShowCommunicationType">
              <p
                class="text-gray-400 leading-none w-full max-w-[300px] mr-6 mb-3"
              >
                ВИД КОММУНИКАЦИИ
              </p>
              <!--<select
                                v-model="lead.communication_type"
                                class="h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                                @change="setTouchCommunicationType(lead.communication_type)"
                            >
                                <option value="0">Выбрать</option>
                                <option v-for="ch in availableCommunicationTypes" :value="ch" :key="ch">{{ communications[ch] }}</option>
                            </select>-->
              <ul>
                <li class="mb-1">
                  <input type="radio" checked="true" />
                  <span class="ml-2">
                    {{
                      communications.find(
                        (item) => item.value === lead.communication_type
                      ).name
                    }}
                  </span>
                </li>
              </ul>
              <!-- <ul v-else>
                <li
                  class="mb-1"
                  v-for="ch in availableCommunicationTypes"
                  :key="ch"
                >
                  <input
                    disabled="true"
                    type="radio"
                    :value="ch"
                    v-model="lead.communication_type"
                    @change="setTouchCommunicationType(lead.communication_type)"
                  />
                  <span class="ml-2">{{ communications[ch] }}</span>
                </li>
              </ul> -->
            </div>
            <!--<div class="p-5 v-bbgray group relative">
                            <div v-if="lead.detail" class="flex items-center">
                                <div class="flex flex-col">
                                    <p class="text-gray-400 text-[10px] uppercase">ДЕТАЛИ</p>
                                    <span class="font-latobold text-base text-gray-700">{{ detail[lead.detail] }}</span>
                                </div>

                                <div class="absolute top-1/2 -translate-y-1/2 right-8 opacity-0 group-hover:opacity-100 transition-all duration-150">
                                    <a class="group cursor-pointer" @click="setLeadDetail(0)">
                                        <svg class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150">
                                            <use xlink:href="@/assets/i.svg#ico-no"></use>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <div v-else class="flex items-center w-full">
                                <p class="text-gray-400 leading-none w-full max-w-[100px] mr-6">ДЕТАЛИ</p>
                                <select
                                    class="h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                                    v-model="lead.detail"
                                    @change="setLeadDetail(lead.detail)"
                                >
                                    <option value="">Выбрать</option>
                                    <option v-for="(val, key) in detail" :key="val" :value="key">{{ val }}</option>
                                </select>
                            </div>
                        </div>-->
          </div>

          <div
            v-if="canShowClient"
            class="rounded-[4px] bg-white mt-4 relative order-conn"
          >
            <template v-if="lead.client_id != null">
              <template v-if="lead.client?.loader">
                <div class="flex">
                  <img src="https://api.tahocrm.ru/images/loader.svg" />
                </div>
              </template>
              <template v-else>
                <div class="flex relative group miw-w-full p-5 v-bbgray">
                  <a
                    class="flex items-center cursor-pointer group max-w-[75%]"
                    @click="showClient(lead.client)"
                  >
                    <div
                      class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4"
                    >
                      <span class="w-6 h-6 flex-cc">
                        <IcoPic
                          :pic="lead.client?.picture"
                          :name="lead.client?.name"
                        />
                      </span>
                    </div>
                    <div
                      class="flex flex-col justify-center overflow-hidden pr-8"
                    >
                      <span
                        class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                        >Клиент</span
                      >
                      <span
                        class="font-latobold text-base cursor-pointer group-hover:text-green-500 transition-colors duration-300"
                        >{{ lead.client?.name }}</span
                      >
                    </div>
                  </a>

                  <div
                    v-if="
                      ['Manager', 'Studio', 'Hosd'].includes(role) &&
                      [2, 3, 10].includes(lead.status)
                    "
                    class="absolute top-1/2 -translate-y-1/2 right-2 opacity-0 group-hover:opacity-100 transition-all duration-150"
                  >
                    <a class="cursor-pointer" @click="dClient">
                      <svg
                        class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                      >
                        <use xlink:href="@/assets/i.svg#ico-no"></use>
                      </svg>
                    </a>
                  </div>
                </div>
              </template>
            </template>

            <div v-else class="p-5">
              <div v-if="canEditClient" class="flex items-center w-full">
                <p class="text-gray-400 leading-none w-full max-w-[100px] mr-6">
                  КЛИЕНТ
                </p>
                <div class="flex-1 relative">
                  <input
                    :disabled="lead.status == 1"
                    type="text"
                    v-model.trim="text_client"
                    class="bg-slate-50 h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                    @click="findLeadClient(text_client)"
                    @input="findLeadClient(text_client)"
                  />
                  <div
                    class="absolute w-full top-full bg-white left-0 rounded-b-lg border border-green-500 border-t-0 z-10 hidden"
                    :class="{ '!block': selectLeadClients?.length > 0 }"
                  >
                    <div
                      v-for="cl in selectLeadClients"
                      :key="cl"
                      @click="setLeadClient(cl.id), (text_client = '')"
                      class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                    >
                      <span>{{ cl.name }}</span>
                      <span class="text-xs">{{ cl.iid }}</span>
                    </div>
                  </div>
                  <div
                    v-if="
                      text_client.length > 0 && selectLeadClients?.length <= 0
                    "
                    class="absolute w-full top-full bg-white left-0 rounded-b-lg border border-green-500 border-t-0 z-10 block"
                  >
                    <div
                      class="px-4 py-2 flex justify-between items-center hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                    >
                      <span>НЕ&nbsp;НАЙДЕН</span>
                      <button class="v-btn bg-green-500 hover:bg-green-400">
                        <span
                          class="v-btn_text flex items-center"
                          @click="
                            addLeadClient(text_client), (text_client = '')
                          "
                        >
                          <svg
                            class="fill-white w-16px h-16px mr-2 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linea"
                          >
                            <use xlink:href="/img/i.5cce8585.svg#ico-no"></use>
                          </svg>
                          СОЗДАТЬ
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <p v-else class="text-rose-400 leading-none text-center">
                Клиент не добавлен!
              </p>
            </div>

            <template v-if="lead.client_id != null">
              <div class="p-5 v-bbgray flex items-center group relative">
                <template v-if="lead.company_id != null">
                  <template v-if="lead.company?.loader">
                    <div class="flex">
                      <img src="https://api.tahocrm.ru/images/loader.svg" />
                    </div>
                  </template>
                  <template v-else>
                    <a
                      class="flex cursor-pointer group"
                      @click="showСompanyfromLead(lead.company)"
                    >
                      <div
                        class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4"
                      >
                        <span class="w-6 h-6 flex-cc">
                          <IcoPic
                            :pic="lead.company?.picture"
                            :name="lead.company?.name"
                          />
                        </span>
                      </div>
                      <div
                        class="flex flex-col justify-center overflow-hidden pr-8"
                      >
                        <span
                          class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                          >Компания</span
                        >
                        <span
                          class="font-latobold text-base cursor-pointer group-hover:text-green-500 transition-colors duration-300"
                        >
                          {{ lead.company?.secondname }}
                          {{ lead.company?.name }}
                        </span>
                      </div>
                    </a>
                    <div
                      v-if="
                        ['Manager', 'Studio', 'Hosd'].includes(role) &&
                        lead.company_id &&
                        [2, 3, 10, 11, 12, 13].includes(lead.status)
                      "
                      class="absolute top-1/2 -translate-y-1/2 right-2 opacity-0 group-hover:opacity-100 transition-all duration-150"
                    >
                      <a class="group cursor-pointer" @click="delLeadCompany()">
                        <svg
                          class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                        >
                          <use xlink:href="@/assets/i.svg#ico-no"></use>
                        </svg>
                      </a>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div
                    v-if="
                      ['Manager', 'Logist', 'Studio', 'Hosd', 'Check'].includes(
                        role
                      ) &&
                      selectLeadCompanies &&
                      selectLeadCompanies.length > 0
                    "
                    class="flex items-center w-full"
                  >
                    <p
                      class="text-gray-400 leading-none w-full max-w-[100px] mr-6"
                    >
                      Выберите компанию
                    </p>
                    <div class="relative w-full">
                      <input
                        :disabled="lead.status == 1"
                        type="text"
                        v-model.trim="text_company"
                        class="bg-slate-50 h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                        @input="findCompany()"
                        @click="findCompany()"
                      />
                      <div
                        v-if="companyList && companyList.length > 0"
                        class="absolute w-full top-full bg-white left-0 border-2 border-green-800 border-t-0 z-10"
                      >
                        <div
                          v-for="co in companyList"
                          :key="co"
                          @click="pickCompany(co)"
                          class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                        >
                          <span>{{ co.secondname }} {{ co.name }}</span>
                          <span class="text-xs">{{ co.position }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p v-else class="text-rose-500 text-sm">
                    Компания не выбрана!
                  </p>
                </template>
              </div>

              <div class="p-5 v-bbgray flex items-center group relative">
                <template v-if="lead.contact_id != null">
                  <template v-if="lead.contact?.loader">
                    <div class="flex">
                      <img src="https://api.tahocrm.ru/images/loader.svg" />
                    </div>
                  </template>
                  <template v-else>
                    <a
                      class="flex cursor-pointer group"
                      @click="showContactfromLead(lead.contact)"
                    >
                      <div
                        class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4"
                      >
                        <span class="w-6 h-6 flex-cc">
                          <IcoPic
                            :pic="lead.contact?.picture"
                            :name="lead.contact?.name"
                          />
                        </span>
                      </div>
                      <div
                        class="flex flex-col justify-center overflow-hidden pr-8"
                      >
                        <span
                          class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                          >Контакт</span
                        >
                        <span
                          class="font-latobold text-base cursor-pointer group-hover:text-green-500 transition-colors duration-300"
                          >{{ lead.contact?.secondname }}
                          {{ lead.contact?.name }}</span
                        >
                      </div>
                    </a>
                    <template v-if="lead.contact?.phone">
                      <a
                        class="rounded-full absolute top-1/2 -translate-y-1/2 right-16 w-7 h-7 hover:w-36 overflow-hidden flex items-center bg-green-500 transition-all duration-300"
                        :href="'tel:' + lead.contact.phone"
                      >
                        <svg class="w-4 h-4 ml-[6px] fill-white">
                          <use xlink:href="@/assets/i.svg#ico-phone"></use>
                        </svg>
                        <span class="absolute left-7 p-1 text-white">{{
                          lead.contact?.phone
                        }}</span>
                      </a>
                    </template>
                    <div
                      v-if="
                        ['Manager', 'Studio', 'Hosd'].includes(role) &&
                        lead.contact_id &&
                        [2, 3, 10, 11, 12, 13].includes(lead.status)
                      "
                      class="absolute top-1/2 -translate-y-1/2 right-2 opacity-0 group-hover:opacity-100 transition-all duration-150"
                    >
                      <a class="group cursor-pointer" @click="delLeadContact()">
                        <svg
                          class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                        >
                          <use xlink:href="@/assets/i.svg#ico-no"></use>
                        </svg>
                      </a>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div
                    v-if="
                      ['Manager', 'Logist', 'Studio', 'Hosd', 'Check'].includes(
                        role
                      ) &&
                      selectLeadContacts &&
                      selectLeadContacts.length > 0
                    "
                    class="flex items-center w-full"
                  >
                    <p
                      class="text-gray-400 leading-none w-full max-w-[100px] mr-6"
                    >
                      Выберите контакт
                    </p>
                    <div class="relative w-full">
                      <input
                        :disabled="lead.status == 1"
                        type="text"
                        v-model.trim="text_contact"
                        class="bg-slate-50 h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                        @input="findContact()"
                        @click="findContact()"
                      />
                      <div
                        v-if="contactList && contactList.length > 0"
                        class="absolute w-full top-full bg-white left-0 border-2 border-green-800 border-t-0 z-10"
                      >
                        <div
                          v-for="co in contactList"
                          :key="co"
                          @click="pickContact(co)"
                          class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                        >
                          <span>{{ co.secondname }} {{ co.name }}</span>
                          <span class="text-xs">{{ co.position }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p v-else class="text-rose-500 text-sm">
                    Контакт не добавлен!
                  </p>
                </template>
              </div>

              <div class="p-5 v-bbgray">
                <div v-if="lead.client?.business" class="flex items-center">
                  <div class="flex flex-col">
                    <p class="text-gray-400 text-[10px] uppercase">
                      Направление
                    </p>
                    <span
                      v-if="lead.client?.business != 5"
                      class="font-latobold text-base text-gray-700"
                    >
                      {{ b[lead.client?.business] }}
                    </span>
                    <span v-else class="font-latobold text-base text-gray-700">
                      {{ lead.client?.business_dop }}
                    </span>
                  </div>
                </div>
                <template v-else>
                  <div class="flex items-center w-full">
                    <p
                      class="text-gray-400 leading-none w-full max-w-[100px] mr-6"
                    >
                      Направление
                    </p>
                    <select
                      class="h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                      v-model="business"
                      @change="
                        setClientBusiness({
                          business: business,
                          business_dop: null,
                        })
                      "
                    >
                      <option v-for="(val, key) in b" :key="val" :value="key">
                        {{ val }}
                      </option>
                    </select>
                  </div>

                  <template v-if="business == 5">
                    <div class="flex items-center w-full">
                      <p
                        class="text-gray-400 leading-none w-full max-w-[100px] mr-6"
                      >
                        КОММЕНТАРИЙ
                      </p>
                      <input
                        class="h-9 px-3 py-2 mt-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                        type="text"
                        placeholder="Впишите сферу бизнеса"
                        v-model="business_dop"
                      />
                    </div>
                    <button
                      class="v-btn v-btn--sm bg-green-500 hover:bg-green-400 ml-auto mt-2"
                      @click="
                        setClientBusiness({
                          business: 5,
                          business_dop: business_dop,
                        })
                      "
                    >
                      <span class="v-btn_text flex items-center"
                        >СОХРАНИТЬ</span
                      >
                    </button>
                  </template>
                </template>
              </div>

              <div class="p-5 v-bbgray">
                <div v-if="lead.client?.ts" class="flex items-center">
                  <div class="flex flex-col">
                    <p class="text-gray-400 text-[10px] uppercase">КОЛ-ВО ТС</p>
                    <span class="font-latobold text-base text-gray-700">
                      {{ lead.client?.ts }}
                    </span>
                  </div>
                </div>
                <template v-else>
                  <div class="flex items-center w-full">
                    <p
                      class="text-gray-400 leading-none w-full max-w-[100px] mr-6"
                    >
                      КОЛ-ВО ТС <br />У КЛИЕНТА
                    </p>

                    <input
                      class="h-9 px-3 py-2 mt-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                      type="text"
                      v-model="ts"
                      @change="setClientTs(ts)"
                    />
                  </div>
                </template>
              </div>
            </template>
          </div>
          <div
            class="bg-white order-conn mt-4 p-5 relative rounded-[4px]"
            v-if="canAddClient"
          >
            <div style="position: relative" v-if="lead.client_id">
              <a
                @click="editNewClient"
                class="flex items-center cursor-pointer group max-w-[75%]"
              >
                <div
                  class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4"
                >
                  <span class="w-6 h-6 flex-cc">
                    <span
                      class="text-white text-xl w-11 h-11 leading-none rounded-full flex items-center justify-center"
                      >К</span
                    >
                  </span>
                </div>
                <div class="flex flex-col justify-center overflow-hidden pr-8">
                  <span
                    class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                    >Клиент</span
                  >
                  <span
                    class="font-latobold text-base cursor-pointer group-hover:text-green-500 transition-colors duration-300"
                    >{{ lead?.client?.name }}</span
                  >
                </div>
              </a>
              <a
                class="cursor-pointer"
                style="position: absolute; right: 0; top: calc(50% - 8px)"
                @click="dClient"
              >
                <svg
                  class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                >
                  <use xlink:href="@/assets/i.svg#ico-no"></use>
                </svg>
              </a>
            </div>

            <div v-else>
              <button
                @click="addNewClient"
                class="v-btn v-btn--sm bg-green-500 hover:bg-green-400"
              >
                <span class="v-btn_text flex items-center">
                  <svg
                    class="fill-white w-16px h-16px mr-3 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linear"
                  >
                    <use xlink:href="/img/i.cc1a44ec.svg#ico-no"></use>
                  </svg>
                  <span>Добавить клиента</span>
                </span>
              </button>
            </div>
            <div
              v-if="showClientEdit"
              id="popnew"
              class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
            >
              <ClientEdit @close="clientEditClose" />
            </div>
          </div>

          <div class="bg-white order-conn mt-4 relative rounded-[4px]">
            <div
              v-if="['Manager', 'Studio', 'Hosd', 'Check'].includes(role)"
              class="flex relative group p-5 v-bbgray"
            >
              <div v-if="lead.manager_id" class="flex justify-between">
                <div class="flex items-center cursor-pointer group">
                  <div
                    class="min-w-[1.5rem] w-11 h-11 rounded-full mr-4 overflow-hidden bg-green-500"
                  >
                    <span class="w-6 h-6">
                      <IcoPic :pic="lead.upicture" :name="lead.uname" />
                    </span>
                  </div>
                  <div
                    class="flex flex-col justify-center overflow-hidden pr-8"
                  >
                    <span
                      class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                      >Менеджер</span
                    >
                    <span
                      class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
                      >{{ lead.uname }} {{ lead.usecondname }}</span
                    >
                  </div>
                </div>
                <div
                  v-if="lead.manager_id && [3].includes(lead.status)"
                  class="absolute top-1/2 -translate-y-1/2 right-8 opacity-0 group-hover:opacity-100 transition-all duration-150"
                >
                  <a class="group cursor-pointer" @click="delLeadManager()">
                    <svg
                      class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                </div>
              </div>

              <template v-else>
                <div class="flex items-center w-full">
                  <p
                    class="text-gray-400 leading-none w-full max-w-[100px] mr-6"
                  >
                    Менеджер
                  </p>
                  <div class="relative w-full">
                    <input
                      type="text"
                      v-model.trim="text_manager"
                      class="bg-white h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                      @input="findManager()"
                      @click="findManager()"
                    />
                    <div
                      v-if="managerList.length"
                      class="absolute w-full top-full bg-white left-0 border-2 border-green-800 border-t-0 z-10"
                    >
                      <div
                        v-for="m in managerList"
                        :key="m"
                        @click="setLeadManager(m)"
                        class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                      >
                        <span>{{ m.secondname }} {{ m.name }}</span>
                        <span class="text-xs">{{ m.uid }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>

            <div class="flex relative group p-5 v-bbgray">
              <div v-if="lead.who" class="flex justify-between">
                <div class="flex items-center cursor-pointer group">
                  <div
                    class="min-w-[1.5rem] w-11 h-11 rounded-full mr-4 overflow-hidden bg-green-500"
                  >
                    <span class="w-6 h-6">
                      <IcoPic :pic="lead.wpicture" :name="lead.wname" />
                    </span>
                  </div>
                  <div
                    class="flex flex-col justify-center overflow-hidden pr-8"
                  >
                    <span
                      class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                      >СОЗДАТЕЛЬ</span
                    >
                    <span
                      class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
                      >{{ lead.wname }} {{ lead.wsecondname }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col ml-4 mr-12 flex-1 mb-4 max-l:mx-0">
          <div
            v-if="
              ['Manager', 'Logist', 'Studio', 'Hosd', 'Check'].includes(role) &&
              canShowGar
            "
            class="flex justify-between border-t-2 border-gray-400 w-[90%] mt-10 mb-1 mx-auto max-m:hidden"
          >
            <div class="flex flex-col items-center -mt-6 -ml-6 relative">
              <span
                @click="toggleGarl('showTouches')"
                class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center"
              >
                <svg class="w-5 h-5 fill-white">
                  <use xlink:href="@/assets/i.svg#nav-clients"></use>
                </svg>
              </span>
              <span
                class="text-[10px] text-gray-400 leading-none uppercase mt-1"
                >КАСАНИЕ</span
              >

              <div
                v-if="showTouches && garland.touches.length > 0"
                class="absolute-list"
              >
                <p class="font-latobold text-base text-gray-700">Касания</p>
                <a
                  v-for="touch in garland.touches"
                  :key="touch"
                  @click="goToTouch(touch.id)"
                  >№{{ touch.id }}</a
                >
              </div>
            </div>

            <div class="flex flex-col items-center -mt-6 relative">
              <span
                @click="toggleGarl('showLeads')"
                class="rounded-full h-11 w-11 bg-gray-400 flex items-center justify-center"
                :class="{
                  '!bg-status-5': garland.leads.length > 0,
                  '!bg-status-7': lead.status == 11,
                }"
              >
                <svg class="w-5 h-5 fill-white">
                  <use xlink:href="@/assets/i.svg#status-1"></use>
                </svg>
              </span>
              <span
                class="text-[10px] text-gray-400 leading-none uppercase mt-1"
                >ЛИД</span
              >
              <!-- 
                        <div v-if="showLeads && garland.leads.length > 0" class="absolute-list">
                            <p class='font-latobold text-base text-gray-700'>Лиды из касания</p>
                            <a v-for="lead in garland.leads" :key="lead">№{{ lead.iid }}</a>
                        </div> -->
            </div>

            <div class="flex flex-col items-center -mt-6 relative">
              <span
                @click="toggleGarl('showZayavka')"
                class="rounded-full h-11 w-11 flex items-center justify-center bg-gray-400"
                :class="{
                  '!bg-status-5': garland.bids.length,
                  '!bg-status-7': lead.status == 11,
                }"
              >
                <svg class="w-5 h-5 fill-white">
                  <use xlink:href="@/assets/i.svg#doc"></use>
                </svg>
              </span>
              <span
                class="text-[10px] text-gray-400 leading-none uppercase mt-1"
                >ЗАЯВКА</span
              >

              <div
                v-if="showZayavka && garland.bids.length"
                class="absolute-list"
              >
                <p class="font-latobold text-base text-gray-700">
                  Заявки из касания
                </p>
                <a
                  v-for="bid in garland.bids"
                  @click="goToBid(bid.id)"
                  :key="bid"
                  >№{{ bid.id }}</a
                >
              </div>
            </div>

            <div class="flex flex-col items-center -mt-6 relative">
              <span
                @click="toggleGarl('showSchet')"
                class="rounded-full h-11 w-11 flex items-center justify-center bg-gray-400"
                :class="{
                  '!bg-status-5': garland.invoices.length,
                  '!bg-status-7': lead.status == 11,
                }"
              >
                <svg class="w-5 h-5 fill-white">
                  <use xlink:href="@/assets/i.svg#doc"></use>
                </svg>
              </span>
              <span
                class="text-[10px] text-gray-400 leading-none uppercase mt-1"
                >СЧЕТ</span
              >

              <div
                v-if="showSchet && garland.invoices.length"
                class="absolute-list"
              >
                <p class="font-latobold text-base text-gray-700">
                  Счета из касания
                </p>
                <a
                  v-for="invoice in garland.invoices"
                  @click="goToInvoice(invoice.id)"
                  :key="invoice"
                  >{{ invoice.invoice_number }}</a
                >
              </div>
            </div>

            <div class="flex flex-col items-center -mt-6 relative">
              <span
                @click="toggleGarl('showOrderLet')"
                class="rounded-full h-11 w-11 flex items-center justify-center bg-gray-400"
                :class="{
                  '!bg-status-5': garland.orders.length,
                  '!bg-status-7': lead.status == 11,
                }"
              >
                <svg class="w-5 h-5 fill-white">
                  <use xlink:href="@/assets/i.svg#pay-1"></use>
                </svg>
              </span>
              <span
                class="text-[10px] text-gray-400 leading-none uppercase mt-1"
                >ЗАКАЗ</span
              >
              <div
                v-if="showOrderLet && garland.orders.length"
                class="absolute-list last-list"
              >
                <p class="font-latobold text-base text-gray-700">
                  Заказы из касания
                </p>
                <a
                  v-for="order in garland.orders"
                  @click="goToOrder(order.id)"
                  :key="order"
                  >№{{ order.iid }}</a
                >
              </div>
            </div>

            <div class="flex flex-col items-center -mt-6 -mr-6 relative">
              <span
                @click="toggleGarl('showAnketa')"
                class="rounded-full h-11 w-11 flex items-center justify-center bg-gray-400"
                :class="{
                  '!bg-status-5': garland.anketas.length,
                  '!bg-status-7': lead.status == 11,
                }"
              >
                <svg class="w-5 h-5 fill-white">
                  <use xlink:href="@/assets/i.svg#pay-2"></use>
                </svg>
              </span>
              <span
                class="text-[10px] text-gray-400 leading-none uppercase mt-1"
                >АНКЕТА</span
              >

              <div
                v-if="showAnketa && garland.anketas.length"
                class="absolute-list"
              >
                <p class="font-latobold text-base text-gray-700">
                  Анкеты из касания
                </p>
                <a v-for="anketa in garland.anketas" :key="anketa"
                  >№{{ anketa.id }}</a
                >
              </div>
            </div>
          </div>

          <div class="p-5 bg-white rounded-[4px] mt-4">
            <div class="flex justify-between items-center max-l:flex-wrap">
              <div class="max-w-[50%] w-full flex items-center">
                <span
                  class="rounded-full h-11 min-w-[2.75rem] w-11 bg-status-1 flex items-center justify-center mr-4 overflow-hidden"
                >
                  <svg class="w-5 h-5 fill-white">
                    <use xlink:href="/img/i.cc1a44ec.svg#nav-service"></use>
                  </svg>
                </span>
                <span class="text-base font-latobold">СУММА ЗАЯВКИ</span>
              </div>
              <div v-if="lead.sum" class="flex justify-between items-center">
                <div class="text-base font-latobold">{{ lead.sum }}&nbsp;₽</div>
                <a
                  class="group cursor-pointer ml-4"
                  @click="setLeadSum(0), (sum = 0)"
                >
                  <svg
                    class="fill-gray-400 w-4 h-4 group-hover:fill-rose-500 transition-colors duration-300"
                  >
                    <use xlink:href="@/assets/i.svg#ico-no"></use>
                  </svg>
                </a>
              </div>

              <template v-else>
                <div class="flex items-center w-full">
                  <input
                    :disabled="lead.status == 1"
                    class="h-9 px-3 py-2 mt-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                    type="text"
                    v-model="sum"
                    @change="setLeadSum(sum)"
                  />
                </div>
              </template>
            </div>
          </div>

          <!-- <div class="p-5 bg-white rounded-[4px] mt-4">
                        <div class="flex items-center max-l:flex-wrap">
                            <div class="flex items-center max-l:flex-wrap">
                                <span class="text-base font-latobold uppercase">Событие в 1С</span><br/>
                                <div class="flex items-center w-full">
                                    <textarea
                                        class="h-9 px-3 py-2 mt-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        v-model="lead.event_one_s"
                                        @change="setEvent1S(lead.event_one_s)"
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div> -->

          <div class="double-flex">
            <div class="w-[50%] mr-2 p-5 bg-white rounded-[4px] mt-4">
              <div class="flex items-center max-l:flex-wrap">
                <div class="flex items-center max-l:flex-wrap">
                  <span
                    class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4"
                  >
                    <svg class="fill-white w-5 h-5">
                      <use xlink:href="@/assets/i.svg#ico-timer"></use>
                    </svg>
                  </span>
                  <span class="text-base font-latobold uppercase"
                    >Задачи:
                  </span>
                  <div class="flex flex-wrap">
                    <div
                      v-for="item in lead.tasks"
                      :key="item"
                      class="preview ml15"
                    >
                      <a
                        class="ml-2 text-base font-latobold cursor-pointer hover:text-green-500 transition-colors duration-300"
                        :class="statusClasses[item.status]"
                        :title="altStatus[item.status]"
                        @click="showTask(item)"
                        >№{{ item.iid }}</a
                      >
                    </div>
                  </div>
                </div>

                <button
                  v-if="lead.status != 1"
                  class="v-btn v-btn--sm bg-green-500 hover:bg-green-400 ml-auto"
                >
                  <span
                    class="v-btn_text flex items-center"
                    @click="
                      tryAddTask({
                        object: 'lead',
                        oid: lead.id,
                        type: 105,
                        department: 1,
                      })
                    "
                  >
                    <svg
                      class="fill-white w-16px h-16px mr-3 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linear"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                    <span>ЗАДАЧА</span>
                  </span>
                </button>
                <button
                  v-else
                  class="v-btn v-btn--sm bg-green-300 hover:bg-green-400 ml-auto"
                >
                  <span class="v-btn_text flex items-center">
                    <svg
                      class="fill-white w-16px h-16px mr-3 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linear"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                    <span>ЗАДАЧА</span>
                  </span>
                </button>
              </div>
            </div>

            <div class="w-[50%] ml-2 p-5 bg-white rounded-[4px] mt-4">
              <div class="flex items-center max-l:flex-wrap h-full">
                <div class="flex items-center max-l:flex-wrap">
                  <span
                    class="rounded-full h-11 min-w-[2.75rem] w-11 bg-blue-500 flex items-center justify-center mr-4"
                  >
                    <svg class="fill-white w-5 h-5">
                      <use xlink:href="@/assets/i.svg#ico-phone"></use>
                    </svg>
                  </span>
                  <span class="text-base font-latobold uppercase"
                    >Телефония:
                  </span>
                  <span class="text-base font-latobold uppercase ml-4"
                    >8 800 000 00 00</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="p-5 bg-white mt-4 rounded-[4px]">
            <CommentList
              :id="parseInt(lead.id)"
              :iid="parseInt(lead.iid)"
              type="lead"
            />
          </div>

          <div
            v-if="otkaz || lead.status == 11"
            class="p-5 bg-white mt-4 rounded-[4px]"
          >
            <div v-if="lead.status == 11" class="flex items-center">
              <div
                class="min-w-[1.5rem] w-7 h-7 rounded-full bg-rose-500 flex items-center justify-center mr-4"
              >
                <svg class="w-4 h-4 fill-white">
                  <use xlink:href="@/assets/i.svg#ico-no"></use>
                </svg>
              </div>
              <div>
                <span class="blocktext-xs text-gray-800 font-latobold">{{
                  cancel[lead.cancel_id]
                }}</span>
                <span
                  v-if="lead.comment"
                  class="blocktext-xs text-gray-800 font-latobold block"
                  >{{ lead.comment }}</span
                >
              </div>
            </div>

            <div v-else class="flex flex-col justify-center">
              <h3 class="text-red-500 text-center">Укажите причину отказа</h3>
              <select
                class="w-full px-4 py-2 bg-white v-bgray rounded-md mb-3"
                v-model="cancel_id"
              >
                <option value="0" disabled selected>Выбрать причину</option>
                <option :value="key" v-for="(val, key) in cancel" :key="val">
                  {{ val }}
                </option>
              </select>
              <textarea
                type="text"
                class="w-full px-4 py-2 bg-white v-bgray rounded-md"
                v-model="lead.comment"
                placeholder="Комментарий"
              ></textarea>
              <button
                v-if="parseInt(cancel_id) > 0"
                class="v-btn v-btn--sm v-btn--sm bg-green-500 hover:bg-green-400 ml-auto mt-3"
                @click="
                  sendCancel({ cancel_id: cancel_id, comment: lead.comment })
                "
              >
                <span class="v-btn_text flex items-center">
                  <svg class="fill-white w-16px h-16px mr-3">
                    <use xlink:href="@/assets/i.svg#ico-telegram"></use>
                  </svg>
                  <span>ОТПРАВИТЬ</span>
                </span>
              </button>
            </div>
          </div>

          <div
            v-if="lead.status != 1 && lead.status != 11"
            class="flex justify-around mt-4"
          >
            <div
              v-if="!['Master'].includes(role)"
              style="width: fit-content"
              class="h-9 relative"
            >
              <button
                class="v-btn px-6 v-btn--sm bg-green-500 hover:bg-green-400"
                @click="showCreateBidBlock()"
              >
                <span class="v-btn_text flex items-center">
                  <svg
                    style="transform: rotate(45deg)"
                    class="fill-white w-4 h-4 mr-3"
                  >
                    <use xlink:href="@/assets/i.svg#ico-no"></use>
                  </svg>
                  <span>Создать заявку</span>
                </span>
              </button>

              <div
                v-if="showCreateBid"
                class="absolute max-h-[250px] overflow-y-auto w-[183px] top-full bg-white left-0 border-2 border-green-800 border-t-0"
              >
                <div
                  v-if="lead.taho == 'true'"
                  @click="createBid(1)"
                  class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                >
                  <span>Заявка по тахографам</span>
                </div>
                <div
                  v-if="lead.monitor == 'true'"
                  @click="createBid(2)"
                  class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                >
                  <span>Заявка по мониторингу</span>
                </div>
                <div
                  v-if="lead.card == 'true'"
                  @click="createBid(3)"
                  class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                >
                  <span>Заявка по картам</span>
                </div>
                <div
                  v-if="lead.propusk == 'true'"
                  @click="createBid(4)"
                  class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                >
                  <span>Заявка по пропускам</span>
                </div>
                <div
                  v-if="lead.teach == 'true'"
                  @click="createBid(5)"
                  class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                >
                  <span>Заявка по обучению</span>
                </div>
                <div
                  v-if="lead.driver == 'true'"
                  @click="createBid(7)"
                  class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                >
                  <span>Заявка по водителям</span>
                </div>
              </div>
            </div>

            <!-- <template
              v-if="
                ['Manager', 'Studio' , 'Hosd'].includes(role) &&
                [1, 8].includes(lead.status)
              "
            >
              <button
                class="v-btn v-btn--sm bg-emerald-600 hover:bg-emerald-500"
                @click="tryCreateOrder"
              >
                <span class="v-btn_text flex items-center">
                  <svg class="fill-white w-4 h-4 mr-3">
                    <use xlink:href="@/assets/i.svg#nav-flow"></use>
                  </svg>
                  <span>Создать заказ</span>
                </span>
              </button>
            </template> -->

            <template
              v-if="
                ['Manager', 'Studio', 'Hosd'].includes(role) &&
                ![11].includes(lead.status) &&
                !otkaz
              "
            >
              <button
                class="v-btn v-btn--sm bg-gray-400 hover:bg-gray-300"
                @click="tryCansel"
              >
                <span class="v-btn_text flex items-center">
                  <svg class="fill-white w-4 h-4 mr-3">
                    <use xlink:href="@/assets/i.svg#ico-no"></use>
                  </svg>
                  <span>Отказ</span>
                </span>
              </button>
            </template>

            <!-- <template
              v-if="
                ['Manager', 'Studio' , 'Hosd'].includes(role) &&
                ![8, 11].includes(lead.status) &&
                !otkaz
              "
            >
              <button
                class="v-btn v-btn--sm bg-rose-500 hover:bg-rose-400"
                @click="tryDelLead"
              >
                <span class="v-btn_text flex items-center">
                  <svg class="fill-white w-4 h-4 mr-3">
                    <use xlink:href="@/assets/i.svg#ico-delete"></use>
                  </svg>
                  <span>Удалить</span>
                </span>
              </button>
            </template> -->
          </div>

          <div v-if="lead.status == 1" class="flex justify-around mt-4">
            <div style="width: fit-content" class="h-9 relative">
              <template
                v-if="
                  ['Manager', 'Studio', 'Hosd'].includes(role) &&
                  [1].includes(lead.status)
                "
              >
                <!-- <button class="v-btn v-btn--sm bg-emerald-600 hover:bg-emerald-500"> -->
                <button
                  @click="startLeadFunc()"
                  class="v-btn v-btn--sm bg-emerald-600 hover:bg-emerald-500"
                >
                  <span class="v-btn_text flex items-center">
                    <svg class="fill-white w-4 h-4 mr-3">
                      <use xlink:href="@/assets/i.svg#nav-flow"></use>
                    </svg>
                    <span>Начать</span>
                  </span>
                </button>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="s.popup == 'task'"
        id="popnew"
        class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-max overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
      >
        <TaskEdit @close="close" />
      </div>

      <div
        v-if="s.popup"
        @click="close"
        id="overlay"
        class="fixed bg-cyan-900 z-10 w-screen h-screen top-0 left-0 opacity-80"
      ></div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import ClientEdit from "../components/client/ClientEdit";
import ContactEdit from "../components/client/ContactEdit";
import CommentList from "../components/CommentList.vue";
import IcoPic from "../components/ui/IcoPic.vue";
import IcoStatusLead from "../components/ui/IcoStatusLead.vue";

const com = {
  5: true, //Звонок
  6: true, //Письмо
  7: true, //Пришел в офис
  10: true, //Заявка через ЛК агента СРМ
  // 13: true, // "Заявка с сайта",
};
const com1 = {
  5: true, //Звонок
  6: true, //Письмо
  10: true, //Заявка через ЛК агента СРМ
  // 13: true, // "Заявка с сайта",
};
const vt_is2 = {
  5: true, //Звонок
  12: true, //заявка с рейтингом более 7
};

const nv_vh1 = {
  1: true,
  2: true,
  3: true,
  4: true,
};
const nv_vh2 = {
  5: true,
  6: true,
  7: true,
};
const nv_vh3 = {
  8: true,
};
const nv_is = {
  5: true,
  9: true,
};

const leadsByOther = {
  1: {
    //ПЕРВИЧНЫЙ
    1: {
      //ВХОДЯЩИЙ
      1: {
        //Интернет
        0: nv_vh1 /*{
                    1: true,
                    2: true,
                }*/,
      },
      2: {
        //Рекомендация
        0: nv_vh2 /*{
               4: true,
               5: true,
           }*/,
      },
      3: {
        //Агент
        0: nv_vh3 /*{
               5: true,
               6: true,
               7: true,
           }*/,
      },
    },
    2: {
      //ИСХОДЯЩИЙ
      15: {
        //Агент
        0: nv_is /*{
               5: true,
               6: true,
               7: true,
           }*/,
      },
    },
  },

  2: {
    //ВТОРИЧНЫЙ
    1: {
      //ВХОДЯЩИЙ
      21: {
        //Вторичная клиентская база
        /*1: {//Наименование клиента
                    5: true, //Звонок
                    6: true, //Письмо
                    7: true, //Пришел в офис
                    8: true, //Заявка через ЛК агента СРМ
                },
                6: {//Обзвон клиентов по контролю качества
                    12: true, //Заявка из отзыва с рейтингом
                }*/
        0: com,
      },
      22: {
        //Рассылка
        /*2: {//Ниатменование рассылки
               5: true, //Звонок
               6: true, //Письмо
               8: true, //Заявка через ЛК агента СРМ
           },
           6: {//Обзвон клиентов по контролю качества
               12: true, //Заявка из отзыва с рейтингом
           }*/
        0: com1,
      },
      23: {
        //Агент-сотрудник
        3: {
          //ФИО агента-сотрудника
          11: true, //Заявка через ЛК агента СРМ
        },
      },
    },
    2: {
      21: {
        0: vt_is2,
      },
    },
  },
};

function extract(o, k, ...p) {
  var v = o[k];
  if (!v) {
    var s = new Set();
    Object.values(o).forEach((x) => {
      if (p.length) {
        extract(x, ...p).forEach((a) => s.add(a));
      } else {
        Object.keys(x).forEach((a) => s.add(a));
      }
    });
    return [...s];
  }
  if (p.length) return extract(v, ...p);
  else return Object.keys(v);
}
const statusClasses = {
  1: "new_lead_task",
  2: "robot_lead_task",
  3: "appoint_lead_task",
  4: "started_lead_task",
  5: "done_lead_task",
  6: "finished_lead_task",
  7: "violate_lead_task",
  19: "violate_lead_task",
};
export default {
  name: "LeadEdit",

  components: { ContactEdit, CommentList, IcoPic, IcoStatusLead, ClientEdit },

  data() {
    return {
      showTouches: false,
      showLeads: false,
      showZayavka: false,
      showSchet: false,
      showAnketa: false,
      showOrderLet: false,

      altStatus: {
        1: "Черновик",
        2: "Новая",
        3: "Назначена",
        4: "Начата",
        5: "Выполнена",
        6: "Завершена",
        7: "Отклонена",
        19: "Нарушение",
      },

      moment: moment,
      direction: { 1: "ВХОДЯЩИЙ", 2: "ИСХОДЯЩИЙ" },
      type: { 1: "ПЕРВИЧНЫЙ", 2: "ВТОРИЧНЫЙ" },
      detail: {
        101: 'Задача "Как дела"',
        102: 'Задача "Пролонгация"',
        103: "Контроль качества",
        104: "Агент-сотрудник",
      },
      channel: [
        {
          valueCh: 1,
          name: "Интернет",
          show: false,
        },
        {
          valueCh: 2,
          name: "Рекомендация",
          show: false,
        },
        {
          valueCh: 3,
          name: "Агент",
          show: false,
        },
        {
          valueCh: 4,
          name: "Обзвон новой базы",
          show: false,
        },
        // {
        //   value: 1,
        //   name: "Обзвон новой базы",
        //   show: true,
        // },
        {
          valueCh: 21,
          name: "Вторичная клиентская база",
          show: false,
        },
        {
          valueCh: 22,
          name: "Рассылка",
          show: false,
        },
        {
          valueCh: 23,
          name: "Агент-сотрудник",
          show: false,
        },
      ],
      cancel: {
        101: "Нецелевое касание: другой регион",
        102: "Нецелевое касание: б/у оборудование",
        103: "Нецелевое касание: экономически нецелесообразно",
        104: "Нецелевое касание: карты тахографа",
        105: "Нецелевое касание: консультация",
        106: "Нецелевое касание: недозвон",
        201: "Целевое касание: дорого",
        202: "Целевое касание: другое",
        //203: "другое",
      },
      communications: [
        {
          value: 1,
          name: "Интернет контекст звонок",
          show: false,
        },
        {
          value: 2,
          name: "Интернет контекст заявка с сайта",
          show: false,
        },
        {
          value: 3,
          name: "Интернет СЕО звонок",
          show: false,
        },
        {
          value: 4,
          name: "Интернет СЕО заявка с сайта",
          show: false,
        },
        {
          value: 5,
          name: "Звонок Исходящий",
          show: false,
        },
        {
          value: 6,
          name: "Письмо",
          show: false,
        },
        {
          value: 7,
          name: "Пришел в офис",
          show: false,
        },
        {
          value: 8,
          name: "Заявка через ЛК агента СРМ",
          show: false,
        },
        {
          value: 9,
          name: "Рассылка/Звонок",
          show: false,
        },
        {
          value: 10,
          name: "Заявка через ЛК клиента в СРМ",
          show: false,
        },
        {
          value: 11,
          name: "Заявка через ЛК агента-сотрудника",
          show: false,
        },
        {
          value: 13,
          name: "Заявка с сайта",
          show: false,
        },
        {
          value: 15,
          name: "Звонок Входящий",
          show: false,
        },
      ],
      channelDetailType: {
        1: "Наименование клиента",
        2: "Ниатменование рассылки",
        3: "ФИО агента-сотрудника",
        4: 'Пролонгация заказа, Задача "Пролонгация"',
        5: "Обзвон неактивных клиентов",
        6: "Обзвон клиентов по контролю качества",
        7: "Интернет контекст",
        8: "Интернет СЕО",
        9: "Наименование клиента",
        10: "Наименование агента",
        11: "Вид базы",
      },
      b: {
        1: "Логистический бизнес (перевозки)",
        2: "Строительный бизнес, спецтехника",
        3: "Среднегабаритная перевозка (городские перевозки)",
        4: "Собственная доставка (свой транспортный отдел)",
        5: "Другое",
      },
      business: "",
      business_dop: "",
      ts: "",
      sum: "",
      otkaz: false,
      showCreateBid: false,
      cancel_id: 0,
      text_client: "",
      text_company: "",
      text_contact: "",
      text_price: "",
      text_comment: "",
      text_manager: "",
      explaintext: "",
      editPrice: {},
      timer: { time: null, color: null },
      companyList: [],
      contactList: [],
      priceList: [],
      managerList: [],
      st: {
        1: "Новый",
        2: "Выполняется",
        6: "Выполнен",
        11: "Отказ",
        8: "Нарушение",
      },
      newClient: {},
      showClientEdit: false,
      statusClasses: statusClasses,
      canShowGar: false,
    };
  },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "role",
      "garland",
      "roles",
      "lead",
      "selectLeadClients",
      "selectLeadCompanies",
      "selectLeadContacts",
      "selectManagers",
    ]),
    availableChannels() {
      return extract(leadsByOther, this.lead.type, this.lead.direction);
    },
    availableChannelDetails() {
      return extract(
        leadsByOther,
        this.lead.type,
        this.lead.direction,
        this.lead.channel
      );
    },
    availableCommunicationTypes() {
      return extract(
        leadsByOther,
        this.lead.type,
        this.lead.direction,
        this.lead.channel,
        this.lead.channel_detail_type
      );
    },
    canEditClient() {
      return ["Manager", "Logist", "Studio", "Check"].includes(this.role);
    },
    canShowClient() {
      return [
        this.canEditClient,
        this.lead.direction,
        this.lead.type == 2,
      ].every(Boolean);
    },
    canAddClient() {
      return [
        this.canEditClient,
        this.lead.direction,
        this.lead.type == 1,
      ].every(Boolean);
    },
    canShowChannel() {
      return [
        this.lead.direction && this.lead.direction != 0,
        this.lead.type && this.lead.type != 0,
      ].every(Boolean);
    },
    canShowChannelDetailType() {
      return 21 == this.lead.channel && 2 == this.lead.direction;
    },
    canShowChannelDetailValue() {
      return ["4", "5", "6"].includes(this.lead.channel_detail_type);
    },
    canShowCommunicationType() {
      return [1, 2, 3, 4, 15, 21, 22, 23].includes(this.lead.channel);
    },
    canCloseLead() {
      var set = new Set(this.lead.tasks.map((x) => x.status));
      return ![2, 3, 4].some((x) => set.has(x));
    },
  },

  methods: {
    ...mapActions([
      "getLead",
      "setLead",
      "getGarladnsLead",
      "kindOfLead",
      "findLeadClient",
      "setLeadClient",
      "delLeadClient", // startLead(ctx) {

      // },
      "addLeadClient",
      "clearLeadContactsCars",
      "setLeadCompany",
      "delLeadCompany",
      "setLeadContact",
      "delLeadContact",
      "updContact",
      "delLead",
      "setClient",
      "setClientContact",
      "showClient",
      "showContact",
      "setLeadManager",
      "delLeadManager",
      "setSettings",
      "addTask",
      "showTask",
      "sendCancel",
      "addOrder",
      "editOrderId",
      "setClientBusiness",
      "changeBPL",
      "setLeadChannel",
      "setLeadDirection",
      "setLeadType",
      "setLeadDetail",
      "setClientTs",
      "setLeadSum",
      "startLead",
      "setEvent1S",
      "setLeadChannelDetailType",
      "setLeadChannelDetailValue",
      "setLeadCommunicationType",
      "addClientByLead",
      "goToTouchFromLead",
      "createBidFromLead",
      "goToBidNow",
      "goToInvoiceNow",
      "goToOrderNow",
    ]),
    showCreateBidBlock() {
      if (
        (this.lead.taho == false || this.lead.taho == null) &&
        (this.lead.monitor == false || this.lead.monitor == null) &&
        (this.lead.card == false || this.lead.card == null) &&
        (this.lead.propusk == false || this.lead.propusk == null) &&
        (this.lead.teach == false || this.lead.teach == null) &&
        (this.lead.driver == false || this.lead.driver == null)
      ) {
        alert("Нельзя создать заявку, так как не выбран ее тип");
        return 0;
      }
      if (this.canCloseLead) {
        this.showCreateBid = !this.showCreateBid;
      } else {
        alert("Нельзя создать заявку если задачи не завершены");
        return 0;
      }
    },
    createBid(parent_id) {
      this.s.popup = null;
      this.createBidFromLead(parent_id);
    },
    dClient() {
      this.contactList = null;
      this.tsList = null;
      return this.delLeadClient();
    },

    close() {
      this.s.popup = null;
      this.getLead();
    },

    closeContact() {
      this.getLead();
      this.s.popup = null;
    },

    // КОМПАНИЯ
    findCompany() {
      // console.log("findCompany: ", this.text_company);
      if (this.text_company != "") {
        this.companyList = this.selectLeadCompanies.filter((item) => {
          console.log("item", item);
          let name = null;
          let full = null;
          if (item.name != null) {
            name =
              item.name
                .toLowerCase()
                .indexOf(this.text_company.toLowerCase()) !== -1;
          }
          if (item.full != null) {
            full =
              item.full
                .toLowerCase()
                .indexOf(this.text_company.toLowerCase()) !== -1;
          }
          if (name || full) {
            return true;
          }
          return false;
        });
      } else {
        this.companyList = this.selectLeadCompanies;
      }
    },

    pickCompany(item) {
      this.setLeadCompany(item);
      this.text_contact = "";
    },

    // КОНТАКТ
    findContact() {
      // console.log("findContact: ", this.text_contact);
      if (this.text_contact != "") {
        this.contactList = this.selectLeadContacts.filter((item) => {
          console.log("item", item);
          let name = null;
          let secondname = null;
          if (item.name != null) {
            name =
              item.name
                .toLowerCase()
                .indexOf(this.text_contact.toLowerCase()) !== -1;
          }
          if (item.secondname != null) {
            secondname =
              item.secondname
                .toLowerCase()
                .indexOf(this.text_contact.toLowerCase()) !== -1;
          }
          if (name || secondname) {
            return true;
          }
          return false;
        });
      } else {
        this.contactList = this.selectLeadContacts;
      }
    },

    startLeadFunc() {
      this.startLead();
    },

    pickContact(item) {
      this.setLeadContact(item);
      this.text_contact = "";
    },
    toggleGarl(garlShow) {
      if (garlShow == "showTouches") {
        this.showTouches = !this.showTouches;
        this.showLeads = false;
        this.showZayavka = false;
        this.showSchet = false;
        this.showAnketa = false;
        this.showinvoice = false;
        this.showOrderLet = false;
      }
      if (garlShow == "showLeads") {
        this.showTouches = false;
        this.showLeads = !this.showLeads;
        this.showZayavka = false;
        this.showSchet = false;
        this.showAnketa = false;
        this.showinvoice = false;
        this.showOrderLet = false;
      }
      if (garlShow == "showZayavka") {
        this.showTouches = false;
        this.showZayavka = !this.showZayavka;
        this.showSchet = false;
        this.showAnketa = false;
        this.showLeads = false;
        this.showinvoice = false;
        this.showOrderLet = false;
      }
      if (garlShow == "showSchet") {
        this.showTouches = false;
        this.showSchet = !this.showSchet;
        this.showAnketa = false;
        this.showinvoice = false;
        this.showLeads = false;
        this.showZayavka = false;
        this.showOrderLet = false;
      }
      if (garlShow == "showAnketa") {
        this.showTouches = false;
        this.showAnketa = !this.showAnketa;
        this.showLeads = false;
        this.showZayavka = false;
        this.showSchet = false;
        this.showinvoice = false;
        this.showOrderLet = false;
      }
      if (garlShow == "showinvoice") {
        this.showTouches = false;
        this.showinvoice = !this.showinvoice;
        this.showLeads = false;
        this.showZayavka = false;
        this.showSchet = false;
        this.showAnketa = false;
        this.showOrderLet = false;
      }
      if (garlShow == "showOrderLet") {
        this.showTouches = false;
        this.showinvoice = false;
        this.showLeads = false;
        this.showZayavka = false;
        this.showSchet = false;
        this.showAnketa = false;
        this.showOrderLet = !this.showOrderLet;
      }
    },
    showContactfromLead(contact) {
      this.s.back = "/lead/edit";
      this.setSettings(this.s);
      this.showContact(contact);
    },

    findManager() {
      this.managerList = this.selectManagers.filter(
        (item) =>
          item.name.toLowerCase().indexOf(this.text_manager.toLowerCase()) !==
          -1
      );
    },

    Order() {
      let formData = new FormData();
      formData.append("lead_id", this.lead.id);
      formData.append("client_id", this.lead.client_id);
      this.addOrder(formData);
    },

    Tmr() {
      if (this.lead.timer) {
        let date = moment(this.lead.timer);
        let now = moment() - moment().utcOffset() * 60000 + 180 * 60000; // временная зона
        this.timer.time = moment.utc(date - now).format("HH:mm:ss");

        let t = date - now;

        if (t > 0) {
          this.timer.color = "1";
        } else {
          this.timer.color = "7";
          t = t * -1;
        }
        let hours = Math.floor(t / (1000 * 60 * 60));
        if (hours < 10) {
          hours = "0" + hours;
        }
        let minutes = Math.floor((t / (1000 * 60)) % 60);
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        let seconds = Math.floor((t / 1000) % 60);
        if (seconds < 10) {
          seconds = "0" + seconds;
        }
        this.timer.time = hours + ":" + minutes + ":" + seconds;
      } else {
        this.timer.time = null;
      }
    },
    extract: extract,
    async trySetLeadDirection(value) {
      if (value == 0) {
        await this.setClient(0);
        await this.trySetLeadChannel(0);
        await this.dClient();
      }
      await this.setLeadDirection(value);
      var channels = extract(leadsByOther, this.lead.type, this.lead.direction);
      if (channels.length === 1) await this.trySetLeadChannel(channels[0]);
      if (value == 2) await this.setLeadCommunicationType(5);
    },
    async trySetLeadType(value) {
      if (value == 0) {
        await this.setClient(0);
        //await this.trySetLeadChannel(0);
        await this.trySetLeadDirection(0);
      }
      await this.setLeadType(value);
    },
    async trySetLeadChannel(value) {
      if (value == 0) {
        console.log(
          "Сбрасываем",
          this.lead.channel_detail_type,
          this.lead.channel_detail_value
        );
        await this.trySetLeadChannelDetailType(0);
      }
      await this.setLeadChannel(value);
      if (this.lead.direction == 2) await this.setLeadCommunicationType(5);
    },
    //async trySetLeadChannel(value){},
    async trySetLeadChannelDetailType(value) {
      if (value == 0) {
        console.log("Сам сброс");
        this.lead.communication_type = 0;
        this.lead.channel_detail_value = "";
        var rt = await this.setLeadCommunicationType(0);
        var rv = await this.setLeadChannelDetailValue("");
        console.log("Результаты: ", rt.data, rv.data);
      }
      await this.setLeadChannelDetailType(value);
    },
    async addNewClient() {
      var client = await this.addClientByLead();
      console.log("Новый клиент", client);
      await this.setLeadClient(client.id);
      await this.setClient(client);
      this.showClientEdit = true;
    },
    async editNewClient() {
      await this.setClient(this.lead.client);
      this.showClientEdit = true;
    },
    async clientEditClose() {
      await this.getLead();
      this.showClientEdit = false;
    },
    tryCreateOrder() {
      if (this.canCloseLead) return this.Order();
      else alert("Нельзя создать заказ если задачи не завершены");
    },
    tryCansel() {
      if (this.canCloseLead) this.otkaz = true;
      else alert("Нельзя оформить отказ если задачи не завершены");
    },
    tryDelLead() {
      if (this.canCloseLead) return this.delLead();
      else alert("Нельзя удалить лид если задачи не завершены");
    },
    tryAddTask(data) {
      if (this.canCloseLead) return this.addTask(data);
      else alert("В лиде должна быть только одна незавершенная задача");
    },
    goToTouch(touch) {
      this.goToTouchFromLead(touch);
    },
    goToLead(lead) {
      this.goToLeadFromTouch(lead);
    },
    goToBid(bid) {
      this.goToBidNow(bid);
    },
    goToInvoice(invoice) {
      this.goToInvoiceNow(invoice);
    },
    goToOrder(order) {
      this.goToOrderNow(order);
    },
    // onChangeBP() {
    //     // lead/business
    //     // alert('BEACH NIBBA');
    //     return this.changeBPL(this.lead.business_proc);
    // },
  },

  // mounted() {
  //     this.getLead();
  //     this.getGarladnsLead();

  //     window.setInterval(() => {
  //         this.Tmr();
  //     }, 1000);
  //     console.log('garland lead morda', this.garland);
  //     // console.log('garland', this.garland);
  //     console.log('garland lead localstorage', localStorage.getItem("garland"));
  //     // console.log('garland.touches', this.garland.touches);
  //     // console.log('garland', this.garland);
  //     window.DEBUG_LEAD = this;
  // },
  mounted() {
    console.log(localStorage.getItem("lead"));
    this.getLead();
    this.getGarladnsLead();

    window.setInterval(() => {
      this.Tmr();
      if (this.garland != {}) {
        this.canShowGar = true;
      }
    }, 1000);
    window.DEBUG_LEAD = this;
  },
};
</script>
<style>
.new_lead_task {
  color: gray;
}
.robot_lead_task {
  color: blue;
}
.appoint_lead_task {
  color: green;
}
.started_lead_task {
  color: darkviolet;
}
.done_lead_task {
  color: brown;
}
.finished_lead_task {
  color: olive;
}
.violate_lead_task {
  color: red;
}
</style>
