import API from "../../services/api";
import router from "@/router/router";
import moment from "moment";

export default {
    state: {
        clients: [],
        filteredClients: [],
        countClients: [],
        clientArhive: [],
        clientDoubles: [],
        client: {},
        merge: [],
        companies: [],
        company: {},
        contacts: [],
        contact: {},
        cars: [],
        touches: [],
        leads: [],
        cars_removed: [],
        car: {},
        orders: [],
        activs: [],
        activ: {},
        claims: [],
        docs: [],
        doc: {},
        tasks: [],
        task: {},
        managers: [],
        accessIds: [], // id юзеров имеющих право создавать заказы для этого клиента
        history: [],
        activity: {},
        counts: {},
        bids: {},
        invoices: {},
        listUnfilled: {},
        prolon: [],
        clientPaginator:{
            page: 0,
            itemsInPage: 10
        },
        clientListPort: [],
        clientsInfoChart: [],
    },

    mutations: {
        setClients: (state, clients) => {
            state.clients = clients;
            state.filteredClients = clients;
            state.countClients = clients;
            // state.clients = clients.filter((item) => parseInt(item.status) != 8);
        },

        filteredClients: (state, clients) => {
            state.filteredClients = clients;
        },

        setClientArhive: (state, clients) => {
            state.clientArhive = clients;
        },

        setClientDoubles: (state, clients) => {
            state.clientDoubles = clients;
        },

        setClientMerge: (state, merge) => {
            state.merge = merge;
        },

        setClient: (state, client) => {
            console.log('setClient', client);
            state.client = client;
        },
        setClientUnfilled: (state, listUnfilled) => {
            state.listUnfilled = listUnfilled;
        },
        setClientTabs: (state, tabs) => {
            if (tabs.companies) {
                state.companies = tabs.companies;
            }
            if(tabs.contacts) {
                state.contacts = tabs.contacts;
            }
            if (tabs.cars) {
                state.cars = tabs.cars;
            }
            if (tabs.cars_removed) {
                state.cars_removed = tabs.cars_removed;
            }
            if (tabs.orders) {
                state.orders = tabs.orders;
            }
            if (tabs.touches) {
                state.touches = tabs.touches;
            }
            if (tabs.leads) {
                state.leads = tabs.leads;
            }
            if (tabs.bids) {
                state.bids = tabs.bids;
            }
            if (tabs.invoices) {
                state.invoices = tabs.invoices;
            }
            if (tabs.activs) {
                state.activs = tabs.activs;
            }
            if (tabs.claims) {
                state.claims = tabs.claims;
            }
            if (tabs.docs) {
                state.docs = tabs.docs;
            }
            if (tabs.tasks) {
                state.tasks = tabs.tasks;
            }
            if (tabs.managers) {
                state.managers = tabs.managers;
            }                 
            if (tabs.accessIds) {
                state.accessIds = tabs.accessIds;
            }
            if (tabs.history) {
                state.history = tabs.history;
            }
            if (tabs.counts) {
                state.counts = tabs.counts;
            }
            if (tabs.prolon) {
                state.prolon = tabs.prolon;
            }
        },
        clearClientTabs: (state) => {
            state.companies = null;
            state.contacts = null;
            state.cars = null;
            state.cars_removed = null;
            state.orders = null;
            state.activs = null;
            state.claims = null;
            state.docs = null;
            state.tasks = null;
            state.managers = null;
            state.history = null;
        },
        setClientCompanies: (state, companies) => {
            state.companies = companies;
        },
        setCompany: (state, company) => {
            state.company = company;
        },
        setClientContacts: (state, contacts) => {
            state.contacts = contacts;
        },
        setContact: (state, contact) => {
            state.contact = contact;
        },
        setClientCars: (state, cars) => {
            state.cars = cars;
        },
        setCar: (state, car) => {
            state.car = car;
        },
        setActiv: (state, activ) => {
            state.activ = activ;
        },
        setClientDocs: (state, docs) => {
            state.docs = docs;
        },
        setDoc: (state, doc) => {
            state.doc = doc;
        },
        setClientTasks: (state, tasks) => {
            state.tasks = tasks;
        },
        setActivity(state, activity){
            console.log("активность", state, activity);
            state.activity = activity;
        },

        clientListPort(state, clients){
            console.log("клиенты на портфель", state, clients);
            state.clientListPort = clients;
        },

        clientsInfoChart(state, briefcase) {
            console.log("клиенты на диаграмму", state, briefcase);
            state.clientsInfoChart = briefcase;
        }
    },

    actions: {

        async getClientsInfoChart(ctx, data) {
            ctx.dispatch("setLoader", true);

            console.log('data', data);
            return API.GET("briefcase/chart").then((res) => {
                console.log("getClientsInfoChart", res.data.briefcase);
                res.data.briefcase ? ctx.commit("clientsInfoChart", res.data.briefcase) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                ctx.dispatch("setLoader", false);
            });
        },

        async getClientListIds(ctx, data) {
            ctx.dispatch("setLoader", true);

            console.log('data', data);
            return API.GET("client/get-by-ids", { ids: data.ids.join(' ')}).then((res) => {
                console.log("getClientListIds", res.data.clients);
                res.data.clients ? ctx.commit("clientListPort", res.data.clients) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                ctx.dispatch("setLoader", false);

            });
        },
        getClients(ctx) {
            // const t0 = performance.now();
            ctx.dispatch("setLoader", true);

            // ctx.dispatch("loadClients").then(() => {
            //     ctx.dispatch("statusClient", ctx.rootState.s);
            // });

            API.GET("client/clients").then((res) => {
                localStorage.setItem("clients", JSON.stringify(res.data));
                ctx.commit("setClients", res.data);
                ctx.dispatch("loadSettings").then(() => {
                    ctx.dispatch("statusClient", ctx.rootState.s);
                    ctx.dispatch("setLoader", false);
                });
                // console.log(performance.now() - t0, "ms");
            });
        },

        loadClients(ctx) {
            let clients = JSON.parse(localStorage.getItem("clients"));
            if (clients) {
                ctx.commit("setClients", clients);
            }
        },

        findClient(ctx, s) {
            let list = ctx.state.clients;

            if (s.findme != "" && s.findme != undefined) {
                list = list.filter((item) => {
                    let iid = false;
                    let full = false;
                    let name = false;
                    if(item.iid){
                        iid = item.iid.toString().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.full) {
                        full = item.full.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.name) {
                        name = item.name.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (iid || full || name) {
                        return true;
                    }
                    return false;
                });
            }

            if (s.manager != "" && s.manager != undefined) {
                if (s.manager == "none") {
                    list = list.filter((item) => item.manager_id === null);
                } else {
                    list = list.filter((item) => parseInt(item.manager_id) == parseInt(s.manager));
                }
            }

            if (s.start != "" && s.start != undefined) {
                list = list.filter((item) => item.lastorder >= s.start);
            }

            if (s.end != "" && s.end != undefined) {
                list = list.filter((item) => item.lastorder <= s.end);
            }

            // на память
            // if(s.active_start || s.active_end){
            //     list = list.filter(
            //         item=>
            //             item.activityHistory.some(
            //                 (act, pos)=> [
            //                     +new Date(act.date) >= (s.active_start ? +new Date(s.active_start) : -Infinity),
            //                     +new Date(act.date) <= (s.active_end ? +new Date(s.active_end) : Infinity),
            //                     act.type === "active" || item.activityHistory[pos - 1]?.type === "active"
            //                 ].every(Boolean)
            //             )
            //     );
            // }

            if (list.length != ctx.state.clients.length) {
                s.clear = true;
            }

            ctx.state.filteredClients = list;
            ctx.state.countClients = list;
            ctx.dispatch("setSettings", s);
        },

        statusClient(ctx, s) {
            ctx.dispatch("findClient", s);

            let list = ctx.state.filteredClients;

            if (s.status != "" && s.status != undefined) {
                let from = null;
                let to = null;
                if (s.status == 1) {
                    from = 0;
                    to = 2;
                }
                if (s.status == 10) {
                    from = 1;
                    to = 11;
                }
                if (s.status == 30) {
                    from = 10;
                    to = 31;
                }
                if (s.status == 50) {
                    from = 30;
                    to = 51;
                }
                if (s.status == 99) {
                    from = 50;
                    to = Infinity;
                }

                if (s.status == "zero") {
                    list = list.filter((item) => parseInt(item.ts) < 1 || item.ts == null);
                } else {
                    list = list.filter((item) => parseInt(item.ts) > from && parseInt(item.ts) < to);
                }
            }

            if (list.length != ctx.state.clients.length) {
                s.clear = true;
            }

            ctx.state.filteredClients = list;
        },

        changeTypeClient(ctx, data) {
            let formData = new FormData();
            formData.append("id", data.id);
            formData.append("type", data.type);
            API.POST("client/change-type", formData).then((res) => {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        async getClientArhive(ctx, filter_clients) {  
            ctx.dispatch("setLoader", true);
            console.log('loader 1', this.state.s.loader);
            console.log('clients', this.state.clientArhive);

            let formData = new FormData();
            formData.append("page", filter_clients.page);
            formData.append("page_size", filter_clients.page_size);
            formData.append("findme", filter_clients.findme || "");
            formData.append("activity", filter_clients.activity);
            formData.append("car_range", filter_clients.car_range);
            formData.append("active_start", filter_clients.active_start || "");
            formData.append("active_end", filter_clients.active_end || "");
            formData.append("manager", filter_clients.manager || "");
            formData.append("flag_no_active", filter_clients.flag_no_active || "");
            ctx.dispatch("loadSettings");            
            var res = await API.POST("client/arhive", formData);   
            ctx.commit("setClientArhive", res.data.clients);

            console.log('приезжает на бак ', res);
            ctx.dispatch("setLoader", false);

            console.log('loader 2', this.state.s.loader);

            ctx.commit("setActivity", res.data);
            return res;
        },

        getClientDoubles(ctx) {
            ctx.dispatch("setLoader", true);
            ctx.dispatch("loadSettings").then(() => {
                // console.log("getClientDoubles");
                API.GET("client/doubles").then((res) => {
                    console.log(res.data);
                    ctx.commit("setClientDoubles", res.data);
                    ctx.dispatch("setLoader", false);
                });
            });
        },

        setClient(ctx, client) {
            console.log("setClient");
            ctx.commit("setClient", client);
            localStorage.setItem("client", JSON.stringify(client));
        },

        showClient(ctx, client) {
            console.log("showClient", client.id);
            ctx.dispatch("setClient", client).then(() => {
                router.push("/client/view");
            });
        },

       async getClient(ctx) {
            console.log("getClient");
            let client = localStorage.getItem("client");
            if (client) {
                client = JSON.parse(client);
                ctx.commit("setClient", client);
                var res = await API.GET("client/tabs-info", { iid: client.iid });
                console.log("tabs", res.data);
                ctx.commit("setClient", res.data.client);
                ctx.commit("setClientTabs", res.data.tabs);
                ctx.commit("setClientUnfilled", res.data.tabs.list_unfilled);
                return res;
            }
        },

        // async getClientTabsCompany(ctx) {
        //     console.log("getClient");
        //     let client = localStorage.getItem("client");
        //     if (client) {
        //         client = JSON.parse(client);
        //         var res = await API.GET("client/tabs-companies", { iid: client.iid });
        //         ctx.commit("setClientTabs", res.data.tabs);
        //         return res;
        //     } 
        // },

        // async getClientTabsContacts(ctx) {
        //     console.log("getClientTabsContacts");
        //     let client = localStorage.getItem("client");
        //     if (client) {
        //         client = JSON.parse(client);
        //         var res = await API.GET("client/tabs-contacts", { iid: client.iid });
        //         ctx.commit("setClientTabs", res.data.tabs);
        //         return res;        
        //     }
        // },

        // async getClientTabsCars(ctx) {
        //     console.log("getClient");
        //     let client = localStorage.getItem("client");
        //     if (client) {
        //         client = JSON.parse(client);
        //         var res = await API.GET("client/tabs-cars", { iid: client.iid });
        //         ctx.commit("setClientTabs", res.data.tabs);
        //         return res;
        //     }
        // },

        async getClientTabsOrders(ctx) {
            console.log("getClient");
            let client = localStorage.getItem("client");
            if (client) {
                client = JSON.parse(client);
                var res = await API.GET("client/tabs-orders", { iid: client.iid });
                ctx.commit("setClientTabs", res.data.tabs);
                return res;
            }
        },

        async getClientTabsActivations(ctx) {
            console.log("getClient");
            let client = localStorage.getItem("client");
            if (client) {
                client = JSON.parse(client);
                var res = await API.GET("client/tabs-activations", { iid: client.iid });
                ctx.commit("setClientTabs", res.data.tabs);
                return res;
            }
        },

        async getClientTabsClaims(ctx) {
            console.log("getClient");
            let client = localStorage.getItem("client");
            if (client) {
                client = JSON.parse(client);
                var res = await API.GET("client/tabs-claims", { iid: client.iid });
                ctx.commit("setClientTabs", res.data.tabs);
                return res;
            }
        },

        async getClientTasksP(ctx) {
            console.log("getClient");
            let client = localStorage.getItem("client");
            if (client) {
                client = JSON.parse(client);
                var res = await API.GET("client/tabs-prolon", { iid: client.iid });
                ctx.commit("setClientTabs", res.data.tabs);
                return res;
            }
        },

        async getClientTabsTasks(ctx) {
            console.log("getClient");
            let client = localStorage.getItem("client");
            if (client) {
                client = JSON.parse(client);
                var res = await API.GET("client/tabs-tasks", { iid: client.iid });
                ctx.commit("setClientTabs", res.data.tabs);
                return res;
            }
        },

        async getClientTabsDocs(ctx) {
            console.log("getClient");
            let client = localStorage.getItem("client");
            if (client) {
                client = JSON.parse(client);
                var res = await API.GET("client/tabs-docs", { iid: client.iid });
                ctx.commit("setClientTabs", res.data.tabs);
                return res;
            }
        },

        async getClientTabsInvoices(ctx) {
            console.log("getClientTabsInvoices");
            let client = localStorage.getItem("client");
            if (client) {
                client = JSON.parse(client);
                var res = await API.GET("client/tabs-invoices", { iid: client.iid });
                ctx.commit("setClientTabs", res.data.tabs);
                return res;
            }
        },

        async getClientTabsTouches(ctx) {
            console.log("getClientTouches");
            let client = localStorage.getItem("client");
            if (client) {
                client = JSON.parse(client);
                var res = await API.GET("client/tabs-touches", { iid: client.iid });
                ctx.commit("setClientTabs", res.data.tabs);
                return res;
            }
        },
        async getClientTabsBids(ctx) {
            console.log("getClientBids");
            let client = localStorage.getItem("client");
            if (client) {
                client = JSON.parse(client);
                var res = await API.GET("client/tabs-bids", { iid: client.iid });
                ctx.commit("setClientTabs", res.data.tabs);
                return res;
            }
        },
        async getClientTabsLeads(ctx) {
            console.log("getClientTouches");
            let client = localStorage.getItem("client");
            if (client) {
                client = JSON.parse(client);
                var res = await API.GET("client/tabs-leads", { iid: client.iid });
                ctx.commit("setClientTabs", res.data.tabs);
                return res;
            }
        },

        myClient(ctx) {
            console.log("myClient");
            API.GET("client/my-client").then((res) => {
                console.log("res.data", res.data);
                ctx.commit("setClient", res.data.client);
                ctx.commit("setClientTabs", res.data.tabs);
            });
        },

        clearClientTabs(ctx) {
            ctx.commit("clearClientTabs");
        },

        addClient(ctx) {
            return API.GET("client/add-client").then((res) => {
                ctx.rootState.s.tab = "ClientInfo";
                ctx.commit("settings", ctx.rootState.s);
                ctx.dispatch("setClient", res.data);
                router.push("/client/view");
            });
        },

        addClientByLead(ctx) {
            return API.GET("client/add-client").then((res) => {
                //ctx.rootState.s.tab = "ClientInfo";
                //ctx.commit("settings", ctx.rootState.s);
                ctx.dispatch("setClient", res.data);
                return res.data;
                //router.push("/client/view");
            });
        },

        saveClient(ctx, client) {
            let formData = new FormData();
            Object.keys(client).forEach(function (key) {
                formData.append(key, this[key]);
            }, client);

            API.POST("client/save-client", formData).then((res) => {
                if(res.data) {
                    ctx.commit("setClientUnfilled", res.data.list_unfilled);
                }
                else {
                    ctx.commit("setClientUnfilled", null);
                }
            });
            ctx.dispatch("setClient", client);
            // API.POST("client/save-client", formData);
            // ctx.dispatch("setClient", client);
            // ctx.commit("setClientUnfilled", res.data.tabs.list_unfilled);
        },

        setGroupManager(ctx, data) {
            let formData = new FormData();
            formData.append("clients", data.clients);
            formData.append("manager_id", data.manager_id);
            return API.POST("client/group-manager", formData).then(() => {
                // ctx.state.clients
                ctx.rootState.s.popup = false;
                ctx.dispatch("setSettings", ctx.rootState.s);
                ctx.dispatch("getClients");
            });
        },

        setGroupType(ctx, data) {
            let formData = new FormData();
            formData.append("clients", data.clients);
            formData.append("type", data.type);
            return API.POST("client/group-type", formData).then((res) => {
                // ctx.state.clients
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;

                ctx.rootState.s.popup = false;
                ctx.dispatch("setSettings", ctx.rootState.s);
                ctx.dispatch("getClients");
            });
        },

        // console.log("item", item);

        checkedDouble(ctx, client) {
            console.log("checkedDouble", client.iid);
            let formData = new FormData();
            formData.append("iid", client.iid);
            API.POST("client/checked-double", formData).then(() => {
                console.log("checkedDouble", client.iid);
                ctx.state.clientDoubles.filter((item) => item.client.id != client.id);
                ctx.commit("setClientDoubles", ctx.state.clientDoubles);
            });
        },

        findForMerge(ctx, iid) {
            console.log("findForMerge", iid);
            let formData = new FormData();
            formData.append("from_iid", iid);
            formData.append("to_id", ctx.state.client.id);
            API.POST("client/find-for-merge", formData).then((res) => {
                console.log("findForMerge", res.data);
                ctx.dispatch("setClientMerge", res.data);
            });
        },

        setClientMerge(ctx, data) {
            ctx.commit("setClientMerge", data);
        },

        goClientMerge(ctx, iid) {
            console.log("clientMerge", iid);
            let formData = new FormData();
            formData.append("from_iid", iid);
            formData.append("to_id", ctx.state.client.id);
            API.POST("client/merge", formData).then(() => {
                ctx.dispatch("setClientMerge", null);
                ctx.dispatch("getClient");
            });
        },



        delClient(ctx, client) {
            if (ctx.state.clients.length == 0) {
                let clients = JSON.parse(localStorage.getItem("clients"));
                if (clients) {
                    ctx.commit("setClients", clients);
                }
            }
            if (ctx.state.clients.length > 0) {
                ctx.state.clients = ctx.state.clients.filter((item) => item.id != client.id);
                ctx.commit("setClients", ctx.state.clients);
                localStorage.setItem("clients", JSON.stringify(ctx.state.clients));
            }

            let formData = new FormData();
            formData.append("iid", client.iid);
            API.POST("client/del-client", formData).then(() => {
                // router.push("/client");
                router.go(-1)
            });
        },

        undelClient(ctx, client) {
            if (ctx.state.clients.length == 0) {
                let clients = JSON.parse(localStorage.getItem("clients"));
                if (clients) {
                    ctx.commit("setClients", clients);
                }
            }
            if (ctx.state.clients.length > 0) {
                ctx.state.clients = ctx.state.clients.filter((item) => item.id != client.id);
                ctx.commit("setClients", ctx.state.clients);
                localStorage.setItem("clients", JSON.stringify(ctx.state.clients));
            }

            let formData = new FormData();
            formData.append("iid", client.iid);
            API.POST("client/undel-client", formData).then(() => {
                ctx.state.client.del = 0;
                ctx.dispatch("saveClient", ctx.state.client);
            });
        },

        delClientManager(ctx) {
            ctx.state.client.manager_id = null;
            ctx.dispatch("saveClient", ctx.state.client);
        },
        dieClient(ctx) {
            ctx.state.client.activity = 'dead';
            ctx.dispatch("saveClient", ctx.state.client);
        },
        undieClient(ctx) {
            ctx.state.client.activity = 'passive';
            ctx.dispatch("saveClient", ctx.state.client);
        },

        // КОМПАНИЯ
        showCompany(ctx, company) {
            ctx.dispatch("setCompany", company);
        },

        editCompany(ctx, company) {
            ctx.commit("setCompany", company);
        },

        setCompany(ctx, company) {
            // console.log("setCompany", company);
            ctx.commit("setCompany", company);
            localStorage.setItem("company", JSON.stringify(company));
        },

        addCompany(ctx) {
            ctx.commit("setCompany", null);
            let formData = new FormData();
            formData.append("iid", ctx.state.client.iid);
            API.POST("client/add-company", formData).then((res) => {
                ctx.commit("setClientCompanies", res.data.companies);
                ctx.dispatch("setCompany", res.data.companies[0]);
            });
        },

        saveCompany(ctx, company) {
            console.log("saveCompany");
            let formData = new FormData();
            Object.keys(company).forEach(function (key) {
                formData.append(key, this[key]);
            }, company);
            formData.append("iid", ctx.state.client.iid);

            
            API.POST("client/save-company", formData).then((res) => {
                ctx.commit("setClientUnfilled", res.data.list_unfilled);
            });
        },

        clearCompany(ctx) {
            localStorage.removeItem("company");
            ctx.dispatch("setCompany", null);
        },

        delCompany(ctx, company) {
            ctx.state.companies = ctx.state.companies.filter((item) => item != company);
            ctx.commit("setClientCompanies", ctx.state.companies);
            let formData = new FormData();
            formData.append("iid", ctx.state.client.iid);
            formData.append("id", company.id);
            
            API.POST("client/del-company", formData).then((res) => {
                ctx.commit("setClientUnfilled", res.data.list_unfilled);
            });
        },

        // БАНКОВСКИЕ РЕКВИЗИТЫ
        addBank(ctx) {
            let formData = new FormData();
            formData.append("iid", ctx.state.client.iid);
            formData.append("company_id", ctx.state.company.id);
            API.POST("client/add-bank", formData).then((res) => {
                ctx.commit("setCompany", res.data.company);
            });
        },

        saveBank(ctx, company) {
            console.log("saveCompany");
            let formData = new FormData();
            Object.keys(company).forEach(function (key) {
                formData.append(key, this[key]);
            }, company);
            formData.append("iid", ctx.state.client.iid);
            formData.append("company_id", ctx.state.company.id);

            API.POST("client/save-bank", formData);
        },

        delBank(ctx, bank) {
            ctx.state.company.banks = ctx.state.company.banks.filter((item) => item != bank);
            ctx.commit("setCompany", ctx.state.company);
            let formData = new FormData();
            formData.append("iid", ctx.state.client.iid);
            formData.append("id", bank.id);
            API.POST("client/del-bank", formData);
        },

        // КОНТАКТ
        showContact(ctx, contact) {
            ctx.dispatch("setContact", contact);
            // ctx.dispatch("setTab", "ClientContact");
            ctx.rootState.s.tab = "ClientContact";
            ctx.rootState.s.popup = "edit";
            ctx.dispatch("setSettings", ctx.rootState.s);
            router.push("/client/view");
        },

        editContact(ctx, contact) {
            ctx.commit("setContact", contact);
        },

        setContact(ctx, contact) {
            ctx.commit("setContact", contact);
            localStorage.setItem("contact", JSON.stringify(contact));
        },

        addContact(ctx) {
            ctx.commit("setContact", null);
            let formData = new FormData();
            formData.append("iid", ctx.state.client.iid);
            API.POST("client/add-contact", formData).then((res) => {
                console.log("addContact", res.data.contacts);
                ctx.commit("setClientContacts", res.data.contacts);
                ctx.dispatch("setContact", res.data.contacts[0]);
            });
        },

        saveContact(ctx, contact) {
            console.log("saveContact");
            let formData = new FormData();
            Object.keys(contact).forEach(function (key) {
                formData.append(key, this[key]);
            }, contact);
            formData.append("iid", ctx.state.client.iid);
            API.POST("client/save-contact", formData).then((res) => {
                ctx.commit("setClientUnfilled", res.data.list_unfilled);
            });
        },

        clearContact(ctx) {
            localStorage.removeItem("contact");
            ctx.dispatch("setContact", null);
        },

        delContact(ctx, contact) {
            ctx.state.contacts = ctx.state.contacts.filter((item) => item != contact);
            ctx.commit("setClientContacts", ctx.state.contacts);
            let formData = new FormData();
            formData.append("iid", ctx.state.client.iid);
            formData.append("id", contact.id);
        
            API.POST("client/del-contact", formData).then((res) => {
                ctx.commit("setClientUnfilled", res.data.list_unfilled);
            });
        },

        Enter(ctx, lk) {
            // console.log(lk);
            let formData = new FormData();
            formData.append("client_id", lk.client_id);
            formData.append("uid", lk.uid);
            API.POST("client/enter", formData).then((res) => {
                console.log(res.data);
                if (res.data.token) {
                    let AUTHM = localStorage.getItem("AUTH");
                    localStorage.setItem("AUTH", res.data.token);
                    localStorage.setItem("AUTHM", AUTHM);
                    router.push("/client/info").then(() => {
                        location.reload();
                    });
                }
            });
        },

        backToManager() {
            console.log("backToManager");
            let AUTH = localStorage.getItem("AUTHM");
            localStorage.setItem("AUTH", AUTH);
            localStorage.removeItem("AUTHM");
            router.push("/client/view").then(() => {
                location.reload();
            });
        },

        // ТС
        showCar(ctx, car) {
            ctx.commit("setCar", car);
        },

        editCar(ctx, car) {
            ctx.commit("setCar", car);
        },

        setCar(ctx, car) {
            ctx.commit("setCar", car);
            localStorage.setItem("car", JSON.stringify(car));
        },

        addCar(ctx) {
            console.log("addCar");
            let formData = new FormData();
            formData.append("iid", ctx.state.client.iid);
            console.log("client iid", ctx.state.client.iid);
            API.POST("car/add-car", formData).then((res) => {
                ctx.commit("setClientCars", res.data.cars);
                ctx.commit("setCar", res.data.cars[0]);
                console.log("addCar", res.data.cars[0]);
                localStorage.setItem("car", JSON.stringify(res.data.cars[0]));
            });
        },

        saveCar(ctx, car) {
            let formData = new FormData();
            Object.keys(car).forEach(function (key) {
                formData.append(key, this[key]);
            }, car);
            API.POST("car/save-car", formData);
        },

        clearCar(ctx) {
            localStorage.removeItem("car");
            ctx.dispatch("setCar", null);
        },

        delCar(ctx, car) {
            ctx.state.cars = ctx.state.cars.filter((item) => item != car);
            ctx.commit("setClientCars", ctx.state.cars);
            let formData = new FormData();
            formData.append("iid", ctx.state.client.iid);
            formData.append("id", car.id);
            API.POST("car/del-car", formData);
        },

        getCompaniesByClientId(ctx, client_id) {
            API.GET("client/companies-by-client-id", { id: client_id }).then((res) => {
                if (res.data?.companies) {
                    ctx.commit("setClientCompanies", res.data.companies);
                }
            });
        },

        sendPass(ctx, id) {
            console.log("sendPass");
            let formData = new FormData();
            formData.append("iid", ctx.state.client.iid);
            formData.append("contact_id", id);
            API.POST("client/send-pass", formData).then((res) => {
                if (res.data) {
                    ctx.state.contact.lk = true;
                    ctx.commit("setContact", ctx.state.contact);
                    localStorage.setItem("contact", JSON.stringify(ctx.state.contact));
                }
            });
        },

        async removeClients (ctx, info) {
            let fd = new FormData();
            info.checked.forEach((x) => fd.append("ids[]", x));
            
            let res = await API.POST("client/remove-clients", fd);

            if (res.status == 200) {
                if(res.data.alert.gtfo) {
                    res.data.alert ? ctx.commit("setAlert", res.data.alert.gtfo) : null;
                }
                else {
                   res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                }
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            }             
        },

        async killClients (ctx, info) {
            let fd = new FormData();
            info.checked.forEach((x) => fd.append("ids[]", x));
            
            let res = await API.POST("client/kill-clients", fd);

            if (res.status == 200) {
                if(res.data.alert.gtfo) {
                    res.data.alert ? ctx.commit("setAlert", res.data.alert.gtfo) : null;
                }
                else {
                   res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                }
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            }             
        },

        delPass(ctx, id) {
            console.log("delPass");
            let formData = new FormData();
            formData.append("iid", ctx.state.client.iid);
            formData.append("contact_id", id);
            API.POST("client/del-pass", formData).then(() => {
                ctx.state.contact.lk = false;
                ctx.commit("setContact", ctx.state.contact);
                localStorage.setItem("contact", JSON.stringify(ctx.state.contact));
            });
        },

        showActivById(ctx, id) {
            let formData = new FormData();
            formData.append("id", id);
            API.POST("order/get-activation", formData).then((res) => {
                if (res.data.activation) {
                    console.log(res.data.activation);
                    ctx.commit("setActiv", res.data.activation);
                    ctx.dispatch("showPopup", "activation");
                }
            });
        },

        // АКТИВАЦИЯ
        showActiv(ctx, activ) {
            // console.log("showActiv", activ);
            ctx.commit("setActiv", activ);
        },

        clearActiv(ctx) {
            localStorage.removeItem("activ");
            ctx.commit("setActiv", null);
        },

        ActivStatus(ctx, status) {
            ctx.state.activ.status = status;
            ctx.commit("setActiv", ctx.state.activ);

            let formData = new FormData();
            formData.append("id", ctx.state.activ.id);
            formData.append("status", status);
            API.POST("order/status-activation", formData).then((res) => {
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        doneActivation(ctx) {
            ctx.state.activ.status = 8;
            ctx.commit("setActiv", ctx.state.activ);

            let formData = new FormData();
            formData.append("client_id", ctx.state.client.id);
            formData.append("id", ctx.state.activ.id);
            API.POST("order/done-activation", formData).then((res) => {
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        cancelActivation(ctx, cancel_id) {
            ctx.state.activ.status = 7;
            ctx.commit("setActiv", ctx.state.activ);

            let formData = new FormData();
            formData.append("iid", ctx.state.client.iid);
            formData.append("id", ctx.state.activ.id);
            formData.append("cancel_id", cancel_id);
            API.POST("order/cancel-activation", formData).then((res) => {
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        overdueActivation(ctx) {
            ctx.state.activ.status = 7;
            ctx.commit("setActiv", ctx.state.activ);

            let formData = new FormData();
            // formData.append("iid", ctx.state.client.iid);
            formData.append("id", ctx.state.activ.id);
            API.POST("order/overdue-activation", formData);
        },

        delActive(ctx, active) {
            ctx.state.activs = ctx.state.activs.filter((item) => item != active);
            ctx.commit("setActiv", ctx.state.activs);
            let formData = new FormData();
            formData.append("iid", ctx.state.client.iid);
            formData.append("id", active.id);
            API.POST("client/del-active", formData);
        },

        // ЛК КЛИЕНТА
        getClientCompanies(ctx) {
            return API.GET("client/companies").then((res) => {
                ctx.commit("setClientCompanies", res.data);
            });
        },

        getClientContacts(ctx) {
            return API.GET("client/contacts").then((res) => {
                ctx.commit("setClientContacts", res.data);
            });
        },

        getClientCars(ctx) {
            API.GET("client/cars").then((res) => {
                ctx.commit("setClientCars", res.data);
            });
        },

        // Договоры
        addDogovor(ctx, formData) {
            API.POST("client/add-dogovor", formData).then((res) => {
                ctx.commit("setClientDocs", res.data);
            });
        },

        editDoc(ctx, doc) {
            ctx.commit("setDoc", doc);
        },

        saveDoc(ctx, doc) {
            console.log("saveDoc");
            let formData = new FormData();
            formData.append("iid", ctx.state.client.iid);
            formData.append("id", doc.id);
            formData.append("name", doc.name);
            formData.append("date", moment(doc.date).format("YYYY-MM-DD"));
            API.POST("client/save-doc", formData);
        },

        pushTaskinClient(ctx, task) {
            if (ctx.state.tasks) {
                ctx.state.tasks.push(task);
                ctx.commit("setClientTasks", ctx.state.tasks);
            }
        },

        addClientTask(ctx, id) {
            API.GET("task/add-task", { object: "client", oid: id }).then((res) => {
                ctx.dispatch("setTask", res.data.task);
                ctx.rootState.s.popup = "task";
                ctx.commit("settings", ctx.rootState.s);

                console.log("object client", id);
                ctx.dispatch("pushTaskinClient", res.data.task);
            });
        },

        delClientTask(ctx, task) {
            ctx.state.tasks = ctx.state.tasks.filter((t) => t !== task);
            ctx.commit("setClientTasks", ctx.state.tasks);

            ctx.rootState.s.popup = null;
            ctx.commit("settings", ctx.rootState.s);

            let formData = new FormData();
            formData.append("iid", task.iid);
            API.POST("task/del-task", formData);
        },

        setClientManager(ctx, manager_id) {
            ctx.state.managers.forEach((manager) => {
                if (manager.uid == manager_id) {
                    if (manager.manager_id == null) {
                        manager.manager_id = manager_id;
                    } else {
                        manager.manager_id = null;
                    }
                }
            });
            let formData = new FormData();
            formData.append("iid", ctx.state.client.iid);
            formData.append("manager_id", manager_id);
            API.POST("client/set-manager", formData).then((res) => {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        setVialon(ctx, data = false) {
            let formData = new FormData();
            if (data && data.iid) {
                formData.append("iid", data.iid); // client.iid
            }
            if (data && data.vialon) {
                formData.append("vialon", data.vialon);
            }
            API.POST("client/set-vialon", formData).then(() => {
                if (ctx.rootState.order.order.client) {
                    ctx.rootState.order.order.client.vialon = data.vialon;
                }
                if (ctx.state.client) {
                    ctx.state.client.vialon = data.vialon;
                }
                // res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        setClientTariff(ctx, data) {
            let formData = new FormData();
            formData.append("id", data.id); // client.id
            formData.append("tariff", data.tariff);
            if (data.monitor_id) {
                formData.append("monitor_id", data.monitor_id); // monitor_id
            }
            API.POST("client/change-tariff", formData).then(() => {
                // res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },
        loadActivity(ctx, config){
            return new Promise((done)=>{
                API.GET('client/activity-counts', config).then(data=>{
                    console.log("Грузим активнотсь", data.data);
                    ctx.commit("setActivity", data.data);
                    done(data.data);
                });
            })
        },
        async getActiveClients(ctx, config){
            ctx.dispatch("setLoader", true);
            let formData = new FormData;
            formData.append('active_start', config.active_start || '');
            formData.append('active_end', config.active_end || '');
            let res = await API.POST('client/active-client', formData);
            ctx.commit("setClients", res.data);
            ctx.dispatch("setLoader", false);
            return res.data;
        },
    },

    getters: {
        ClientList(state) {
            return state.filteredClients;
        },
        ClientCounter(state) {
            let count = [];
            let numts = [];
            let realts = [];
            let allnumts = 0;
            let allrealts = 0;
            console.log('перед ифом')
            // if (true || state.countClients?.length > 0) {
                count["zero"] = 0;
                count[1] = 0;
                count[10] = 0;
                count[30] = 0;
                count[50] = 0;
                count[99] = 0;
                numts[10] = 0;
                numts[30] = 0;
                numts[50] = 0;
                numts[99] = 0;
                realts[1] = 0;
                realts[10] = 0;
                realts[30] = 0;
                realts[50] = 0;
                realts[99] = 0;
                console.log('внутри ифа')
                state.countClients.forEach((elem) => {
                    console.log('внутри фор ича')
                    if (elem.ts < 1 || elem.ts == null) {
                        count["zero"]++;
                    }
                    if (elem.ts > 0 && elem.ts < 2) {
                        count[1]++;
                        realts[1] = realts[1] + parseInt(elem.countcars);
                    }
                    if (elem.ts > 1 && elem.ts < 11) {
                        count[10]++;
                        numts[10] = numts[10] + parseInt(elem.ts);
                        realts[10] = realts[10] + parseInt(elem.countcars);
                    }
                    if (elem.ts > 10 && elem.ts < 31) {
                        count[30]++;
                        numts[30] = numts[30] + parseInt(elem.ts);
                        realts[30] = realts[30] + parseInt(elem.countcars);
                    }
                    if (elem.ts > 30 && elem.ts < 51) {
                        count[50]++;
                        numts[50] = numts[50] + parseInt(elem.ts);
                        realts[50] = realts[50] + parseInt(elem.countcars);
                    }
                    if (elem.ts > 50) {
                        count[99]++;
                        numts[99] = numts[99] + parseInt(elem.ts);
                        realts[99] = realts[99] + parseInt(elem.countcars);
                    }
                });

                // allnumts = state.filteredClients.reduce((sum, a) => parseInt(sum) + parseInt(a.ts), 0);
                // allrealts = state.filteredClients.reduce((sum, a) => parseInt(sum) + parseInt(a.countcars), 0);
                allnumts = count[1] + numts[10] + numts[30] + numts[50] + numts[99];
                allrealts = realts[1] + realts[10] + realts[30] + realts[50] + realts[99];
            // }
            return { count: count, numts: numts, realts: realts, allnumts: allnumts, allrealts: allrealts };
        },
        ClientArhive(state) {
            return state.clientArhive;
        },
        ClientDoubles(state) {
            return state.clientDoubles;
        },
        client(state) {
            return state.client;
        },
        ClientCompanies(state) {
            return state.companies;
        },
        company(state) {
            return state.company;
        },
        ClientContacts(state) {
            return state.contacts;
        },
        contact(state) {
            return state.contact;
        },
        ClientCars(state) {
            return state.cars;
        },
        ClientUnfilled(state) {
            return state.listUnfilled;
        },
        ClientCarsRemoved(state) {
            return state.cars_removed;
        },
        car(state) {
            return state.car;
        },
        ClientOrders(state) {
            return state.orders;
        },
        ClientActivs(state) {
            return state.activs;
        },
        activ(state) {
            return state.activ;
        },
        ClientClaims(state) {
            return state.claims;
        },
        ClientDocs(state) {
            return state.docs;
        },
        doc(state) {
            return state.doc;
        },
        ClientTasks(state) {
            return state.tasks;
        },
        ClientProlon(state) {
            return state.prolon;
        },
        ClientManagers(state) {
            return state.managers;
        },
        ClientAccessIds(state) {
            return state.accessIds;
        },
        ClientHistory(state) {
            return state.history;
        },
        clientMerge(state) {
            return state.merge;
        },
        activity(state) {
            console.log("возвращаем активность", state.activity);
            return state.activity;
        },
        clientPaginator(state){
            return state.clientPaginator;
        },
        ClientInvoices(state){///TODO доделать!!!
            return state.invoices;
        },
        ClientTouches(state) {
            return state.touches;
        },
        ClientLeads(state) {
            return state.leads;
        },
        ClientBids(state) {
            return state.bids;
        },
        ClientTabsCounts(state){
            return state.counts;
        },
        clientListPort(state) {
            return state.clientListPort;
        },
        clientsInfoChart(state) {
            return state.clientsInfoChart;
        },
    },
};
