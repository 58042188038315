<template>
  <div class="h-full">
    <template v-if="backM && backM == 1">
      <a
        class="relative z-50 mx-auto mt-4 v-btn bg-sky-500"
        @click="backToManager()"
      >
        <span class="v-btn_text">В кабинет менеджера</span>
      </a>
    </template>
    <a
      class="block w-24 h-24 my-5 mx-auto rounded-full overflow-hidden relative cursor-pointer max-l:hidden"
      @click="$router.push('/profile')"
    >
      <img
        v-if="profile?.picture"
        class="w-full h-full object-cover"
        :src="'https://api.tahocrm.ru/uploads/users/' + profile?.picture"
      />
    </a>
    <div class="text-center relative py-4 max-l:hidden">
      <a
        class="uppercase text-green-500 text-sm cursor-pointer transition-colors duration-300 hover:text-green-400"
        @click="nextRole()"
      >
        <span>{{ roleNames[role] }}</span>
      </a>
    </div>

    <ul class="px-6 max-l:flex max-l:overflow-y-hidden h-max">
      <li
        v-for="item in menu"
        :key="item"
        class="mr-30px list-none group transition-colors duration-1000"
      >
        <a
          class="flex pl-9 py-3 font-latobold max-w-[200px] cursor-pointer relative anim-navbar max-l:before:hidden"
          @click="$router.push(item.path)"
        >
          <svg class="menuIco" v-if="item.path == '/calendar'">
            <use xlink:href="@/assets/i.svg#nav-calendar"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/order'">
            <use xlink:href="@/assets/i.svg#nav-flow"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/order_hosd'">
            <use xlink:href="@/assets/i.svg#nav-flow"></use>
          </svg>

          <svg class="menuIco" v-if="item.path == '/prolon'">
            <use xlink:href="@/assets/i.svg#nav-flow"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/order/arhive'">
            <use xlink:href="@/assets/i.svg#ico-search"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/claim'">
            <use xlink:href="@/assets/i.svg#status-19"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/price'">
            <use xlink:href="@/assets/i.svg#nav-service"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/filial'">
            <use xlink:href="@/assets/i.svg#nav-branches"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/worker'">
            <use xlink:href="@/assets/i.svg#nav-masters"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/salary'">
            <use xlink:href="@/assets/i.svg#pay-1"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/client'">
            <use xlink:href="@/assets/i.svg#nav-clients"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/car'">
            <use xlink:href="@/assets/i.svg#truck"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/lead'">
            <use xlink:href="@/assets/i.svg#truck"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/client/arhive'">
            <use xlink:href="@/assets/i.svg#ico-search"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/client/info'">
            <use xlink:href="@/assets/i.svg#nav-clients"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/client/company'">
            <use xlink:href="@/assets/i.svg#nav-clients"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/client/contact'">
            <use xlink:href="@/assets/i.svg#nav-clients"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/client/car'">
            <use xlink:href="@/assets/i.svg#nav-clients"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/task'">
            <use xlink:href="@/assets/i.svg#nav-orders"></use>
          </svg>

          <svg class="menuIco" v-if="item.path == '/marketing'">
            <use xlink:href="@/assets/i.svg#bill-6"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/check'">
            <use xlink:href="@/assets/i.svg#status-11"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/money'">
            <use xlink:href="@/assets/i.svg#pay-1"></use>
          </svg>
          <svg
            class="menuIco"
            v-if="item.path == '/invoice-moneys' || item.path == '/repolead'"
          >
            <use xlink:href="@/assets/i.svg#pay-1"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/statistic'">
            <use xlink:href="@/assets/i.svg#stat-2"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/profile'">
            <use xlink:href="@/assets/i.svg#ico-avatar"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/ask'">
            <use xlink:href="@/assets/i.svg#ico-question"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/report/activity'">
            <use xlink:href="@/assets/i.svg#stat-2"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/activity/director'">
            <use xlink:href="@/assets/i.svg#stat-2"></use>
          </svg>

          <img
            src="@/assets/bid.svg"
            class="menuIco"
            v-if="item.path == '/bid'"
          />
          <img
            src="@/assets/invoice.svg"
            class="menuIco"
            v-if="item.path == '/invoice'"
          />
          <img
            class="menuIco"
            v-if="item.path == '/touch/touches'"
            src="@/assets/images/touches.svg"
            alt=""
          />
          <img
            class="menuIco"
            v-if="item.path == '/car/report' || item.path == '/report'"
            src="@/assets/images/reports.svg"
            alt=""
          />

          <img
            class="menuIco"
            v-if="item.path == '/plans'"
            src="@/assets/images/plans.svg"
            alt=""
          />

          <img
            class="menuIco"
            v-if="item.path == '/facts'"
            src="@/assets/images/facts.svg"
            alt=""
          />

          <img
            class="menuIco"
            v-if="item.path == '/manager-facts'"
            src="@/assets/images/facts.svg"
            alt=""
          />

          <img
            class="menuIco"
            v-if="item.path == '/briefcase'"
            src="@/assets/briefcase.svg"
            alt=""
          />

          <span
            class="text-white group-hover:text-green-500 z-1 duration-300"
            >{{ item.name }}</span
          >
        </a>
      </li>
      <li
        v-if="
          ['Studio', 'Hosd', 'Logist', 'Outsourcer'].find((r) => r === role)
        "
        class="mr-30px list-none group transition-colors duration-1000"
      >
        <a
          class="flex pl-9 py-3 font-latobold max-w-[200px] cursor-pointer relative anim-navbar max-l:before:hidden"
          :href="newTahobanFrontLink"
        >
          <span class="text-white group-hover:text-green-500 z-1 duration-300"
            >Агрегатор</span
          >
        </a>
      </li>
    </ul>
    <p
      class="block text-gray-400 text-xs relative text-center mt-24 max-l:hidden pb-8"
    >
      © TahoCRM 2025
    </p>
    <!--<button @click="testCron">CRON TEST</button>-->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
//import API from "../services/api";
export default {
  name: "NavBar",

  data() {
    return {
      isDrop: false,
      icons: {
        "nav-calendar":
          '<svg><use xlink:href="@/assets/i.svg#nav-calendar"></use></svg>',
      },
    };
  },

  computed: {
    ...mapGetters([
      "menu",
      "profile",
      "role",
      "roleNames",
      "backM",
      "newTahobanFrontLink",
    ]),
  },

  methods: {
    ...mapActions(["logOut", "nextRole", "backToManager"]),

    DropMenu() {
      this.isDrop = !this.isDrop;
    },
    localLogOut() {
      this.isDrop = false;
      this.logOut();
    },
    //async testCron(){
    //    console.log('CRON ', await API.GET("lead/cr", {}));
    //},
  },

  mounted() {
    // if ( localStorage.getItem("AUTH") ) {
    //     this.pageState = 'logOut'
    // }else{
    //    this.pageState = ''
    // }
  },
};
</script>

<style></style>
