import API from "../../services/api";
import router from "@/router/router";

export default {
    state: {
        filials: [],
        workers: [],
        filteredWorkers: {},
        worker: {},
        workerCats: [],
    },

    mutations: {
        setFilials: (state, filials) => {
            state.filials = filials;
        },

        setWorkers: (state, workers) => {
            state.workers = workers;
            state.filteredWorkers = workers;
        },

        filteredWorkers: (state, workers) => {
            state.filteredWorkers = workers;
        },

        setWorker: (state, worker) => {
            state.worker = worker;
        },

        setWorkerCats: (state, workerCats) => {
            state.workerCats = workerCats;
        },

        setCategories: (state, categories) => {
            state.workerCategories = categories;
        },
    },

    actions: {
        addNewWorker(ctx, data) {
            console.log(data);

            var formData = new FormData();
            formData.append("email", data.email);
            formData.append("username", data.username);
            formData.append("name", data.name);
            formData.append("secondname", data.secondname);
            formData.append("middlename", data.middlename);
            formData.append("phone", data.phone);
            formData.append("studio_id", 10);
            formData.append("role", data.role);
            API.POST("worker/create", formData).then((res) => {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },
        getFilials(ctx) {
            API.GET("worker/filials").then((res) => {
                localStorage.setItem("filials", JSON.stringify(res.data.filials));
                ctx.commit("setFilials", res.data.filials);
            });
        },

        getWorkers(ctx) {
            API.GET("worker/workers").then((res) => {
                localStorage.setItem("workers", JSON.stringify(res.data.workers));
                ctx.commit("setWorkers", res.data.workers);

                // ctx.dispatch("loadSettings").then(() => {
                //     ctx.dispatch("findWorker", ctx.rootState.s);
                // });
            });
        },

        findWorker(ctx, s) {
            let list = ctx.state.workers;

            if (s.findme != "" && s.findme != undefined) {
                list = list.filter((item) => item.fullname.toLowerCase().indexOf(s.findme) !== -1);
            }         

            if (s.category != "" && s.category != undefined) {
                list = list.filter((item) => String(item.category_id) == String(s.category));
            }

            if (list.length != ctx.state.workers.length) {
                s.clear = true;
            }

            ctx.state.filteredWorkers = list;
            ctx.dispatch("setSettings", s);
        },

        getWorker(ctx) {
            let worker = localStorage.getItem("worker");
            if (worker) {
                worker = JSON.parse(worker);
                ctx.commit("setWorker", worker);
                API.GET("worker/one-worker", { uid: ctx.state.worker.uid }).then((res) => {
                    localStorage.setItem("worker", JSON.stringify(res.data.worker));
                    ctx.commit("setWorker", res.data.worker);
                    ctx.commit("setWorkerCats", res.data.cats);
                });
            } else {
                router.push("/worker");
            }
        },

        changeWorker(ctx, worker) {
            console.log(worker);
            let formData = new FormData();
            formData.append("uid", worker.uid);
            formData.append("email", worker.email);
            formData.append("phone", worker.phone);
            API.POST("worker/change", formData).then((res) => {                
                localStorage.setItem("worker", JSON.stringify(res.data.worker));
                ctx.commit("setWorker", res.data.worker);
                ctx.commit("setWorkerCats", res.data.cats);
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        // changeWorker(ctx, worker){
        //     console.log('worker', worker);
        //     return API.POST("worker/change", { uid: worker.uid, worker: worker.email, phone: worker.phone, }).then((res) => {
        //         localStorage.setItem("worker", JSON.stringify(res.data.worker));
        //         ctx.commit("setWorker", res.data.worker);
        //         ctx.commit("setWorkerCats", res.data.cats);
        //     });
        // },

        getWorkerById(ctx, id){
            console.log("id", id);
            return API.GET("worker/one-worker", { uid: id }).then((res) => {
                localStorage.setItem("worker", JSON.stringify(res.data.worker));
                ctx.commit("setWorker", res.data.worker);
                ctx.commit("setWorkerCats", res.data.cats);
            });
        },

        addWorker(ctx) {
            API.GET("worker/add-worker").then((res) => {
                ctx.state.workers.push(res.data.worker);
                ctx.dispatch("setWorkers", ctx.state.workers);
                ctx.dispatch("showWorker", res.data.worker);
            });
        },

        setWorker(ctx, worker) {
            localStorage.setItem("worker", JSON.stringify(worker));
            ctx.commit("setWorker", worker);
        },

        showWorker(ctx, worker) {
            ctx.dispatch("setWorker", worker);
            router.push("/worker/view");
        },

        delWorker(ctx, worker){
            // console.log("DEL", ctx, worker);
            let formData = new FormData();
            formData.append("uid", worker.uid);
            formData.append("active", worker.active);

            API.POST("worker/sack-worker", formData).then((res) => {
                if (res.data.worker) {
                    ctx.dispatch("setWorker", res.data.worker); 
                    res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;

                    if (ctx.state.worker.uid == ctx.rootState.profile.profile.uid) {
                        ctx.dispatch("getProfile");
                    }
                }
            });
        },

        onOffRole(ctx, role) {
            console.log("onOffRole", role);
            let formData = new FormData();
            formData.append("uid", ctx.state.worker.uid);
            formData.append("role", role);
            API.POST("worker/on-off-role", formData).then((res) => {
                if (res.data.worker) {
                    ctx.dispatch("setWorker", res.data.worker);
                    if (ctx.state.worker.uid == ctx.rootState.profile.profile.uid) {
                        ctx.dispatch("getProfile");
                    }
                }
            });
        },

        ChangeCat(ctx, cat) {
            let formData = new FormData();
            formData.append("uid", ctx.state.worker.uid);
            formData.append("cat", cat);
            API.POST("worker/change-cat", formData).then((res) => {
                ctx.commit("setWorkerCats", res.data.cats);
            });
        },
    },

    getters: {
        FilialList(state) {
            return state.filials;
        },
        WorkerList(state) {
            return state.filteredWorkers;
        },
        worker(state) {
            return state.worker;
        },
        workerCats(state) {
            return state.workerCats;
        },
    },
};
