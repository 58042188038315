<template>
  <div class="flex mt-5">
    <input
      type="month"
      class="month-report-top rounded-[8px] py-4 mr-5"
      v-model="dateFrom"
    />
    <button @click="getPortfelInfo()" class="button-form-report rounded-[8px]">
      Сформировать
    </button>
  </div>
  <div class="wrap-info" v-if="showBriefCase">
    <div class="flex mt-5">
      <div
        class="bg-white flex flex-col w-1/2 pt-4 pb-6 mr-2 mt-2 relative rounded-[8px] justify-center items-center max-h-[500px]"
      >
        <p class="font-latobold text-base uppercase mb-4">
          активные клиенты по категориям
        </p>
        <div class="w-full">
          <div class="absolute innerTextPie">
            <p class="innerValue">
              <span>
                {{
                  reportBriefcase.active_ts_1.count +
                  reportBriefcase.active_ts_2.count +
                  reportBriefcase.active_ts_3.count +
                  reportBriefcase.active_ts_4.count +
                  reportBriefcase.active_ts_5.count
                }}
              </span>
              шт
            </p>
          </div>
          <Doughnut
            :data="dataActive()"
            ref="doughnutChart"
            :options="options"
          />
        </div>
        <div></div>
      </div>

      <div class="flex w-1/2">
        <div
          class="bg-white flex flex-col w-1/2 pt-4 pb-6 mr-2 mt-2 relative rounded-[8px] justify-center items-center"
        >
          <p class="font-latobold text-base uppercase mb-4">
            доход по категориям клиентов
          </p>

          <div class="wrap-semi-th mb-4 w-full px-6">
            <p class="font-latobold text-base uppercase min-w-[120px]">1 ТС</p>
            <div class="progress-pay-wrap max-w-[80%]">
              <div
                class="progress-pay"
                role="graysausage"
                :style="'--value:' + reportBriefcase.income_ts_1 / maxIncome"
              ></div>
            </div>
            <p class="font-latobold text-base uppercase min-w-[90px] ml-4">
              {{ addSpace(reportBriefcase.income_ts_1) }}
            </p>
          </div>

          <div class="wrap-semi-th mb-4 w-full px-6">
            <p class="font-latobold text-base uppercase min-w-[120px]">
              2-10 ТС
            </p>
            <div class="progress-pay-wrap max-w-[80%]">
              <div
                class="progress-pay"
                role="graysausage"
                :style="'--value:' + reportBriefcase.income_ts_2 / maxIncome"
              ></div>
            </div>
            <p class="font-latobold text-base uppercase min-w-[90px] ml-4">
              {{ addSpace(reportBriefcase.income_ts_2) }}
            </p>
          </div>

          <div class="wrap-semi-th mb-4 w-full px-6">
            <p class="font-latobold text-base uppercase min-w-[120px]">
              11-30 ТС
            </p>
            <div class="progress-pay-wrap max-w-[80%]">
              <div
                class="progress-pay"
                role="graysausage"
                :style="'--value:' + reportBriefcase.income_ts_3 / maxIncome"
              ></div>
            </div>
            <p class="font-latobold text-base uppercase min-w-[90px] ml-4">
              {{ addSpace(reportBriefcase.income_ts_3) }}
            </p>
          </div>

          <div class="wrap-semi-th mb-4 w-full px-6">
            <p class="font-latobold text-base uppercase min-w-[120px]">
              31-50 ТС
            </p>
            <div class="progress-pay-wrap max-w-[80%]">
              <div
                class="progress-pay"
                role="graysausage"
                :style="'--value:' + reportBriefcase.income_ts_4 / maxIncome"
              ></div>
            </div>
            <p class="font-latobold text-base uppercase min-w-[90px] ml-4">
              {{ addSpace(reportBriefcase.income_ts_4) }}
            </p>
          </div>

          <div class="wrap-semi-th w-full px-6">
            <p class="font-latobold text-base uppercase min-w-[120px]">
              > 50 ТС
            </p>
            <div class="progress-pay-wrap max-w-[80%]">
              <div
                class="progress-pay"
                role="graysausage"
                :style="'--value:' + reportBriefcase.income_ts_5 / maxIncome"
              ></div>
            </div>
            <p class="font-latobold text-base uppercase min-w-[90px] ml-4">
              {{ addSpace(reportBriefcase.income_ts_5) }}
            </p>
          </div>
        </div>

        <div
          class="bg-white flex flex-col w-1/2 pt-4 pb-6 mt-2 relative rounded-[8px] justify-center items-center"
        >
          <p class="font-latobold text-base uppercase mb-4">
            маржа по категориям клиентов
          </p>

          <div
            @click="showReportClients()"
            class="wrap-semi-th mb-4 w-full px-6 cursor-pointer"
          >
            <p class="font-latobold text-base uppercase min-w-[120px]">1 ТС</p>
            <div class="progress-pay-wrap max-w-[80%]">
              <div
                class="progress-pay"
                role="graysausage"
                :style="'--value:' + reportBriefcase.margin_ts_1 / maxMargin"
              ></div>
            </div>
            <p class="font-latobold text-base uppercase min-w-[90px] ml-4">
              {{ addSpace(reportBriefcase.margin_ts_1) }}
            </p>
          </div>

          <div class="wrap-semi-th mb-4 w-full px-6">
            <p class="font-latobold text-base uppercase min-w-[120px]">
              2-10 ТС
            </p>
            <div class="progress-pay-wrap max-w-[80%]">
              <div
                class="progress-pay"
                role="graysausage"
                :style="'--value:' + reportBriefcase.margin_ts_2 / maxMargin"
              ></div>
            </div>
            <p class="font-latobold text-base uppercase min-w-[90px] ml-4">
              {{ addSpace(reportBriefcase.margin_ts_2) }}
            </p>
          </div>

          <div class="wrap-semi-th mb-4 w-full px-6">
            <p class="font-latobold text-base uppercase min-w-[120px]">
              11-30 ТС
            </p>
            <div class="progress-pay-wrap max-w-[80%]">
              <div
                class="progress-pay"
                role="graysausage"
                :style="'--value:' + reportBriefcase.margin_ts_3 / maxMargin"
              ></div>
            </div>
            <p class="font-latobold text-base uppercase min-w-[90px] ml-4">
              {{ addSpace(reportBriefcase.margin_ts_3) }}
            </p>
          </div>

          <div class="wrap-semi-th mb-4 w-full px-6">
            <p class="font-latobold text-base uppercase min-w-[120px]">
              31-50 ТС
            </p>
            <div class="progress-pay-wrap max-w-[80%]">
              <div
                class="progress-pay"
                role="graysausage"
                :style="'--value:' + reportBriefcase.margin_ts_4 / maxMargin"
              ></div>
            </div>
            <p class="font-latobold text-base uppercase min-w-[90px] ml-4">
              {{ addSpace(reportBriefcase.margin_ts_4) }}
            </p>
          </div>

          <div class="wrap-semi-th w-full px-6">
            <p class="font-latobold text-base uppercase min-w-[120px]">
              > 50 ТС
            </p>
            <div class="progress-pay-wrap max-w-[80%]">
              <div
                class="progress-pay"
                role="graysausage"
                :style="'--value:' + reportBriefcase.margin_ts_5 / maxMargin"
              ></div>
            </div>
            <p class="font-latobold text-base uppercase min-w-[90px] ml-4">
              {{ addSpace(reportBriefcase.margin_ts_5) }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="flex">
      <div
        class="bg-white flex flex-col w-1/2 pt-4 pb-6 mr-2 mt-2 relative rounded-[8px] justify-center items-center max-h-[500px]"
      >
        <p class="font-latobold text-base uppercase mb-4">ДОЛЯ АКБ</p>
        <div class="w-full">
          <div class="absolute innerTextPie !bottom-[33%]">
            <p class="innerValue">
              <span>
                {{ reportBriefcase.all_clients.count }}
              </span>
              шт.
            </p>
            <p class="innerValue1">
              <span>
                {{
                  Math.round(
                    (reportBriefcase.active_all.count /
                      reportBriefcase.all_clients.count) *
                      100
                  )
                }}
              </span>
              %
            </p>
          </div>
          <Doughnut
            ref="doughnutChart4"
            :data="dataActive2()"
            :options="options4"
          />
        </div>
      </div>

      <div class="flex w-1/2">
        <div
          class="bg-white flex flex-col w-1/2 pt-4 pb-6 mt-2 mr-2 relative rounded-[8px] justify-center items-center max-h-[500px]"
        >
          <p class="font-latobold text-base uppercase mb-4">
            новые клиенты по категориям
          </p>
          <div class="w-full">
            <div class="absolute innerTextPie !bottom-[31%]">
              <p class="innerValue">
                <span>
                  {{
                    reportBriefcase.new_ts_1.count +
                    reportBriefcase.new_ts_2.count +
                    reportBriefcase.new_ts_3.count +
                    reportBriefcase.new_ts_4.count +
                    reportBriefcase.new_ts_5.count
                  }}
                </span>
                шт
              </p>
            </div>
            <Doughnut
              ref="doughnutChart1"
              :data="dataNew()"
              :options="options1"
            />
          </div>
        </div>
        <div
          class="bg-white flex flex-col w-1/2 pt-4 pb-6 mt-2 relative rounded-[8px] justify-center items-center max-h-[500px]"
        >
          <p class="font-latobold text-base uppercase mb-4">
            вернувшиеся клиенты по категориям
          </p>
          <div class="w-full">
            <div class="absolute innerTextPie !bottom-[31%]">
              <p class="innerValue">
                <span>
                  {{
                    reportBriefcase.back_ts_1.count +
                    reportBriefcase.back_ts_2.count +
                    reportBriefcase.back_ts_3.count +
                    reportBriefcase.back_ts_4.count +
                    reportBriefcase.back_ts_5.count
                  }}
                </span>
                шт
              </p>
            </div>
            <Doughnut
              :data="dataBack()"
              ref="doughnutChart2"
              :options="options2"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="flex">
      <div
        class="bg-white flex flex-col w-1/2 pt-4 pb-6 mr-2 mt-2 relative rounded-[8px] justify-center items-center max-h-[500px]"
      >
        <p class="font-latobold text-base uppercase mb-4">
          доля потерянных клиентов
        </p>
        <div class="w-full">
          <div class="absolute innerTextPie">
            <p class="innerValue">
              <span>
                {{
                  reportBriefcase.lost_lost.count +
                  reportBriefcase.lost_active.count
                }}
              </span>
              шт
            </p>
          </div>
          <Doughnut :data="dataLost()" :options="options3" />
        </div>
      </div>
      <div class="flex w-1/2">
        <div
          class="bg-white flex flex-col w-1/2 pt-4 pb-6 mr-2 mt-2 relative rounded-[8px] justify-center items-center"
        >
          <p class="font-latobold text-base uppercase mb-4">
            потерянные клиенты по категориям
          </p>

          <div
            @click="
              clientList = reportBriefcase.lost_cat_ts_1.ids;
              showReportC = true;
            "
            class="wrap-semi-th mb-4 w-full px-6 cursor-pointer"
          >
            <p class="font-latobold text-base uppercase min-w-[120px]">1 ТС</p>
            <div class="progress-pay-wrap max-w-[80%]">
              <div
                class="progress-pay1"
                role="graysausage"
                :style="
                  '--value:' + reportBriefcase.lost_cat_ts_1.count / maxLostCat
                "
              ></div>
            </div>
            <p class="font-latobold text-base uppercase min-w-[90px] ml-4">
              {{ addSpace(reportBriefcase.lost_cat_ts_1.count) }}
            </p>
          </div>

          <div
            @click="
              clientList = reportBriefcase.lost_cat_ts_2.ids;
              showReportC = true;
            "
            class="wrap-semi-th mb-4 w-full px-6 cursor-pointer"
          >
            <p class="font-latobold text-base uppercase min-w-[120px]">
              2-10 ТС
            </p>
            <div class="progress-pay-wrap max-w-[80%]">
              <div
                class="progress-pay1"
                role="graysausage"
                :style="
                  '--value:' + reportBriefcase.lost_cat_ts_2.count / maxLostCat
                "
              ></div>
            </div>
            <p class="font-latobold text-base uppercase min-w-[90px] ml-4">
              {{ addSpace(reportBriefcase.lost_cat_ts_2.count) }}
            </p>
          </div>

          <div
            @click="
              clientList = reportBriefcase.lost_cat_ts_3.ids;
              showReportC = true;
            "
            class="wrap-semi-th mb-4 w-full px-6 cursor-pointer"
          >
            <p class="font-latobold text-base uppercase min-w-[120px]">
              11-30 ТС
            </p>
            <div class="progress-pay-wrap max-w-[80%]">
              <div
                class="progress-pay1"
                role="graysausage"
                :style="
                  '--value:' + reportBriefcase.lost_cat_ts_3.count / maxLostCat
                "
              ></div>
            </div>
            <p class="font-latobold text-base uppercase min-w-[90px] ml-4">
              {{ addSpace(reportBriefcase.lost_cat_ts_3.count) }}
            </p>
          </div>

          <div
            @click="
              clientList = reportBriefcase.lost_cat_ts_4.ids;
              showReportC = true;
            "
            class="wrap-semi-th mb-4 w-full px-6 cursor-pointer"
          >
            <p class="font-latobold text-base uppercase min-w-[120px]">
              31-50 ТС
            </p>
            <div class="progress-pay-wrap max-w-[80%]">
              <div
                class="progress-pay1"
                role="graysausage"
                :style="
                  '--value:' + reportBriefcase.lost_cat_ts_4.count / maxLostCat
                "
              ></div>
            </div>
            <p class="font-latobold text-base uppercase min-w-[90px] ml-4">
              {{ addSpace(reportBriefcase.lost_cat_ts_4.count) }}
            </p>
          </div>

          <div
            @click="
              clientList = reportBriefcase.lost_cat_ts_5.ids;
              showReportC = true;
            "
            class="wrap-semi-th mb-4 w-full px-6 cursor-pointer"
          >
            <p class="font-latobold text-base uppercase min-w-[120px]">
              > 50 ТС
            </p>
            <div class="progress-pay-wrap max-w-[80%]">
              <div
                class="progress-pay1"
                role="graysausage"
                :style="
                  '--value:' + reportBriefcase.lost_cat_ts_5.count / maxLostCat
                "
              ></div>
            </div>
            <p class="font-latobold text-base uppercase min-w-[90px] ml-4">
              {{ addSpace(reportBriefcase.lost_cat_ts_5.count) }}
            </p>
          </div>
        </div>
        <div
          class="bg-white flex flex-col w-1/2 pt-4 pb-6 mt-2 relative rounded-[8px] justify-center items-center"
        >
          <p class="font-latobold text-base uppercase mb-4">
            потерянные клиенты по менеджерам
          </p>

          <div class="wrap-lost-managers">
            <div
              v-for="i in reportBriefcase.lost_managers"
              :key="i"
              @click="
                clientList = i.ids;
                showReportC = true;
              "
              class="wrap-semi-th mb-4 w-full px-6 cursor-pointer"
            >
              <p class="font-latobold text-base min-w-[120px]">
                {{ i.info }}
              </p>
              <div class="progress-pay-wrap max-w-[80%]">
                <div
                  class="progress-pay1"
                  role="graysausage"
                  :style="'--value:' + i.count / maxLostManagers"
                ></div>
              </div>
              <p class="font-latobold text-base uppercase min-w-[90px] ml-4">
                {{ addSpace(i.count) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="bg-white flex flex-col w-full pt-4 pb-6 mt-2 mb-2 relative rounded-[8px] justify-center items-center max-h-[550px]"
    >
      <p class="font-latobold text-base uppercase mb-4">
        динамика процентной доли АКБ
      </p>
      <Line
        class="max-h-[350px] min-h-[350px]"
        :data="dataAKB()"
        :options="optionsLine"
      />
    </div>

    <div
      class="orders-tabs px-5 mt-12 mb-2 w-fit ml-auto bg-white rounded-lg flex flex-wrap justify-around items-center"
    >
      <a
        :class="{ checkedTab: tab == 'count' }"
        class="link py-3 px-4 w-[150px] uppercase"
        @click="tab = 'count'"
        >количество</a
      >
      <a
        :class="{ checkedTab: tab == 'marge' }"
        class="link py-3 px-4 w-[150px] uppercase"
        @click="tab = 'marge'"
        >маржа</a
      >
      <a
        :class="{ checkedTab: tab == 'income' }"
        class="link py-3 px-4 w-[150px] uppercase"
        @click="tab = 'income'"
        >Доход</a
      >
    </div>

    <div v-if="tab == 'count'">
      <div
        class="bg-white flex flex-col w-full pt-4 pb-6 mt-2 mb-4 relative rounded-[8px] justify-center items-center max-h-[550px]"
      >
        <p class="font-latobold text-base uppercase mb-4">
          динамика активных клиентов по категориям
        </p>
        <Line
          class="max-h-[350px] min-h-[350px]"
          :data="DataCountActive()"
          :options="optionsLine"
        />
      </div>

      <div
        class="bg-white flex flex-col w-full pt-4 pb-6 mt-2 mb-4 relative rounded-[8px] justify-center items-center max-h-[550px]"
      >
        <p class="font-latobold text-base uppercase mb-4">
          динамика потерянных клиентов по категориям
        </p>
        <Line
          class="max-h-[350px] min-h-[350px]"
          :data="DataCountLost()"
          :options="optionsLine"
        />
      </div>
    </div>
    <div v-if="tab == 'marge'">
      <div
        class="bg-white flex flex-col w-full pt-4 pb-6 mt-2 mb-4 relative rounded-[8px] justify-center items-center max-h-[550px]"
      >
        <p class="font-latobold text-base uppercase mb-4">
          динамика активных клиентов по категориям
        </p>
        <Line
          class="max-h-[350px] min-h-[350px]"
          :data="DataMargeActive()"
          :options="optionsLine"
        />
      </div>

      <div
        class="bg-white flex flex-col w-full pt-4 pb-6 mt-2 mb-4 relative rounded-[8px] justify-center items-center max-h-[550px]"
      >
        <p class="font-latobold text-base uppercase mb-4">
          динамика потерянных клиентов по категориям
        </p>
        <Line
          class="max-h-[350px] min-h-[350px]"
          :data="DataMargeLost()"
          :options="optionsLine"
        />
      </div>
    </div>

    <div v-if="tab == 'income'">
      <div
        class="bg-white flex flex-col w-full pt-4 pb-6 mt-2 mb-4 relative rounded-[8px] justify-center items-center max-h-[550px]"
      >
        <p class="font-latobold text-base uppercase mb-4">
          динамика активных клиентов по категориям
        </p>
        <Line
          class="max-h-[350px] min-h-[350px]"
          :data="DataIncomeActive()"
          :options="optionsLine"
        />
      </div>

      <div
        class="bg-white flex flex-col w-full pt-4 pb-6 mt-2 mb-4 relative rounded-[8px] justify-center items-center max-h-[550px]"
      >
        <p class="font-latobold text-base uppercase mb-4">
          динамика потерянных клиентов по категориям
        </p>
        <Line
          class="max-h-[350px] min-h-[350px]"
          :data="DataIncomeLost()"
          :options="optionsLine"
        />
      </div>
    </div>
  </div>

  <div
    v-if="showReportC"
    id="popnew4"
    class="w-10/12 bg-slate-200 fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-y-auto overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
  >
    <ClientsListModal :clientListProp="clientList" />
  </div>
  <div
    v-if="showReportC"
    @click="showReportC = false"
    id="overlay"
    class="fixed bg-cyan-900 z-10 w-screen h-screen top-0 left-0 opacity-80"
  ></div>
</template>

<script>
import ClientsListModal from "@/components/report/ClientsListModal";
import { mapActions, mapGetters } from "vuex";
import { Line } from "vue-chartjs";
import { Doughnut } from "vue-chartjs";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  ChartJsPluginDataLabels,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default {
  name: "PortfelClient",

  components: {
    Doughnut,
    ClientsListModal,
    Line,
  },

  data() {
    return {
      dateFrom: null,
      dateTo: null,
      tab: "count",
      showBriefCase: false,
      showReportC: false,
      maxIncome: 0,
      maxMargin: 0,
      maxLostCat: 0,
      maxLostManagers: 0,

      clientList: [],

      optionsLine: {
        responsive: true,
        maintainAspectRatio: false,
        tension: 0.4,

        plugins: {
          datalabels: {
            align: "top",
            color: function (ctx) {
              // use the same color as the border
              return ctx.dataset.borderColor;
            },
            font: {
              weight: "bold",
              size: 12,
            },
          },
        },
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutout: 80,

        plugins: {
          datalabels: {
            color: function (ctx) {
              // use the same color as the border
              return ctx.dataset.borderColor;
            },
            font: {
              weight: "bold",
              size: 12,
            },
          },
        },

        onClick: (e) => {
          const chart = this.$refs.doughnutChart.chart;
          const res = chart.getElementsAtEventForMode(
            e,
            "nearest",
            { intersect: true },
            true
          );
          // If didn't click on a bar, `res` will be an empty array
          if (res.length === 0) {
            return;
          }
          // Alerts "You clicked on A" if you click the "A" chart

          if (chart.data.labels[res[0].index] == "1 ТС") {
            this.clientList = this.reportBriefcase.active_ts_1.ids;
            this.showReportC = true;
          }
          if (chart.data.labels[res[0].index] == "2-10 ТС") {
            this.clientList = this.reportBriefcase.active_ts_2.ids;
            this.showReportC = true;
          }
          if (chart.data.labels[res[0].index] == "11-30 ТС") {
            this.clientList = this.reportBriefcase.active_ts_3.ids;
            this.showReportC = true;
          }
          if (chart.data.labels[res[0].index] == "31-50 ТС") {
            this.clientList = this.reportBriefcase.active_ts_4.ids;
            this.showReportC = true;
          }
          if (chart.data.labels[res[0].index] == "> 50 ТС") {
            this.clientList = this.reportBriefcase.active_ts_5.ids;
            this.showReportC = true;
          }
        },
      },

      options1: {
        responsive: true,
        maintainAspectRatio: false,
        cutout: 80,

        onClick: (e) => {
          const chart = this.$refs.doughnutChart1.chart;
          const res = chart.getElementsAtEventForMode(
            e,
            "nearest",
            { intersect: true },
            true
          );
          // If didn't click on a bar, `res` will be an empty array
          if (res.length === 0) {
            return;
          }
          // Alerts "You clicked on A" if you click the "A" chart
          if (chart.data.labels[res[0].index] == "1 ТС") {
            this.clientList = this.reportBriefcase.new_ts_1.ids;
            this.showReportC = true;
          }
          if (chart.data.labels[res[0].index] == "2-10 ТС") {
            this.clientList = this.reportBriefcase.new_ts_2.ids;
            this.showReportC = true;
          }
          if (chart.data.labels[res[0].index] == "11-30 ТС") {
            this.clientList = this.reportBriefcase.new_ts_3.ids;
            this.showReportC = true;
          }
          if (chart.data.labels[res[0].index] == "31-50 ТС") {
            this.clientList = this.reportBriefcase.new_ts_4.ids;
            this.showReportC = true;
          }
          if (chart.data.labels[res[0].index] == "> 50 ТС") {
            this.clientList = this.reportBriefcase.new_ts_5.ids;
            this.showReportC = true;
          }
        },
      },

      options2: {
        responsive: true,
        maintainAspectRatio: false,
        cutout: 80,

        onClick: (e) => {
          const chart = this.$refs.doughnutChart2.chart;
          const res = chart.getElementsAtEventForMode(
            e,
            "nearest",
            { intersect: true },
            true
          );
          // If didn't click on a bar, `res` will be an empty array
          if (res.length === 0) {
            return;
          }
          // Alerts "You clicked on A" if you click the "A" chart
          if (chart.data.labels[res[0].index] == "1 ТС") {
            this.clientList = this.reportBriefcase.back_ts_1.ids;
            this.showReportC = true;
          }
          if (chart.data.labels[res[0].index] == "2-10 ТС") {
            this.clientList = this.reportBriefcase.back_ts_2.ids;
            this.showReportC = true;
          }
          if (chart.data.labels[res[0].index] == "11-30 ТС") {
            this.clientList = this.reportBriefcase.back_ts_3.ids;
            this.showReportC = true;
          }
          if (chart.data.labels[res[0].index] == "31-50 ТС") {
            this.clientList = this.reportBriefcase.back_ts_4.ids;
            this.showReportC = true;
          }
          if (chart.data.labels[res[0].index] == "> 50 ТС") {
            this.clientList = this.reportBriefcase.back_ts_5.ids;
            this.showReportC = true;
          }
        },
      },

      options3: {
        responsive: true,
        maintainAspectRatio: false,
        cutout: 80,

        onClick: (e) => {
          const chart = this.$refs.doughnutChart3.chart;
          const res = chart.getElementsAtEventForMode(
            e,
            "nearest",
            { intersect: true },
            true
          );
          // If didn't click on a bar, `res` will be an empty array
          if (res.length === 0) {
            return;
          }
          // Alerts "You clicked on A" if you click the "A" chart
          if (chart.data.labels[res[0].index] == "Активные") {
            this.clientList = this.reportBriefcase.lost_lost.ids;
            this.showReportC = true;
          }
          if (chart.data.labels[res[0].index] == "Потерянные") {
            this.clientList = this.reportBriefcase.lost_active.ids;
            this.showReportC = true;
          }
        },
      },

      options4: {
        responsive: true,
        maintainAspectRatio: false,
        cutout: 80,

        onClick: (e) => {
          const chart = this.$refs.doughnutChart4.chart;
          const res = chart.getElementsAtEventForMode(
            e,
            "nearest",
            { intersect: true },
            true
          );
          // If didn't click on a bar, `res` will be an empty array
          if (res.length === 0) {
            return;
          }
          // Alerts "You clicked on A" if you click the "A" chart
          if (chart.data.labels[res[0].index] == "Активные") {
            this.clientList = this.reportBriefcase.lost_lost.ids;
            this.showReportC = true;
          }
          if (chart.data.labels[res[0].index] == "Потерянные") {
            this.clientList = this.reportBriefcase.lost_active.ids;
            this.showReportC = true;
          }
        },
      },
    };
  },

  computed: {
    ...mapGetters(["s", "profile", "reportBriefcase", "clientsInfoChart"]),
  },
  methods: {
    ...mapActions(["getPortfel", "getClientsInfoChart"]),

    showReportClients() {},
    async getPortfelInfo() {
      this.showBriefCase = false;
      await this.getPortfel({
        date: this.dateFrom,
      });
      this.showBriefCase = true;

      this.maxIncome = Math.max(
        this.reportBriefcase.income_ts_1,
        this.reportBriefcase.income_ts_2,
        this.reportBriefcase.income_ts_3,
        this.reportBriefcase.income_ts_4,
        this.reportBriefcase.income_ts_5
      );
      this.maxMargin = Math.max(
        this.reportBriefcase.margin_ts_1,
        this.reportBriefcase.margin_ts_2,
        this.reportBriefcase.margin_ts_3,
        this.reportBriefcase.margin_ts_4,
        this.reportBriefcase.margin_ts_5
      );
      this.maxLostCat = Math.max(
        this.reportBriefcase.lost_cat_ts_1.count,
        this.reportBriefcase.lost_cat_ts_2.count,
        this.reportBriefcase.lost_cat_ts_3.count,
        this.reportBriefcase.lost_cat_ts_4.count,
        this.reportBriefcase.lost_cat_ts_5.count
      );
      await this.getClientsInfoChart();
      for (let i = 0; this.reportBriefcase.lost_managers.length; i++) {
        if (
          this.maxLostManagers < this.reportBriefcase.lost_managers[i].count
        ) {
          this.maxLostManagers = this.reportBriefcase.lost_managers[i].count;
        }
      }
    },
    addSpace(strNumber) {
      return String(strNumber).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
    },

    dataAKB() {
      let labelName = ["Начало отчета"];
      let data1akb = [0];

      for (let i = 0; i < this.clientsInfoChart[0].chart.length; i++) {
        labelName.push(this.clientsInfoChart[3].chart[i].date);
        data1akb.push(this.clientsInfoChart[3].chart[i].akb);
      }

      for (let i = 0; i < labelName.length; i++) {
        if (labelName[i].substring(5, 7) == "01") {
          labelName[i] = "Январь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "02") {
          labelName[i] = "Февраль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "03") {
          labelName[i] = "Март " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "04") {
          labelName[i] = "Апрель " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "05") {
          labelName[i] = "Май " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "06") {
          labelName[i] = "Июнь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "07") {
          labelName[i] = "Июль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "08") {
          labelName[i] = "Август " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "09") {
          labelName[i] = "Сентябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "10") {
          labelName[i] = "Октябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "11") {
          labelName[i] = "Ноябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "12") {
          labelName[i] = "Декабрь " + labelName[i].substring(0, 4);
        }
      }

      let result = {
        labels: labelName,
        datasets: [
          {
            label: "Доля АКБ (%)",
            backgroundColor: ["#17CE3F"],
            borderColor: "#17CE3F",

            data: data1akb,
          },
        ],
      };
      return result;
    },

    DataCountActive() {
      let labelName = ["Начало отчета"];
      let data1car = [0];
      let data210car = [0];
      let data1130car = [0];
      let data3150car = [0];
      let data50car = [0];

      for (let i = 0; i < this.clientsInfoChart[0].chart.length; i++) {
        labelName.push(this.clientsInfoChart[0].chart[i].date);

        data1car.push(this.clientsInfoChart[0].chart[i].active[0]);
        data210car.push(this.clientsInfoChart[0].chart[i].active[1]);
        data1130car.push(this.clientsInfoChart[0].chart[i].active[2]);
        data3150car.push(this.clientsInfoChart[0].chart[i].active[3]);
        data50car.push(this.clientsInfoChart[0].chart[i].active[4]);
      }

      for (let i = 0; i < labelName.length; i++) {
        if (labelName[i].substring(5, 7) == "01") {
          labelName[i] = "Январь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "02") {
          labelName[i] = "Февраль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "03") {
          labelName[i] = "Март " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "04") {
          labelName[i] = "Апрель " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "05") {
          labelName[i] = "Май " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "06") {
          labelName[i] = "Июнь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "07") {
          labelName[i] = "Июль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "08") {
          labelName[i] = "Август " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "09") {
          labelName[i] = "Сентябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "10") {
          labelName[i] = "Октябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "11") {
          labelName[i] = "Ноябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "12") {
          labelName[i] = "Декабрь " + labelName[i].substring(0, 4);
        }
      }

      let result = {
        labels: labelName,
        datasets: [
          {
            label: "1 ТС",
            backgroundColor: "#B8EDF9",
            borderColor: "#B8EDF9",
            data: data1car,
          },
          {
            label: "2-10 ТС",
            backgroundColor: "#229DB7",
            borderColor: "#229DB7",

            data: data210car,
          },
          {
            label: "11-30 ТС",
            borderColor: "#90B0E0",
            backgroundColor: "#90B0E0",
            data: data1130car,
          },
          {
            label: "31-50 ТС",
            borderColor: "#A090E0",
            backgroundColor: "#A090E0",
            data: data3150car,
          },
          {
            label: "> 50 ТС",
            borderColor: "#CB90E0",
            backgroundColor: "#CB90E0",
            data: data50car,
          },
        ],
      };
      return result;
    },

    DataCountLost() {
      let labelName = [""];
      let data1car = [0];
      let data210car = [0];
      let data1130car = [0];
      let data3150car = [0];
      let data50car = [0];

      for (let i = 0; i < this.clientsInfoChart[0].chart.length; i++) {
        labelName.push(this.clientsInfoChart[0].chart[i].date);

        data1car.push(this.clientsInfoChart[0].chart[i].lost[0]);
        data210car.push(this.clientsInfoChart[0].chart[i].lost[1]);
        data1130car.push(this.clientsInfoChart[0].chart[i].lost[2]);
        data3150car.push(this.clientsInfoChart[0].chart[i].lost[3]);
        data50car.push(this.clientsInfoChart[0].chart[i].lost[4]);
      }

      for (let i = 0; i < labelName.length; i++) {
        if (labelName[i].substring(5, 7) == "01") {
          labelName[i] = "Январь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "02") {
          labelName[i] = "Февраль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "03") {
          labelName[i] = "Март " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "04") {
          labelName[i] = "Апрель " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "05") {
          labelName[i] = "Май " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "06") {
          labelName[i] = "Июнь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "07") {
          labelName[i] = "Июль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "08") {
          labelName[i] = "Август " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "09") {
          labelName[i] = "Сентябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "10") {
          labelName[i] = "Октябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "11") {
          labelName[i] = "Ноябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "12") {
          labelName[i] = "Декабрь " + labelName[i].substring(0, 4);
        }
      }

      let result = {
        labels: labelName,
        datasets: [
          {
            label: "1 ТС",
            backgroundColor: "#B8EDF9",
            borderColor: "#B8EDF9",
            data: data1car,
          },
          {
            label: "2-10 ТС",
            backgroundColor: "#229DB7",
            borderColor: "#229DB7",

            data: data210car,
          },
          {
            label: "11-30 ТС",
            borderColor: "#90B0E0",
            backgroundColor: "#90B0E0",
            data: data1130car,
          },
          {
            label: "31-50 ТС",
            borderColor: "#A090E0",
            backgroundColor: "#A090E0",
            data: data3150car,
          },
          {
            label: "> 50 ТС",
            borderColor: "#CB90E0",
            backgroundColor: "#CB90E0",
            data: data50car,
          },
        ],
      };
      return result;
    },

    DataMargeActive() {
      let labelName = [""];
      let data1car = [0];
      let data210car = [0];
      let data1130car = [0];
      let data3150car = [0];
      let data50car = [0];

      for (let i = 0; i < this.clientsInfoChart[1].chart.length; i++) {
        labelName.push(this.clientsInfoChart[1].chart[i].date);

        data1car.push(this.clientsInfoChart[1].chart[i].active[0]);
        data210car.push(this.clientsInfoChart[1].chart[i].active[1]);
        data1130car.push(this.clientsInfoChart[1].chart[i].active[2]);
        data3150car.push(this.clientsInfoChart[1].chart[i].active[3]);
        data50car.push(this.clientsInfoChart[1].chart[i].active[4]);
      }

      for (let i = 0; i < labelName.length; i++) {
        if (labelName[i].substring(5, 7) == "01") {
          labelName[i] = "Январь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "02") {
          labelName[i] = "Февраль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "03") {
          labelName[i] = "Март " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "04") {
          labelName[i] = "Апрель " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "05") {
          labelName[i] = "Май " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "06") {
          labelName[i] = "Июнь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "07") {
          labelName[i] = "Июль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "08") {
          labelName[i] = "Август " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "09") {
          labelName[i] = "Сентябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "10") {
          labelName[i] = "Октябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "11") {
          labelName[i] = "Ноябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "12") {
          labelName[i] = "Декабрь " + labelName[i].substring(0, 4);
        }
      }

      let result = {
        labels: labelName,
        datasets: [
          {
            label: "1 ТС",
            backgroundColor: "#B8EDF9",
            borderColor: "#B8EDF9",
            data: data1car,
          },
          {
            label: "2-10 ТС",
            backgroundColor: "#229DB7",
            borderColor: "#229DB7",

            data: data210car,
          },
          {
            label: "11-30 ТС",
            borderColor: "#90B0E0",
            backgroundColor: "#90B0E0",
            data: data1130car,
          },
          {
            label: "31-50 ТС",
            borderColor: "#A090E0",
            backgroundColor: "#A090E0",
            data: data3150car,
          },
          {
            label: "> 50 ТС",
            borderColor: "#CB90E0",
            backgroundColor: "#CB90E0",
            data: data50car,
          },
        ],
      };
      return result;
    },
    DataMargeLost() {
      let labelName = [""];
      let data1car = [0];
      let data210car = [0];
      let data1130car = [0];
      let data3150car = [0];
      let data50car = [0];

      for (let i = 0; i < this.clientsInfoChart[1].chart.length; i++) {
        labelName.push(this.clientsInfoChart[1].chart[i].date);

        data1car.push(this.clientsInfoChart[1].chart[i].lost[0]);
        data210car.push(this.clientsInfoChart[1].chart[i].lost[1]);
        data1130car.push(this.clientsInfoChart[1].chart[i].lost[2]);
        data3150car.push(this.clientsInfoChart[1].chart[i].lost[3]);
        data50car.push(this.clientsInfoChart[1].chart[i].lost[4]);
      }
      for (let i = 0; i < labelName.length; i++) {
        if (labelName[i].substring(5, 7) == "01") {
          labelName[i] = "Январь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "02") {
          labelName[i] = "Февраль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "03") {
          labelName[i] = "Март " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "04") {
          labelName[i] = "Апрель " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "05") {
          labelName[i] = "Май " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "06") {
          labelName[i] = "Июнь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "07") {
          labelName[i] = "Июль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "08") {
          labelName[i] = "Август " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "09") {
          labelName[i] = "Сентябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "10") {
          labelName[i] = "Октябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "11") {
          labelName[i] = "Ноябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "12") {
          labelName[i] = "Декабрь " + labelName[i].substring(0, 4);
        }
      }

      let result = {
        labels: labelName,
        datasets: [
          {
            label: "1 ТС",
            backgroundColor: "#B8EDF9",
            borderColor: "#B8EDF9",
            data: data1car,
          },
          {
            label: "2-10 ТС",
            backgroundColor: "#229DB7",
            borderColor: "#229DB7",

            data: data210car,
          },
          {
            label: "11-30 ТС",
            borderColor: "#90B0E0",
            backgroundColor: "#90B0E0",
            data: data1130car,
          },
          {
            label: "31-50 ТС",
            borderColor: "#A090E0",
            backgroundColor: "#A090E0",
            data: data3150car,
          },
          {
            label: "> 50 ТС",
            borderColor: "#CB90E0",
            backgroundColor: "#CB90E0",
            data: data50car,
          },
        ],
      };
      return result;
    },

    DataIncomeActive() {
      let labelName = [""];
      let data1car = [0];
      let data210car = [0];
      let data1130car = [0];
      let data3150car = [0];
      let data50car = [0];

      for (let i = 0; i < this.clientsInfoChart[2].chart.length; i++) {
        labelName.push(this.clientsInfoChart[2].chart[i].date);

        data1car.push(this.clientsInfoChart[2].chart[i].active[0]);
        data210car.push(this.clientsInfoChart[2].chart[i].active[1]);
        data1130car.push(this.clientsInfoChart[2].chart[i].active[2]);
        data3150car.push(this.clientsInfoChart[2].chart[i].active[3]);
        data50car.push(this.clientsInfoChart[2].chart[i].active[4]);
      }
      for (let i = 0; i < labelName.length; i++) {
        if (labelName[i].substring(5, 7) == "01") {
          labelName[i] = "Январь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "02") {
          labelName[i] = "Февраль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "03") {
          labelName[i] = "Март " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "04") {
          labelName[i] = "Апрель " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "05") {
          labelName[i] = "Май " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "06") {
          labelName[i] = "Июнь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "07") {
          labelName[i] = "Июль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "08") {
          labelName[i] = "Август " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "09") {
          labelName[i] = "Сентябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "10") {
          labelName[i] = "Октябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "11") {
          labelName[i] = "Ноябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "12") {
          labelName[i] = "Декабрь " + labelName[i].substring(0, 4);
        }
      }

      let result = {
        labels: labelName,
        datasets: [
          {
            label: "1 ТС",
            backgroundColor: "#B8EDF9",
            borderColor: "#B8EDF9",
            data: data1car,
          },
          {
            label: "2-10 ТС",
            backgroundColor: "#229DB7",
            borderColor: "#229DB7",

            data: data210car,
          },
          {
            label: "11-30 ТС",
            borderColor: "#90B0E0",
            backgroundColor: "#90B0E0",
            data: data1130car,
          },
          {
            label: "31-50 ТС",
            borderColor: "#A090E0",
            backgroundColor: "#A090E0",
            data: data3150car,
          },
          {
            label: "> 50 ТС",
            borderColor: "#CB90E0",
            backgroundColor: "#CB90E0",
            data: data50car,
          },
        ],
      };
      return result;
    },
    DataIncomeLost() {
      let labelName = [""];
      let data1car = [0];
      let data210car = [0];
      let data1130car = [0];
      let data3150car = [0];
      let data50car = [0];

      for (let i = 0; i < this.clientsInfoChart[2].chart.length; i++) {
        labelName.push(this.clientsInfoChart[2].chart[i].date);

        data1car.push(this.clientsInfoChart[2].chart[i].lost[0]);
        data210car.push(this.clientsInfoChart[2].chart[i].lost[1]);
        data1130car.push(this.clientsInfoChart[2].chart[i].lost[2]);
        data3150car.push(this.clientsInfoChart[2].chart[i].lost[3]);
        data50car.push(this.clientsInfoChart[2].chart[i].lost[4]);
      }
      for (let i = 0; i < labelName.length; i++) {
        if (labelName[i].substring(5, 7) == "01") {
          labelName[i] = "Январь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "02") {
          labelName[i] = "Февраль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "03") {
          labelName[i] = "Март " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "04") {
          labelName[i] = "Апрель " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "05") {
          labelName[i] = "Май " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "06") {
          labelName[i] = "Июнь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "07") {
          labelName[i] = "Июль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "08") {
          labelName[i] = "Август " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "09") {
          labelName[i] = "Сентябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "10") {
          labelName[i] = "Октябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "11") {
          labelName[i] = "Ноябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "12") {
          labelName[i] = "Декабрь " + labelName[i].substring(0, 4);
        }
      }

      let result = {
        labels: labelName,
        datasets: [
          {
            label: "1 ТС",
            backgroundColor: "#B8EDF9",
            borderColor: "#B8EDF9",
            data: data1car,
          },
          {
            label: "2-10 ТС",
            backgroundColor: "#229DB7",
            borderColor: "#229DB7",

            data: data210car,
          },
          {
            label: "11-30 ТС",
            borderColor: "#90B0E0",
            backgroundColor: "#90B0E0",
            data: data1130car,
          },
          {
            label: "31-50 ТС",
            borderColor: "#A090E0",
            backgroundColor: "#A090E0",
            data: data3150car,
          },
          {
            label: "> 50 ТС",
            borderColor: "#CB90E0",
            backgroundColor: "#CB90E0",
            data: data50car,
          },
        ],
      };
      return result;
    },

    dataActive() {
      let result = {
        labels: ["1 ТС", "2-10 ТС", "11-30 ТС", "31-50 ТС", "> 50 ТС"],
        datasets: [
          {
            backgroundColor: [
              "#B8EDF9",
              "#90D2E0",
              "#90B0E0",
              "#A090E0",
              "#CB90E0",
            ],
            data: [
              this.reportBriefcase.active_ts_1.count,
              this.reportBriefcase.active_ts_2.count,
              this.reportBriefcase.active_ts_3.count,
              this.reportBriefcase.active_ts_4.count,
              this.reportBriefcase.active_ts_5.count,
            ],
          },
        ],
      };
      return result;
    },

    dataNew() {
      let result = {
        labels: ["1 ТС", "2-10 ТС", "11-30 ТС", "31-50 ТС", "> 50 ТС"],
        datasets: [
          {
            backgroundColor: [
              "#B8EDF9",
              "#90D2E0",
              "#90B0E0",
              "#A090E0",
              "#CB90E0",
            ],
            data: [
              this.reportBriefcase.new_ts_1.count,
              this.reportBriefcase.new_ts_2.count,
              this.reportBriefcase.new_ts_3.count,
              this.reportBriefcase.new_ts_4.count,
              this.reportBriefcase.new_ts_5.count,
            ],
          },
        ],
      };
      return result;
    },

    dataBack() {
      let result = {
        labels: ["1 ТС", "2-10 ТС", "11-30 ТС", "31-50 ТС", "> 50 ТС"],
        datasets: [
          {
            backgroundColor: [
              "#B8EDF9",
              "#90D2E0",
              "#90B0E0",
              "#A090E0",
              "#CB90E0",
            ],
            data: [
              this.reportBriefcase.back_ts_1.count,
              this.reportBriefcase.back_ts_2.count,
              this.reportBriefcase.back_ts_3.count,
              this.reportBriefcase.back_ts_4.count,
              this.reportBriefcase.back_ts_5.count,
            ],
          },
        ],
      };
      return result;
    },

    dataLost() {
      let result = {
        labels: ["Активные", "Потерянные"],
        datasets: [
          {
            backgroundColor: ["#C6DFE4", "#A9A9A9"],
            data: [
              this.reportBriefcase.lost_active.count,
              this.reportBriefcase.lost_lost.count,
            ],
          },
        ],
      };
      return result;
    },

    dataActive2() {
      let result = {
        labels: ["Остальные", "Активные"],
        datasets: [
          {
            backgroundColor: ["#43B6F9", "#3CC35A"],
            data: [
              this.reportBriefcase.all_clients.count -
                this.reportBriefcase.active_all.count,
              this.reportBriefcase.active_all.count,
            ],
          },
        ],
      };
      return result;
    },
  },

  async mounted() {
    var currentYear = new Date().getFullYear();
    var lastMonth = new Date().getMonth();
    var currentMonth = lastMonth + 1;

    if (currentMonth < 10) {
      this.dateFrom = "" + currentYear + "-0" + currentMonth;
    } else {
      this.dateFrom = "" + currentYear + "-" + currentMonth;
    }
  },
};
</script>

<style scoped>
.chart-lol {
  border-radius: 5px;
  background: #f8fafc;
  box-shadow: -2px -2px 10px 0px rgba(0, 0, 0, 0.03);
}
.chart-lol p {
  color: #262d29;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

@keyframes progress {
  0% {
    --percentage: 0;
  }
  100% {
    --percentage: var(--value);
  }
}

.orders-tabs a {
  transition: 0.4s ease;
  color: #9ca3c0;
  text-align: center;
  font-size: 13.5px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.orders-tabs a:first-child {
  margin-right: 10px;
}

.checkedTab {
  border-radius: 3px;
  background: #22c55e;
  color: #fff !important;
}

.todayDateLabel {
  color: rgb(125, 125, 125);

  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
}
.todayDate {
  color: rgb(38, 45, 41);

  font-size: 23px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}
.workDays {
  color: rgb(125, 125, 125);

  font-size: 14px;
  line-height: 34px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}
.workDays > span {
  font-size: 24px;
}
.numberOrders {
  color: rgb(142, 142, 142);

  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
}
.labelOrders {
  color: rgb(125, 125, 125);
  font-size: 15px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
}
.labelOrdersToday {
  color: rgb(38, 45, 41);
  font-size: 12.8px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
}

.numberToday {
  color: rgb(142, 142, 142);
  font-size: 36.57px;
  font-weight: 900;
  line-height: 44px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}
.numberLabelToday {
  color: rgb(38, 45, 41);

  font-size: 12.8px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}
.planText {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
  color: rgb(171, 171, 171);
}
.planText > span {
  color: rgb(80, 80, 80);
}

.titleReport {
  color: rgb(38, 45, 41);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
}

@property --percentage {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}

[role="progressbar"] {
  --percentage: var(--value);
  --primary: #369;
  --secondary: rgb(232, 232, 232);
  --size: 220px;
  animation: progress 2s 0.5s forwards;
  width: var(--size);
  aspect-ratio: 2 / 1;
  border-radius: 50% / 100% 100% 0 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.green[role="progressbar"] {
  --primary: rgb(34, 197, 94);
}
.yellow[role="progressbar"] {
  --primary: rgb(254, 171, 26);
}
.red[role="progressbar"] {
  --primary: rgb(250, 52, 76);
}

[role="progressbar"]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: conic-gradient(
    from 0.75turn at 50% 100%,
    var(--primary) calc((var(--percentage) - 25) * 1% / 2),
    var(--secondary) calc((var(--percentage) - 25) * 1% / 2 + 0.1%)
  );
  mask: radial-gradient(at 50% 100%, white 55%, transparent 55.5%);
  mask-mode: alpha;
  -webkit-mask: radial-gradient(at 50% 100%, #0000 55%, #000 55.5%);
  -webkit-mask-mode: alpha;
}

[role="progressbar"]::after {
  counter-reset: percentage calc(var(--value));
  content: counter(percentage) "%";
  font-family: Helvetica, Arial, sans-serif;
  font-size: 45px;
  color: var(--primary);
  line-height: 80%;
  font-weight: bolder;
}

.month-report-top {
  padding: 12px 15px;
  border-radius: 4px;
}

.textTasks > p,
.textTasksInner p {
  color: rgb(38, 45, 41);
  font-size: 12.8px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
  display: inherit;
}

.textTasks > p > span,
.textTasksInner p > span {
  font-size: 33px;
  font-weight: 900;
  line-height: 40px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
  margin-right: 15px;
}

.task-wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin-top: 12px;
}

.task-window-title {
  color: rgb(38, 45, 41);
  font-size: 12.8px;
  font-weight: 700;
  line-height: 15px;
  text-transform: uppercase;
  margin-bottom: 12px;
}
.text-task-block .innerTextTB {
  font-size: 12.8px;
  font-weight: 700;
  line-height: 15px;
  color: rgb(38, 45, 41);
  text-transform: uppercase;
}
.text-task-block .innerTextTB > span {
  font-size: 36.57px;
  font-weight: 900;
  line-height: 54px;
  text-transform: uppercase;
}
.line100 {
  position: absolute;
  border: 1.09px solid rgb(0, 0, 0);
  width: 25px;
  top: 39px;
  right: 29px;
  z-index: 5;
  display: block;
  transform: rotate(-45deg);
}
.chart-title {
  color: rgb(38, 45, 41);
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.innerTextPie {
  bottom: 34%;
  right: 50%;
  height: 66px;
  transform: translateY(0%) translateX(50%);
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
}
.innerTextPie1 {
  bottom: 40%;
  right: 50%;
  height: 66px;
  transform: translateY(0%) translateX(50%);
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
}
.phoneAbsolute {
  bottom: 33%;
}
.innerValue {
  color: rgb(61, 61, 61);
  font-size: 14.33px;
  font-weight: 900;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}
.innerValue > span {
  font-size: 26px;
}

.innerValue1 {
  color: rgb(61, 61, 61);
  font-size: 10.33px;
  font-weight: 900;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}
.innerValue1 > span {
  font-size: 16px;
}
.innerValueMini {
  color: rgb(61, 61, 61);
  font-size: 12px;
  font-weight: 900;
  line-height: 14px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}
.innerCaption {
  color: rgb(61, 61, 61);
  font-size: 7px;
  font-weight: 900;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}
.button-form-report {
  border-radius: 8px;
  background: #22c55e;
  border: none;
  color: #fff;
  text-align: center;
  font-size: 13.5px;
  font-weight: 700;
  line-height: 13.5px;
  text-transform: uppercase;
  padding: 15px 88px;
}

.absolute-list {
  right: 0px;
  box-shadow: 0px 0px 5px #00000060;
  max-height: 200px;
  overflow-y: auto;
}
.textTasksInner p.font-latobold {
  text-align: center;
}
.title-type {
  margin-bottom: 75px;
}

.title-block-briefcase {
}

.wrap-semi-th {
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress-pay-wrap {
  height: 56px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
.progress-pay {
  position: absolute;
  height: 56px;
  width: calc(var(--value) * 100%);
  border-radius: 8px;
  z-index: 2;
  background: #c6dfe4;
  left: 0;
  top: 0;
  max-width: 100%;
  overflow-x: hidden;
}
.progress-pay1 {
  position: absolute;
  height: 56px;
  width: calc(var(--value) * 100%);
  border-radius: 8px;
  z-index: 2;
  background: #a9a9a9;
  left: 0;
  top: 0;
  max-width: 100%;
  overflow-x: hidden;
}
.progress-pay-wrap > .number-progress {
  z-index: 4;
  width: 100%;
  text-align: center;
}

.wrap-lost-managers {
  max-height: calc(100% - 57px);
  overflow-y: auto;
  width: 100%;
}
</style>
