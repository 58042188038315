<template>
  <div
    v-if="!s.loader && showClients"
    style="position: relative"
    class="w-full bg-white v-bgray2 mb-8 overflow-hidden rounded-xl relative overflow-x-auto"
  >
    <div class="relative h-10 min-w-full">
      <div
        class="grid grid-cols-client-table items-center h-10 v-bbgray px-4 uppercase absolute min-w-full"
      >
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
        ></div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
        >
          №
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
        >
          Наименование
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
        >
          vip
        </div>
        <div
          v-if="role == 'Studio' || role == 'Hosd'"
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
        >
          <div class="btn btn-sort">Менеджер</div>
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] text-center"
        >
          Заказов
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] text-center"
        >
          ТС
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] text-center"
        >
          Дата заказа
        </div>
      </div>
    </div>

    <div
      v-for="item in clientListPort"
      :key="item"
      class="grid grid-cols-client-table px-4 py-2 v-bbgray items-center transition duration-300 cursor-pointer hover:shadow-v1 bg-teal-light odd:bg-white max-2xl:grid-cols-all-mobile max-2xl:auto-rows-[50px]"
      :class="{
        'status-11': item.correct == 0,
        'status-6': item.correct == 1,
      }"
    >
      <div></div>
      <div>{{ item.iid }} {{ item.correct }}</div>

      <a
        class="flex items-center cursor-pointer group"
        @click="showClient(item)"
      >
        <div
          v-if="item.correct != 1"
          class="text-white w-11 h-11 mr-4 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px] bg-status-7"
        >
          <svg class="h-5 w-5 fill-white">
            <use xlink:href="@/assets/i.svg#status-11"></use>
          </svg>
        </div>
        <template v-else>
          <div
            class="text-white w-11 h-11 mr-4 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px] bg-status-6"
          >
            <IcoPic :pic="item.picture" :name="item.name" />
          </div>
        </template>
        <span
          class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
          >{{ item.name }}</span
        >
      </a>

      <div>
        <svg v-if="item.vip" class="h-5 w-5 fill-gray">
          <use xlink:href="@/assets/i.svg#ico-vip"></use>
        </svg>
      </div>
      <div v-if="role == 'Studio' || role == 'Hosd'">
        <div v-if="item.mname" class="flex items-center">
          <div
            class="min-w-[1.5rem] w-11 h-11 rounded-full mr-3 overflow-hidden bg-status-7"
          >
            <IcoPic :pic="item.mpicture" :name="item.msecondname" />
          </div>
          <span class="font-latobold">{{ item.msecondname }}</span>
        </div>
      </div>

      <div class="flex items-center justify-center">
        <svg class="w-5 h-5 fill-gray-400 mr-2">
          <use xlink:href="@/assets/i.svg#nav-flow"></use>
        </svg>
        <span>{{ item.countorders }}</span>
      </div>

      <div class="text-center">
        <span>{{ item.countcars }} из {{ item.ts }}</span>
        <template v-if="item.ts">
          <div
            class="mt-2 w-full flex h-[5px] rounded-full overflow-hidden bg-gray-200"
          >
            <div
              class="flex flex-col justify-center text-white w-full h-full"
              :style="'width:' + (item.countcars * 100) / item.ts + '%'"
              :class="{
                'bg-status-7': (item.countcars * 100) / item.ts < 30,
                'bg-status-10':
                  (item.countcars * 100) / item.ts > 29 &&
                  (item.countcars * 100) / item.ts < 60,
                'bg-status-1':
                  (item.countcars * 100) / item.ts > 59 &&
                  (item.countcars * 100) / item.ts < 90,
                'bg-status-5': (item.countcars * 100) / item.ts > 89,
              }"
            ></div>
          </div>
        </template>
      </div>

      <div class="text-center">
        <div v-if="item.lastorder">
          {{ moment(item.lastorder).format("DD.MM.YYYY") }}
        </div>
        <div v-else>-</div>
      </div>
    </div>
  </div>
  <div v-else-if="s.loader" class="left-0 flex-cc w-full">
    <img src="https://api.tahocrm.ru/images/loader.svg" />
  </div>
  <div
    v-else
    style="position: relative"
    class="top-128 left-0 flex-cc w-full text-slate-300 text-2xl text-center"
  >
    Нет данных
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "ClientsListModal",
  props: {
    clientListProp: Array,
  },
  data() {
    return {
      moment: moment,
      checkedIds: [],
      checkAll: false,
      inputActivityRangeFlag: false,
      loadingWrap: false,
      showClients: false,
    };
  },

  computed: {
    ...mapGetters([
      "s",
      "role",
      "profile",
      "clientListPort",
      "roles",
      "ClientCounter",
      "selectManagers",
      "selectMasters",
      "ccount",
      "clientPaginator",
    ]),
    // page() {
    //   return this.clientPaginator.page;
    // },
    // itemsInPage() {
    //   return 1000;
    // },
    // PaginatedClientList() {
    //   return this.clientListPort.slice(
    //     this.page * this.itemsInPage,
    //     (this.page + 1) * this.itemsInPage
    //   );
    // },
    // pageCount() {
    //   return Math.ceil((this.clientListPort?.length ?? 0) / this.itemsInPage);
    // },
  },

  methods: {
    ...mapActions([
      "getClients",
      "setClient",
      "findClient",
      "statusClient",
      "showClient",
      "addClient",
      "clearSettings",
      "setGroupManager",
      "closePopup",
      "getActiveClients",
      "loadSettings",
      "setSettings",
      "getClientListIds",
    ]),

    filterStatus(status) {
      this.s.status = status;
      this.statusClient(this.s);
    },

    // CheckAllClients() {
    //   if (this.checkAll) {
    //     this.checkedIds = [];
    //     this.checkAll = false;
    //   } else {
    //     this.checkedIds = this.clientListPort.map((item) => item.iid);
    //     this.checkAll = true;
    //   }
    // },

    Clear() {
      this.clearSettings();
      this.LoadClients();
    },

    addThisClient() {
      if (confirm("Подтвердите действие добавления клиента")) {
        this.addClient();
      }
    },
  },

  async mounted() {
    window.DEBUG_CLIENT = this;
    this.loadSettings();
    await await this.getClientListIds({
      ids: this.clientListProp,
    });

    this.showClients = true;
  },
};
</script>

<style>
.bg-status-8 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 72 126 / var(--tw-bg-opacity));
}
.grid-cols-17 {
  grid-template-columns: repeat(15, minmax(0, 1fr));
}

@media (max-width: 1441px) {
  .font-semibold {
    font-size: 13px;
  }
}
</style>
