<template>
  <div class="flex align-center mt-5">
    <input type="month" class="month-report-top mr-5" v-model="dateTop" />

    <button @click="getTableMark()" class="button-form-report">
      Сформировать
    </button>
  </div>
  <div v-if="showMarketing" class="flex justify-between mt-5 pb-10">
    <div class="plan-table-wrap rounded-[8px] w-9/12">
      <div class="plan-table-title bg-white border-tr-8">
        <h2>статистика каналов продаж по лидам</h2>
      </div>
      <div class="wrap-pt-grid title-padding-pt bg-green-200">
        <p>Канал продаж</p>
        <!-- <p>Раб. дни</p> -->
        <p>Бизнес-процесс</p>
        <p>Новые</p>
        <p>Вторичные</p>
        <p>Всего</p>
      </div>
      <div
        v-for="row in marketing"
        :key="row"
        class="wrap-pt-grid data-padding-pt bg-white border-br-8"
      >
        <p>
          <span v-for="com in row.communication_type" :key="com">
            <span v-if="com == 1"
              ><b> Интернет контекст звонок </b> <br
            /></span>
            <span v-if="com == 2"
              ><b> Интернет контекст заявка с сайта </b> <br
            /></span>
            <span v-if="com == 3"><b> Интернет СЕО звонок </b> <br /></span>
            <span v-if="com == 4"
              ><b> Интернет СЕО заявка с сайта </b><br />
            </span>
            <span v-if="com == 5"><b> Звонок Исходящий </b></span>
            <span v-if="com == 6"><b> Письмо </b> <br /></span>
            <span v-if="com == 7"><b> Пришел в офис /</b><br /> </span>
            <span v-if="com == 8"
              ><b> Заявка через ЛК агента СРМ /</b><br />
            </span>
            <span v-if="com == 9"><b> Рассылка/Звонок </b> <br /></span>
            <span v-if="com == 10"
              ><b> Заявка через ЛК клиента в СРМ </b><br />
            </span>
            <span v-if="com == 11"
              ><b> Заявка через ЛК агента-сотрудника </b>
            </span>
            <span v-if="com == 13"><b> Заявка с сайта </b> </span>
            <span v-if="com == 15"><b> Звонок Входящий </b> </span>
          </span>
        </p>

        <p v-if="row.business_proc == 1">Пролонгация тахографы (СКЗИ)</p>
        <p v-if="row.business_proc == 2">Пролонгация настройки ЕСТР</p>
        <p v-if="row.business_proc == 3">Пролонгация пропуска</p>
        <p v-if="row.business_proc == 4">Пролонгация карты</p>
        <p v-if="row.business_proc == 5">Контроль качества</p>
        <p v-if="row.business_proc == 6">Реанимация неактивных клиентов</p>
        <p v-if="row.business_proc == 7">Реанимация клиентов без заказов</p>
        <p v-if="row.business_proc == 8">Рекламации</p>
        <p v-if="row.business_proc == 9">Как дела</p>
        <p v-if="row.business_proc == 10">Общий</p>
        <p v-if="row.business_proc == 11">АБ Мониторинг</p>
        <p v-if="row.business_proc == 12">Агент</p>
        <p v-if="row.business_proc == 13">Рекомендация</p>
        <p v-if="row.business_proc == 14">Интернет</p>
        <p v-if="row.business_proc == 15">Новая база</p>

        <p class="price-text">
          {{ row.first }} <span>{{ BlockNew(row.first) }}</span>
        </p>
        <p class="price-text">
          {{ row.second }} <span>{{ BlockSecond(row.second) }}</span>
        </p>
        <p class="price-text">
          {{ row.quantity }} <span>{{ BlockTotal(row.quantity) }}</span>
        </p>
      </div>
      <div
        class="wrap-pt-grid data-padding-pt bg-white border-br-8 border-top-b"
      >
        <p>Всего</p>
        <p></p>
        <p class="price-text">
          {{ sum_first }} <span>{{ pers_first.toFixed(2) }}%</span>
        </p>
        <p class="price-text">
          {{ sum_second }} <span>{{ pers_second.toFixed(2) }}%</span>
        </p>
        <p class="price-text">{{ sum_quantity }} <span>100%</span></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SellChannel",
  data() {
    return {
      showMarketing: false,

      sum_first: 0,
      sum_second: 0,
      sum_quantity: 0,
      pers_first: 0,
      pers_second: 0,
      dateTop: null,
    };
  },

  computed: {
    ...mapGetters(["s", "profile", "role", "roles", "marketing"]),
  },

  methods: {
    ...mapActions(["getMarketingAll"]),

    BlockNew(value) {
      if (value == 0) {
        return "0%";
      } else {
        let result = ((value / this.sum_first) * 100).toFixed(2);
        return +result + "%";
      }
      // return value;
    },

    BlockSecond(value) {
      if (value == 0) {
        return "0%";
      } else {
        let result = ((value / this.sum_second) * 100).toFixed(2);
        return +result + "%";
      }
      // return value;
    },

    BlockTotal(value) {
      if (value == 0) {
        return "0%";
      } else {
        let result = ((value / this.sum_quantity) * 100).toFixed(2);
        return +result + "%";
      }
      // return value;
    },

    async getTableMark() {
      this.sum_first = 0;
      this.sum_second = 0;
      this.sum_quantity = 0;
      await this.getMarketingAll({ date: this.dateTop });

      for (let i = 0; i < this.marketing.length; i++) {
        this.sum_first += this.marketing[i].first;
        this.sum_second += this.marketing[i].second;
        this.sum_quantity += this.marketing[i].quantity;
      }

      this.pers_first = (this.sum_first / this.sum_quantity) * 100;
      this.pers_second = (this.sum_second / this.sum_quantity) * 100;

      this.showMarketing = true;
    },
  },

  async mounted() {
    var currentYear = new Date().getFullYear();
    var currentMonth = new Date().getMonth();
    // this.dateTop = currentYear;
    currentMonth = currentMonth + 1;
    // console.log('Текущий год - ', currentYear);
    // console.log('Текущий месяц - ', currentMonth);

    if (currentMonth < 10) {
      this.dateTop = "" + currentYear + "-0" + currentMonth;
    } else {
      this.dateTop = "" + currentYear + "-" + currentMonth;
    }
    console.log("Дата на обработку - ", this.dateTop);
  },
};
</script>

<style scoped>
.button-form-report {
  border-radius: 3px;
  border: none;
  color: #fff;
  text-align: center;
  font-size: 13.5px;
  font-weight: 700;
  line-height: 16.5px;
  text-transform: uppercase;
  padding: 10px 38px;
}
.wrap-pt-grid {
  display: grid;
  grid-template-columns: 30% 30% 13.333% 13.333% 13.333%;
}
.wrap-plant-grid {
  display: grid;
  grid-template-columns: 30% 30% 13.333% 13.333% 13.333%;
}
.plan-table-title {
  padding: 25px 0;
  color: rgb(38, 45, 41);
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}
.border-br-8 {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.border-tr-8 {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.wrap-pt-grid > * {
  color: rgb(38, 45, 41);
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: capitalize;

  border-right: 1px solid rgb(232, 236, 236);
}
.wrap-pt-grid > *:last-child {
  border-right: none;
}
.wrap-pt-grid > *:nth-child(1) {
  border-right: 1px solid #e8ecec;
  padding: 0;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.title-padding-pt > * {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.title-padding-pt > * {
  font-weight: 600;
  font-size: 12px;
  color: #262d29;
}
.plan-table-wrap > .data-padding-pt:nth-child(odd) > * {
  background: #fefefe;
}
.plan-table-wrap > .data-padding-pt:nth-child(even) > * {
  background: #f7fbfc;
}
.data-padding-pt > * {
  border-bottom: 1px solid #e8ecec;
  align-items: center;
  padding: 12px 0;
}
.images-row > img {
  height: 13px;
  width: auto;
}
.user-row > img {
  height: 20px;
  width: auto;
  margin-top: 3px;
}
.top-th {
  color: rgb(38, 45, 41);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0%;
  text-align: center;
  border-right: 1px solid rgb(232, 236, 236);
  border-bottom: 1px solid rgb(232, 236, 236);
  padding: 10px 0;
}
.bottom-th,
.row-pt-data {
  display: flex;
  align-items: center;
}
.bottom-th > p {
  padding: 5px 0;
}
.bottom-th > *,
.row-pt-data > * {
  color: rgb(38, 45, 41);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0%;
  text-align: center;
  border-right: 1px solid rgb(232, 236, 236);
  width: 33.3%;
}
.title-col {
  border-right: 1px solid rgb(232, 236, 236);
  border-bottom: 1px solid rgb(232, 236, 236);

  display: flex;
  align-items: center;
}
.title-col > *:first-child {
  width: 68px;
}
.title-col > *:nth-child(2) {
  width: 185px;
}
.title-col > *:last-child {
  width: 125px;
}
.title-col > p {
  color: rgb(38, 45, 41);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: capitalize;
}
.money-row > p {
  color: rgb(38, 45, 41);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: capitalize;
}
.row-pt-data > span {
  padding-top: 20px;
  padding-bottom: 20px;
}
.row-pt-data > input,
.row-pt-data > p {
  padding-top: 20px;
  padding-bottom: 20px;
  background: rgba(217, 217, 217, 0.3);
}

.row-pt-data > input {
  text-align: center;
}
.border-top-b {
  border-top: 1px solid #000;
}
.border-top-b > * {
  border-bottom: 1px solid transparent;
  background-color: #fefefe !important;
}

.price-text {
  font-size: 17px;
  color: #262d29;
  font-weight: 600;
}
.price-text span {
  font-size: 14px;
  color: #8e8e8e;
}
.button-form-report {
  border-radius: 3px;
  background: #22c55e;
  border: none;
  color: #fff;
  text-align: center;
  font-size: 13.5px;
  font-weight: 700;
  line-height: 13.5px;
  text-transform: uppercase;
  padding: 12px 88px;
}
.month-report-top {
  padding: 10px 15px !important;
  border-radius: 10px;
}
</style>
