import API from "@/services/api";
import moment from "moment";

export default {
    state: {
        // change active component
        statisticShow: "",

        // values from db
        statOrders: [],
        statServices: [],
        statSalaries: [],
        statLoading: [],
        statQuality: [],
        statRating: [],

        statQualityOrders: [],

        managerTasks: [],
        planReportTable: [],
        planMRT: [],
        reportPlanStats: {},
        reportFactManager: {},
        reportGraphFact: {},

        factTouchesList: [],
        factLeadList: [],
        factBidList: [],
        factInvoiceList: [],

        listBirthdays: [],

        birthdaysPaginator: {
            page: 0,
            itemsInPage: 10
        },

        reportBriefcase: {},
        marketing: [],
    },

    mutations: {
        statCalendar: (state, statCalendar) => {
            state.statCalendar = statCalendar;
        },
        setActivity: (state, managerTasks) => { 
            state.managerTasks = managerTasks;
        },
        // change active component
        changeShowPage: (state, result) => {
            localStorage.setItem("statisticShow", result);
            state.statisticShow = result;
        },

        setStatQualityScore: (state, result) => {
            localStorage.setItem("statQualityScore", JSON.stringify(result));
            let localShow = JSON.parse(localStorage.getItem("statQualityScore"));
            state.statQualityScore = localShow;
        },

        // clear values
        clearStat: (state) => {
            state.statOrders = null;
            state.statServices = null;
            state.statSalaries = null;
            state.statLoading = null;
            state.statQuality = null;
            state.statRating = null;
            state.statQualityOrders = null;
        },

        // set values
        setStatOrders: (state, orders) => {
            state.statOrders = orders;
        },
        setStatServices: (state, services) => {
            state.statServices = services;
        },
        setStatSalaries: (state, salaries) => {
            state.statSalaries = salaries;
        },
        setStatLoading: (state, loading) => {
            state.statLoading = loading;
        },
        setStatQuality: (state, quality) => {
            state.statQuality = quality;
        },
        setStatRating: (state, rating) => {
            state.statRating = rating;
        },

        setStatQualityOrders: (state, orders) => {
            state.statQualityOrders = orders;
        },
        setPlanReportTable: (state, planReportTable) => {
            state.planReportTable = planReportTable;
        },
        setplanMRT: (state, planMRT) => {
            state.planMRT = planMRT;
        },
        setStateReportPlanStats: (state, reportPlanStats) => {
            state.reportPlanStats = reportPlanStats;
        },
        setStateReportFactManager: (state, reportFactManager) => {
            state.reportFactManager = reportFactManager;
        },
        setStateGraphFact: (state, reportGraphFact) => {
            state.reportGraphFact = reportGraphFact;
        },

        setFactTouchesList: (state, factTouchesList) => {
            state.factTouchesList = factTouchesList;
        },
        setFactLeadsList: (state, factLeadList) => {
            state.factLeadList = factLeadList;
        },
        setFactBidsList: (state, factBidList) => {
            state.factBidList = factBidList;
        },
        setFactInvoicesList: (state, factInvoiceList) => {
            state.factInvoiceList = factInvoiceList;
        },

        setBirthday: (state, listBirthdays) => {
            state.listBirthdays = listBirthdays;
        },

        setBriefcase: (state, reportBriefcase) => {
            state.reportBriefcase = reportBriefcase;
        },

        setMarketing: (state, marketing) => {
            state.marketing = marketing;
        },
    },

    actions: {
        async getMarketingAll(ctx, data) {
            let res = await API.GET("marketing/report", { 
                date: data.date
            });

            if (res.status == 200) {
                // console.log("getGraphFact", res.data.chart_plan_fact);
                res.data.marketing ? ctx.commit("setMarketing", res.data.marketing) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            } 
        },

        async getPortfel(ctx, data) {
            let res = await API.GET("briefcase/report", { 
                date: data.date
            });

            if (res.status == 200) {
                // console.log("getGraphFact", res.data.chart_plan_fact);
                res.data.briefcase ? ctx.commit("setBriefcase", res.data.briefcase) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            } 
        },

        getFactTouchList(ctx, data) {
            console.log('data', data);
            return API.GET("touch/get-by-ids", { ids: data.ids.join(' ')}).then((res) => {
                console.log("getFactTouchList", res.data.touches);
                res.data.touches ? ctx.commit("setFactTouchesList", res.data.touches) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },
        getFactLeadList(ctx, data) {
            console.log('data', data);
            return API.GET("lead/get-by-ids", { ids: data.ids.join(' ')}).then((res) => {
                console.log("getFactLeadList", res.data.leads);
                res.data.leads ? ctx.commit("setFactLeadsList", res.data.leads) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },
        getFactBidList(ctx, data) {
            console.log('data', data);
            return API.GET("bid/get-by-ids", { ids: data.ids.join(' ')}).then((res) => {
                console.log("getFactBidList", res.data.bids);
                res.data.bids ? ctx.commit("setFactBidsList", res.data.bids) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },
        getFactInvoiceList(ctx, data) {
            console.log('data', data);
            return API.GET("invoice/get-by-ids", { ids: data.ids.join(' ')}).then((res) => {
                console.log("getFactInvoiceList", res.data.invoices);
                res.data.invoices ? ctx.commit("setFactInvoicesList", res.data.invoices) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        async regetGraphFact (ctx, data) {
            let res = await API.GET("report/recount-chart", { 
                date: data.date
            });

            if (res.status == 200) {
                console.log("getGraphFact", res.data.chart_plan_fact);
                res.data.chart_plan_fact ? ctx.commit("setStateGraphFact", res.data.chart_plan_fact) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            }       
        },

        async getBirthdays(ctx) {
            let res = await API.GET("report/birthday");

            if (res.status == 200) {
                res.data.birthday ? ctx.commit("setBirthday", res.data.birthday) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            }   
        },

        async getGraphFact (ctx) {
            let res = await API.GET("report/chart-plan-fact");

            if (res.status == 200) {
                console.log("getGraphFact", res.data.chart_plan_fact);
                res.data.chart_plan_fact ? ctx.commit("setStateGraphFact", res.data.chart_plan_fact) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            }       
        },

        clearReportFactManager(ctx) {
            ctx.commit("setStateReportFactManager", {});
        },

        async getThisReportFactManager (ctx, data) {
            let res = await API.GET("report/manager-plan-fact", { 
                lead_dir: data.lead_dir, 
                inc_dir: data.inc_dir, 
                business: data.business
            });

            if (res.status == 200) {
                console.log("getReportFactManager", res.data.report_plan);
                res.data.report_plan ? ctx.commit("setStateReportFactManager", res.data.report_plan) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            }
        },       

        getReportFactManager (ctx, data) {
            return API.GET("report/report-plan-fact", { date: data.date, manager_id: data.manager_id, lead_dir: data.lead_dir, inc_dir: data.inc_dir, business: data.business}).then((res) => {
                console.log("getReportFactManager", res.data.report_plan);
                res.data.report_plan ? ctx.commit("setStateReportFactManager", res.data.report_plan) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },



        async getReportPlanStats (ctx, data) {
            let res = await API.GET("report/report-plan-facts", { date: data.date});

            if (res.status == 200) {
                console.log("report_plan_facts", res.data.report_plan_facts);
                res.data.report_plan_facts ? ctx.commit("setStateReportPlanStats", res.data.report_plan_facts) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            }             
        },

        async copyPlan(ctx, data) {
            let res = await API.GET("report/copy-plan", { date: data.date});

            if (res.status == 200) {
                console.log("setPlanReportTable", res.data);
                res.data.list_plans ? ctx.commit("setPlanReportTable", res.data.list_plans) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            }  
        },

        async getPlanReportTable(ctx, data) {
            let res = await API.GET("report/list-plans", { date: data.date});

            if (res.status == 200) {
                console.log("setPlanReportTable", res.data);
                res.data.list_plans ? ctx.commit("setPlanReportTable", res.data.list_plans) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            } 
        },
        async getManagerPlanReportTable(ctx, data) {
            let res = await  API.GET("report/list-plan", { date: data.date, manager_id: data.manager_id  });
            if (res.status == 200) {
                res.data.list_plan ? ctx.commit("setplanMRT", res.data.list_plan) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            }
        },
        setPlanMRT(ctx, data) {
            let formData = new FormData();
            formData.append("inputPlan", JSON.stringify(data));
            API.POST("report/input-plan", formData).then((res) => {                
                res.data.list_plan ? ctx.commit("setplanMRT", res.data.list_plan) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        approveReport(ctx, data) {
            let formData = new FormData();
            formData.append("manager_id", data.manager_id);
            formData.append("date", data.date);
            API.POST("report/approved", formData).then((res) => {                
                res.data.list_plan ? ctx.commit("setplanMRT", res.data.list_plan) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },        

        async setNewBday(ctx, data) {
            let formData = new FormData();
            formData.append("id", data.id);
            formData.append("bday", data.bday);
            let res = await API.POST("report/birthday-new", formData);
            if (res.status == 200) {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            }
        },

        async delRowPlan(ctx, data) {
            let formData = new FormData();
            formData.append("manager_id", data.manager_id);
            formData.append("date", data.date);
            let res = await API.POST("report/del-manager", formData);
            if (res.status == 200) {
                res.data.list_plan ? ctx.commit("setPlanReportTable", res.data.list_plan) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            }
            
        },
        async addRowPlan(ctx, data) {
            let formData = new FormData();
            formData.append("manager_id", data.manager_id);
            formData.append("date", data.date);
            let res = await API.POST("report/add-manager", formData);
            if (res.status == 200) {
                res.data.list_plan ? ctx.commit("setPlanReportTable", res.data.list_plan) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            }
        },
        async changePlan(ctx, data) {
            let formData = new FormData();
            // let planningRow = JSON.parse(data.row);
            console.log('data.row', data.row);

            formData.append("manager_id", data.row.profile.id);
            formData.append("date", data.date);
            formData.append("taho_plan", data.row.taho_plan);
            formData.append("taho_mean", data.row.taho_mean);
            formData.append("taho_margin", data.row.taho_margin);
            formData.append("monitor_plan", data.row.monitor_plan);
            formData.append("monitor_mean", data.row.monitor_mean);
            formData.append("monitor_margin", data.row.monitor_margin);
            formData.append("other_plan", data.row.other_plan);
            formData.append("other_mean", data.row.other_mean);
            formData.append("other_margin", data.row.other_margin);

            let res = await API.POST("report/input-plan", formData);
            if (res.status == 200) {
                res.data.list_plan ? ctx.commit("setPlanReportTable", res.data.list_plan) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            }
        },

        setSetting(ctx) {
            if (!ctx.rootState.s.start) {
                ctx.rootState.s.start = moment().startOf("month").format("YYYY-MM-DD");
            }
            if (!ctx.rootState.s.end) {
                ctx.rootState.s.end = moment().endOf("month").format("YYYY-MM-DD");
            }
            ctx.dispatch("setSettings", ctx.rootState.s);
        },

        getStatShow(ctx) {
            let show = localStorage.getItem("statisticShow");
            if (!show) {
                show = "main";
                localStorage.setItem("statisticShow", show);
            }
            ctx.commit("changeShowPage", show);
        },

        setStatShow(ctx, show) {
            ctx.commit("changeShowPage", show);
        },

        // Получение всех данных
        getStatAll(ctx) {
            const t0 = performance.now();
            ctx.commit("clearStat");
            API.GET("statistic/all", ctx.rootState.s).then((res) => {
                ctx.commit("setStatOrders", res.data.orders);
                ctx.commit("setStatServices", res.data.services);
                ctx.commit("setStatSalaries", res.data.salaries);
                ctx.commit("setStatLoading", res.data.loading);
                ctx.commit("setStatQuality", res.data.quality);
                ctx.commit("setStatRating", res.data.rating);
                console.log(performance.now() - t0, "ms");
            });
        },

        // Получение всех данных с фильтром
        findStats(ctx, s) {
            ctx.dispatch("getStatAll", s);
        },

        getStatQualityOrders(ctx, data = false) {
            if (data) {
                ctx.rootState.s.category = data.category;
                ctx.rootState.s.type = data.score;
                ctx.dispatch("setSettings", ctx.rootState.s);
            }
            console.log("QualityOrders s", ctx.rootState.s);
            API.GET("statistic/get-quality-orders", ctx.rootState.s).then((res) => {
                console.log("getStatQualityOrders", res.data);
                ctx.commit("setStatQualityOrders", res.data.orders);
            });
        },

        //

        //

        //

        //

        // OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD
        changeStatQualityScore(ctx, { category, score }) {
            if (!localStorage.getItem("statQualityScore")) {
                localStorage.setItem("statQualityScore", JSON.stringify({ category: category, score: score }));
                let localShow = JSON.parse(localStorage.getItem("statQualityScore"));
                ctx.commit("setStatQualityScore", localShow);
            }
            if (localStorage.getItem("statQualityScore")) {
                ctx.commit("setStatQualityScore", JSON.parse(localStorage.getItem("statQualityScore")));
            }
            if (category == 1) {
                localStorage.setItem("statQualityScore", JSON.stringify({ category: "1", score: score }));
                let localShow = JSON.parse(localStorage.getItem("statQualityScore"));
                ctx.commit("setStatQualityScore", localShow);
            }
            if (category == 2) {
                localStorage.setItem("statQualityScore", JSON.stringify({ category: "2", score: score }));
                let localShow = JSON.parse(localStorage.getItem("statQualityScore"));
                ctx.commit("setStatQualityScore", localShow);
            }
            if (category == 3) {
                localStorage.setItem("statQualityScore", JSON.stringify({ category: "3", score: score }));
                let localShow = JSON.parse(localStorage.getItem("statQualityScore"));
                ctx.commit("setStatQualityScore", localShow);
            }
            if (category != 1 && category != 2 && category != 3) {
                localStorage.setItem("statQualityScore", JSON.stringify({ category: category, score: score }));
                let localShow = JSON.parse(localStorage.getItem("statQualityScore"));
                ctx.commit("setStatQualityScore", localShow);
            }
        },

        getStatQualityScore(ctx) {
            if (localStorage.getItem("statQualityScore")) {
                ctx.commit("setStatQualityScore", JSON.parse(localStorage.getItem("statQualityScore")));
            }
        },

        // get values
        // getStatOrders(ctx) {
        //     console.log("getStatOrders");
        //     const t0 = performance.now();
        //     API.GET("statistic/orders").then((res) => {
        //         ctx.commit("setStatOrders", res.data.orders);
        //         console.log(performance.now() - t0, "ms");
        //         // console.log("Orders", res.data.orders);

        //         ctx.dispatch("loadSettings").then(() => {
        //             ctx.dispatch("findStatOrders", ctx.rootState.s);
        //         });
        //     });
        // },

        // getStatServices(ctx) {
        //     API.GET("statistic/prices").then((res) => {
        //         ctx.commit("setStatServices", res.data.prices);
        //         // console.log("Services", res.data.prices);

        //         ctx.dispatch("loadSettings").then(() => {
        //             ctx.dispatch("findStatServices", ctx.rootState.s);
        //         });
        //     });
        // },
        getStatSalaries(ctx) {
            API.GET("statistic/salaries").then((res) => {
                ctx.commit("setStatSalaries", res.data.salaries);
                // console.log("Salaries", res.data.salaries);

                ctx.dispatch("loadSettings").then(() => {
                    ctx.dispatch("findStatSalaries", ctx.rootState.s);
                });
            });
        },
        // getStatLoading(ctx) {
        //     API.GET("statistic/calendar").then((res) => {
        //         ctx.commit("setStatLoading", res.data.calendar);
        //         // console.log("Loading", res.data.calendar);

        //         ctx.dispatch("loadSettings").then(() => {
        //             ctx.dispatch("findStatLoading", ctx.rootState.s);
        //         });
        //     });
        // },
        getStatRating(ctx) {
            API.GET("statistic/raiting").then((res) => {
                ctx.commit("setStatRating", res.data);
                // console.log("Rating", res.data);

                ctx.dispatch("loadSettings").then(() => {
                    ctx.dispatch("findStatRating", ctx.rootState.s);
                });
            });
        },

        // findStatOrders(ctx, s) {
        //     if (!ctx.state.statOrders) {
        //         return null;
        //     }
        //     let list = ctx.state.statOrders;

        //     if (s.master != "" && s.master != undefined) {
        //         list = list.filter((item) => String(item.master_id) == String(s.master));
        //     }

        //     if (s.start != "" && s.start != undefined) {
        //         list = list.filter((item) => item.dates && item.dates[0]?.date >= s.start);
        //     }

        //     if (s.end != "" && s.end != undefined) {
        //         list = list.filter((item) => item.dates && item.dates[0]?.date <= s.end);
        //     }

        //     if (list.length != ctx.state.statOrders?.length) {
        //         s.clear = true;
        //     }

        //     ctx.state.StatOrders = list;
        //     ctx.dispatch("setSettings", s);
        // },
        // findStatServices(ctx, s) {
        //     if (!ctx.state.statServices) {
        //         return null;
        //     }
        //     let list = ctx.state.statServices;

        //     if (list.length != ctx.state.statServices?.length) {
        //         s.clear = true;
        //     }

        //     ctx.state.statServices = list;
        //     ctx.dispatch("setSettings", s);
        // },
        findStatSalaries(ctx, s) {
            if (!ctx.state.statSalaries) {
                return null;
            }
            let list = ctx.state.statSalaries;

            if (s.master != "" && s.master != undefined) {
                list = list.filter((item) => item.uid == s.master);
            }

            if (s.start != "" && s.start != undefined) {
                list = list.filter((item) => item.date >= s.start);
            }

            if (s.end != "" && s.end != undefined) {
                list = list.filter((item) => item.date <= s.end);
            }

            if (list.length != ctx.state.statSalaries?.length) {
                s.clear = true;
            }

            ctx.state.statAllSalaries = list;
            ctx.state.statSalaries = list;
            ctx.dispatch("setSettings", s);
        },
        // findStatLoading(ctx, s) {
        //     if (!ctx.state.statLoading) {
        //         return null;
        //     }
        //     let list = ctx.state.statLoading;

        //     if (s.master != "" && s.master != undefined) {
        //         list = list.filter((item) => item.uid == s.master);
        //     }

        //     if (s.start != "" && s.start != undefined) {
        //         list = list.filter((item) => item.date >= s.start);
        //     }

        //     if (s.end != "" && s.end != undefined) {
        //         list = list.filter((item) => item.date <= s.end);
        //     }

        //     if (list.length != ctx.state.statLoading?.length) {
        //         s.clear = true;
        //     }

        //     ctx.state.statLoading = list;
        //     ctx.dispatch("setSettings", s);
        // },
        findStatRating(ctx, s) {
            if (!ctx.state.statRating) {
                return null;
            }
            let list = ctx.state.statRating;

            if (s.master != "" && s.master != undefined) {
                list = list.filter((item) => item.claim?.uid == s.master && item.punctuality?.uid == s.master && item.quality?.uid == s.master);
            }

            if (s.start != "" && s.start != undefined) {
                list = list.filter((item) => item.claim?.date >= s.start && item.punctuality?.date >= s.start && item.quality?.date >= s.start);
            }

            if (s.end != "" && s.end != undefined) {
                list = list.filter((item) => item.claim?.date <= s.end && item.punctuality?.date <= s.end && item.quality?.date <= s.end);
            }

            if (list.length != ctx.state.statRating?.length) {
                s.clear = true;
            }

            ctx.state.statRating = list;
            ctx.dispatch("setSettings", s);
        },

        // clear filter
        resetStatFilter(ctx) {
            ctx.rootState.s.month = moment().startOf("month").format("YYYY-MM-DD");
            ctx.commit("setStatOrders", ctx.state.statOrders);
            // ctx.commit("setStatServices", ctx.state.statServices);
            ctx.commit("setStatSalaries", ctx.state.statSalaries);
            ctx.commit("setStatLoading", ctx.state.statLoading);
            ctx.commit("setStatRating", ctx.state.statRating);
        },

        pickStatMonth(ctx, date) {
            // ctx.state.statCalendar.current = moment(date).startOf('month').format('YYYY-MM-DD');
            // ctx.state.statCalendar.startMonth = moment(ctx.state.statCalendar.current).startOf('month').format('YYYY-MM-DD');
            // ctx.state.statCalendar.endMonth = moment(ctx.state.statCalendar.current).endOf('month').format('YYYY-MM-DD');
            // ctx.commit('statCalendar', ctx.state.statCalendar);
            ctx.rootState.s.month = moment(date).startOf("month").format("YYYY-MM-DD");
            // ctx.commit('s')
        },

        findRatingDiscipline(ctx, { masterId }) {
            // let m = ctx.rootState.selectMasters;
            let punctuality = ctx.state.statRating.punctuality?.filter((el) => el.uid == masterId);

            let ratingDiscipline = (10 - (punctuality * 10) / ctx.state.StatOrders.length).toFixed(1);

            if (+ratingDiscipline >= 0) return +ratingDiscipline;
            else return 0;
        },

        async getManagerActivity(ctx, data) {
            let res = await API.GET("report/activity-man", { 
                manager_id: data.manager_id, 
                date: data.date 
            });

            if (res.status == 200) {
                ctx.commit("setActivity", res.data.activity_man);
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            }
        },

        async getActivityProfile(ctx) {
            let res = await API.GET("report/activity");

            if (res.status == 200) {
                ctx.commit("setActivity", res.data.activity_man);
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            }
        },
    },

    getters: {
        managerTasks(state) {
            return state.managerTasks;
        },
        statisticShow(state) {
            return state.statisticShow;
        },
        statOrders(state) {
            return state.statOrders;
        },
        statOrdersGraf(state) {
            if (state.statOrders) {
                return state.statOrders.graf;
            } else {
                return;
            }
        },
        statServices(state) {
            return state.statServices;
        },
        statSalaries(state) {
            return state.statSalaries;
        },
        statLoading(state) {
            return state.statLoading;
        },
        statQuality(state) {
            return state.statQuality;
        },
        statRating(state) {
            return state.statRating;
        },

        statQualityOrders(state) {
            return state.statQualityOrders;
        },

        planReportTable(state) {
            return state.planReportTable;
        },
        planMRT(state) {
            return state.planMRT;
        },
        reportPlanStats(state) {
            return state.reportPlanStats;
        },
        reportFactManager(state) {
            return state.reportFactManager;
        },
        reportGraphFact(state) {
            return state.reportGraphFact;
        },

        factTouchesList(state) {
            return state.factTouchesList;
        },
        factLeadList(state) {
            return state.factLeadList;
        },
        factBidList(state) {
            return state.factBidList;
        },
        factInvoiceList(state) {
            return state.factInvoiceList;
        },
        listBirthdays(state) {
            return state.listBirthdays;
        },
        birthdaysPaginator(state) {
            return state.birthdaysPaginator;
        },
        reportBriefcase(state) {
            return state.reportBriefcase;
        },
        marketing(state) {
            return state.marketing;
        },

    },
};
